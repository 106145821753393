import React from 'react';
import { useTranslation } from 'react-i18next';
import { EmptyStateContainer } from 'pages/commom/empty-state-container';
import { NoSessionFound } from 'images/empty-states/no-session-found';

export const CheckoutExpiredView = () => {
  const { t } = useTranslation();

  return (
    <EmptyStateContainer
      illustration={<NoSessionFound className="fill-pink-800 h-32 w-32" />}
      title={t('emptyStates.sessionExpired.title')}
      description={t('emptyStates.sessionExpired.description')}
      buttonText="Go back"
      buttonAction={() => window.location.replace('/')}
    />
  );
};
