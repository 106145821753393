import { RoomType, StepValue } from 'pages/packages/types';

export const calculateRoomPrice = (
  room: RoomType,
  roomKey: string,
  checkinDate?: StepValue,
  checkoutDate?: StepValue
) => {
  return (
    (room[roomKey].prices.find((price) => {
      return price.checkin === checkinDate && price.checkout === checkoutDate;
    })?.price || 0) / room[roomKey].capacity
  );
};
