import { UseStepsFlow } from 'pages/packages/types';
import { TFunction } from 'i18next';
import { ICONS } from 'pages/packages/constants';

export const buildTicketData = (
  get: () => UseStepsFlow,
  ticketId: string,
  quantity: number,
  t: TFunction
) => {
  const ticket = get().tickets?.[ticketId];
  return {
    name: `${quantity}x ${ticket.name}`,
    desc: t(`tickets:${ticketId}.description`),
    icon: ICONS.TICKLET
  };
};
