export const useEnv = () => {
  const isProd = process.env?.REACT_APP_BUILD_ENV === 'production';

  const baseUrlTicketshop = isProd
    ? 'https://tomorrowlandbrasil.insidemusicland.com'
    : 'http://tomorrowlandbrasil.insidemusicland.localhost:3006';

  return {
    env: process.env?.REACT_APP_BUILD_ENV,
    isProd,
    isLocalDev: !process.env?.REACT_APP_BUILD_ENV,
    isSimulator: process.env.REACT_APP_BUILD_ENV === 'simulator',
    baseUrlTicketshop
  };
};
