import React, { forwardRef, InputHTMLAttributes } from 'react';
import { IMaskMixin } from 'react-imask';
import { Input } from '@design-system/fields/input';

type CustomProps = {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  error?: string;
  label?: string | React.ReactNode;
} & InputHTMLAttributes<HTMLInputElement>;

const MaskedStyledInput = IMaskMixin(({ inputRef, ...maskProps }) => (
  <Input
    {...maskProps}
    label={maskProps.label}
    error={maskProps['aria-errormessage']}
    ref={inputRef as any}
  />
));

export const BirthDateInput = forwardRef<HTMLInputElement, CustomProps>(
  ({ value, name, ...props }, ref) => {
    return (
      <MaskedStyledInput
        {...props}
        value={value}
        aria-errormessage={props.error}
        label={props.label}
        mask="00/00/0000"
        definitions={{
          '#': /[1-9]/
        }}
        inputRef={ref}
        onAccept={(value) => {
          props.onChange({ target: { name: name, value } });
        }}
        overwrite
      />
    );
  }
);
