export const IMAGE_BASE_URL = 'https://d1ehf6zvv3mtvz.cloudfront.net';

const IMAGE_BUCKET_MAP: Record<string, string> = {
  dev: 'storefront-admin-images134308-staging',
  production: 'storefront-admin-images223016-main',
  simulator: 'storefront-admin-images223016-main'
};

export const IMAGE_BUCKET = IMAGE_BUCKET_MAP['production'];

export type ImagesHandlerEdits = {
  resize?: {
    width: number;
    height: number;
    fit: 'fill' | 'contain' | 'cover' | 'inside' | 'outside';
  };
};

export const getImage = (key: string, edits?: ImagesHandlerEdits | null) => {
  if (key?.includes('https://')) return key;

  const imageRequest: { bucket: string; key: string; edits?: ImagesHandlerEdits | null } = {
    bucket: IMAGE_BUCKET,
    key: `public/${key}`
  };

  if (edits) {
    imageRequest.edits = {
      ...edits
    };
  }

  const encodedObject = btoa(JSON.stringify(imageRequest));

  return `${IMAGE_BASE_URL}/${encodedObject}?hash=a55b19de4567`;
};
