import React from 'react';
import { Step } from '../components/step';
import { useWizard } from 'react-use-wizard';
import { PaymentMethodDetails } from 'pages/packages/checkout/steps-wizard/components/forms/payment-method-details';
import { useCheckoutForm } from 'pages/packages/checkout/hooks/use-checkout-form';
import { useTranslation } from 'react-i18next';

export const PaymentDetailsStep = ({ onComplete }: { onComplete: () => void }) => {
  const { t } = useTranslation();
  const { nextStep } = useWizard();
  const { updateFormDetails, formsDetails } = useCheckoutForm((s) => ({
    updateFormDetails: s.updateFormDetails,
    formsDetails: s.formsDetails
  }));

  return (
    <Step title={t('checkoutPage.steps.payment')}>
      <PaymentMethodDetails
        defaultValues={formsDetails.paymentDetails}
        onSubmit={async (data) => {
          updateFormDetails({ paymentDetails: data });
          onComplete();
          await nextStep();
        }}
      />
    </Step>
  );
};
