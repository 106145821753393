import { ReactNode, useEffect } from 'react';
import {
  ShippingOptionsContext,
  useShippingOptions,
  useShippingStore
} from '../hooks/use-shipping-store';
import { useQuery } from 'react-query';
import { API_URLS, apiFetch } from 'utils/apiFetch';
import { ShippingOption } from 'legacy-types/shipping';

export const ShippingProvider = ({ children }: { children: ReactNode }) => {
  const { setOptions, setIsLoading } = useShippingOptions();

  const { isLoading } = useQuery<ShippingOption[]>(
    [API_URLS.SHIPPING_OPTIONS],
    async () => {
      return apiFetch(API_URLS.SHIPPING_OPTIONS).get();
    },
    {
      onSuccess: (data) => setOptions(data),
      refetchOnWindowFocus: false
    }
  );

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading, setIsLoading]);

  return (
    <ShippingOptionsContext.Provider value={useShippingStore}>
      {children}
    </ShippingOptionsContext.Provider>
  );
};
