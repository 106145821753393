import { FC } from 'react';

export const GlobalJourneyIcon: FC<{ className?: string }> = (props) => (
  <svg
    className={props.className}
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 304.3607178 176.4836578">
    <g>
      <g>
        <linearGradient
          id="SVGID_1_"
          gradientUnits="userSpaceOnUse"
          x1="152.1803589"
          y1="0"
          x2="152.1803589"
          y2="176.4836578"></linearGradient>
        <path
          className="st0"
          d="M51.3517647,98.092453c-12.7604332,2.2538681-21.3231621-9.261528-21.3231621-26.475563
			c0-19.7877769,9.0745239-33.0354004,22.6075745-35.4221382c6.2498055-1.1023293,11.653183,0.6003761,13.7052841,1.948761
			L65.1456299,49.14711l-1.1170883,0.2854233c-2.4851608-6.500782-5.6543541-10.9937553-11.3923645-9.9849281
			c-7.1060829,1.2548828-11.8155823,13.9907112-11.8155823,29.7530251c0,17.0467148,4.8817406,26.0375824,12.3273773,24.7236481
			c1.2893295-0.2263718,3.0904541-0.9743805,3.9418068-1.5501556v-18.247467c0-4.0254669-1.1072502-6.9141541-5.049057-7.7605896
			v-1.2007446l18.3262062-3.2331734v1.2007523c-2.6524734,2.5245247-3.5973282,4.232151-3.5973282,9.286129V90.066124
			C64.1121979,93.7028275,58.3741875,96.857254,51.3517647,98.092453z M74.2299957,93.2057953V92.005043
			c3.9418106-2.4113464,5.5707016-5.7773819,5.5707016-9.6355286V42.1099243c0-4.109127-1.8847885-6.6877861-5.5707016-7.6670876
			v-1.1958275l20.8999481-3.6859112v1.1958294c-4.1977005,2.367054-5.049057,5.6051407-5.049057,9.5420303v45.9975967
			l8.0460129-1.4221954c3.5973358-0.6348267,5.6543579-4.0746765,8.3068314-11.8254242l1.1170959-0.196846v14.4778976
			L74.2299957,93.2057953z M151.8309631,49.5407982c0,17.0467186-9.7585602,32.5580559-21.9235382,34.7036552
			c-12.0714874,2.1259232-21.9235458-9.9258728-21.9235458-26.9725952s9.8520584-32.5728149,21.9235458-34.6987343
			C142.072403,20.4275208,151.8309631,32.4940796,151.8309631,49.5407982z M140.7879944,51.4895592
			c0-15.245594-4.8866577-26.7216206-10.8805695-25.6635818c-5.9939117,1.0531178-10.7870712,14.2367668-10.7870712,29.4823608
			s4.7931595,26.7363777,10.7870712,25.6832619C135.9013367,79.9335632,140.7879944,66.7351532,140.7879944,51.4895592z
			 M193.0403137,54.609539c0,11.0528069-8.99086,19.2316933-19.1824799,21.0278969l-19.015152,3.3512726v-1.2007446
			c3.9368744-2.3178482,5.5657654-5.6888046,5.5657654-9.6306152V27.8977642c0-4.625843-2.140686-6.9043159-5.5657654-7.6720085
			v-1.1958294l21.5839691-3.8040171c8.6513214-1.5255432,13.0163422,4.123889,13.0163422,11.746686
			c0,5.2262154-3.257782,10.9396248-8.6513062,13.3460464C188.9311981,40.0036888,193.0403137,46.6471863,193.0403137,54.609539z
			 M171.8008118,41.1404648c5.3984528-0.9546928,6.9387665-7.9082184,6.9387665-12.1059246
			c0-7.2783203-2.7459717-10.2260647-5.3148041-9.7733212l-2.7361298,0.4822674v21.5889034L171.8008118,41.1404648z
			 M182.3369141,56.6665649c0-8.223175-4.0254669-13.080307-8.6513062-12.2634048l-2.9969635,0.5265579v27.7501297
			l3.1691895-0.5560837C179.4236145,71.1444626,182.3369141,64.9783173,182.3369141,56.6665649z M238.4424591,64.2499924
			l-19.8714294,3.50383v-1.112175c4.2813721-2.0422592,3.8581543-5.6494293,3.2577667-8.3708038
			c-1.3729858-6.185833-9.6798096-13.19841-14.9896851-13.0360146l-4.1977081,16.5939789
			c-1.1121674,4.4732857,0.1722412,6.303936,3.7695618,6.9535255v1.1121674l-13.7889404,2.4310303v-0.7726135
			c2.7410583-1.5107803,4.537262-4.734108,5.6543579-8.8727608l14.3843994-54.7030411l6.5106201-1.1466179l13.3608093,49.2110863
			c1.0285187,3.9319649,3.1692047,6.4614143,5.9102478,7.1798973V64.2499924z M207.7839661,41.4701805
			c4.0205383,0.7480087,8.8186188,2.8985329,11.2988586,5.6297493l-6.3334656-25.2649727L207.7839661,41.4701805z
			 M241.8773956,63.6446953V62.443943c3.9418182-2.4064217,5.5657806-5.7773781,5.5657806-9.6355286V12.5488262
			c0-4.1091251-1.8847809-6.6877861-5.5657806-7.6670866V3.6859102L262.7724304,0v1.1958294
			c-4.1977234,2.367053-5.0490723,5.6051426-5.0490723,9.5420294v45.9976006l8.0509338-1.4221992
			c3.5973511-0.6299019,5.6494446-4.0746765,8.3068542-11.8254242l1.1121521-0.196846v14.4779015L241.8773956,63.6446953z
			 M4.4536033,165.4378204c2.4851594-0.437973,4.5421824,1.2499695,4.5421824,3.7351227
			c0,1.2007446-0.6003752,2.3375244-1.3729887,3.3266754c-0.4232154,0.5856018,0.0885797,0.841507,0.516716,0.7676849
			c3.8483067-0.6791077,6.2498083-6.156311,6.2498083-12.2388v-38.2911377c0-3.5973282-1.540307-5.8954849-4.7931595-6.5253906
			v-1.0235901l19.6942749-3.4693832v1.0235901c-3.5087509,1.9093933-4.7931595,4.9604797-4.7931595,8.2133331v32.8926926
			c0,12.164978-6.1661491,20.6194458-16.4414234,22.4304047C2.9132962,177.1845093,0,175.0389099,0,170.7575378
			C0,168.2723846,1.9733645,165.8758087,4.4536033,165.4378204z M76.14431,133.4358521
			c0,17.0467224-9.7585602,32.5580597-21.9235382,34.7036591c-12.0714798,2.1259155-21.9235382-9.9258728-21.9235382-26.9725952
			s9.8520584-32.5728149,21.9235382-34.6987381C66.3857498,104.3225784,76.14431,116.3891373,76.14431,133.4358521z
			 M65.1013412,135.384613c0-15.2455902-4.8866577-26.7216187-10.8805695-25.6635818
			c-5.9939079,1.0531158-10.7870674,14.2367706-10.7870674,29.4823685s4.7931595,26.7363739,10.7870674,25.6832581
			C60.2146835,163.8286133,65.1013412,150.6302032,65.1013412,135.384613z M78.2997589,104.2733688v-1.1958313l20.127327-3.5481186
			v1.1958313c-3.941803,2.3227615-5.053978,5.688797-5.053978,9.5420227v32.2972412
			c0,9.2467651,3.3414383,13.1098328,8.5676498,12.1895752c7.0224228-1.2401123,9.6749039-7.3570557,9.6749039-14.8125305
			v-32.8926849c0-4.0254669-1.2844086-6.796051-5.9939117-7.5932693v-1.1958313l16.1018677-2.8394775v1.1958237
			c-3.9418106,2.3227615-5.570694,5.7773819-5.570694,9.6306076v32.4645615
			c0,13.1934814-6.5893631,19.6696625-16.5250854,21.4215851c-10.0242996,1.7666779-16.6185837-2.3818207-16.6185837-15.5753174
			v-32.4645462C83.0092545,107.9838791,81.9807434,105.1690063,78.2997589,104.2733688z M170.5508575,145.0545959
			c-0.4281464,1.1072388-3.3365173,4.015625-8.0460205,4.84729c-18.0752258,3.18396-7.7113647-30.5896149-17.7307434-28.822937
			l-2.9132996,0.511795v20.3832397c0,4.6258392,2.0570221,6.8354187,5.3098755,7.7162933v1.2007446l-21.1558456,3.7302094
			v-1.2007599c3.9418106-2.4064178,5.5706863-5.7724609,5.5706863-9.6306v-40.2595901
			c0-4.1091309-1.8847809-6.6927109-5.5706863-7.6720123v-1.1958237l24.1527939-4.2567596
			c9.0794525-1.6042862,15.7623138,2.4408646,15.7623138,11.6925507c0,7.4554825-6.1710663,13.4247818-11.0478821,15.7426224
			c6.5057068,3.7351227,5.8216705,13.6167068,8.5627289,21.5298538c1.5402985,4.4388428,3.7695618,5.1622467,7.1060791,4.5717163
			V145.0545959z M154.7934723,104.0617599c0-6.677948-4.4536133-10.176857-9.4239349-9.3008957l-3.5087433,0.6151352v21.7562256
			l3.5087433-0.6151428C150.339859,115.6411285,154.7934723,110.4001465,154.7934723,104.0617599z M208.8419952,140.359848
			l-25.2600403-38.1139755v32.3759689c0,4.5421906,0.6791077,7.07164,5.221283,7.7261353v1.2007599l-15.2455902,2.6918488
			v-1.2007599c3.8581543-2.2243347,5.4821167-5.6789551,5.4821167-9.6207581V95.1594696
			c0-4.7094955-1.9684448-6.8452606-5.2262115-7.7310562v-1.1958313l13.1934814-2.3227615l20.7277222,31.7165451v-25.525795
			c0-4.2813644-1.5452423-6.8354187-5.9151764-7.6080322v-1.1958237l15.6737213-2.7656631v1.1958237
			c-3.5087433,2.1652908-5.3098755,5.3935394-5.3098755,9.5912399v50.456131L208.8419952,140.359848z M221.7894287,137.7319794
			v-1.2007446c3.9368896-2.3178406,5.5657806-5.688797,5.5657806-9.6306152V86.641037
			c0-4.6258469-2.0521088-6.9141617-5.5657806-7.6720123V77.773201l32.5482178-5.7380142V83.338974l-1.0285187,0.1820755
			c-2.312912-4.3896255-4.7094879-6.4466476-7.9672699-5.8708801l-7.6227875,1.3434601v19.955101l2.7410431-0.4871902
			c3.5087585-0.6151428,5.6543579-3.3906479,7.1110077-6.5598373l1.3680725-0.2411346v14.6501389l-1.3680725,0.2411346
			c-1.4566498-2.8296432-3.6809845-4.6652145-7.1110077-4.0648346l-2.7410431,0.4871826v27.838707l9.0794373-1.6042786
			c3.6859131-0.6495819,5.7380066-3.6613007,8.0509338-9.2959671l1.1170959-0.196846v11.9927368L221.7894287,137.7319794z
			 M266.8520203,78.5654984c-2.0570068-4.0057831-4.2813721-5.5854568-6.5992126-6.3777618v-1.1958237l19.276001-3.3955688
			v1.1958313c-3.8581543,2.1357574-3.1691895,5.0096893-1.2007446,8.6955948l7.2782898,13.7052917l7.7113953-16.776062
			c2.0520935-4.3945465,1.6239624-6.9732056-1.9733582-7.7113724v-1.1958313l13.0163269-2.2932358v1.1958313
			c-2.736145,1.6830139-4.7045898,4.1681747-6.677948,8.8875198l-9.2516785,21.9333801v20.8999481
			c0,4.970314,2.4851685,6.7566833,6.3383789,7.5342178v1.2007446l-23.5524292,4.1484909v-1.2007446
			c4.0254822-2.2489471,6.2498169-5.3836899,6.2498169-9.7536392V98.9634933L266.8520203,78.5654984z"
        />
      </g>
    </g>
  </svg>
);
