import SvgIcon from '@mui/material/SvgIcon';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { AmexSVG, ApplePaySVG, MastercardSVG, PixSVG, VisaSVG } from 'svg/payment-methods';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { SupportedLocales } from 'legacy-types/legacy/locale';

export const PaymentMethods = () => {
  const { i18n } = useTranslation();

  const paymentMethodsFAQLink: Record<SupportedLocales, string> = {
    'pt-BR': 'https://brasil.tomorrowland.com/pt/ingressos/como-comprar-seus-ingressos',
    'en-GB': 'https://brasil.tomorrowland.com/en/tickets/how-to-order-your-tickets',
    'es-ES': 'https://brasil.tomorrowland.com/es/boletos/como-comprar-seus-ingressos'
  };

  const { t } = useTranslation();
  return (
    <div className="inline-block ">
      <span className="text-sm text-gray-400">{t('common.paymentMethods.title')}</span>
      <div className="flex gap-1 pt-1">
        <MastercardSVG className="w-9" />
        <VisaSVG className="w-9" />
        <AmexSVG className="w-9" />
        <ApplePaySVG className="w-9" />
        <PixSVG className="w-9" />
      </div>
      <div className="mt-2 space-x-1 text-sm text-gray-400 inline-block items-center gap-1 pt-1">
        <SvgIcon fontSize="small" className="pr-1">
          <InfoOutlinedIcon />
        </SvgIcon>

        <Trans i18nKey="common.paymentMethods.helpText">
          For more info about payment methods,{' '}
          <a
            href={paymentMethodsFAQLink[i18n.language as SupportedLocales]}
            target="_blank"
            className="text-pink-600"
            rel="noreferrer">
            click here
          </a>
        </Trans>
      </div>
    </div>
  );
};
