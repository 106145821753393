import { Shipping } from 'legacy-types/shipping';
import { State } from 'country-state-city';

export const buildAddressString = (address: Shipping['address']) => {
  if (!address) return '';

  const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });

  const region = regionNames.of(address?.country || '');

  return `${address.address1} ${address.address2 || ''}, ${address.city} - ${
    State.getStateByCodeAndCountry(address.state, address.country)?.name
  }, ${region} - ${address.postalCode}`;
};
