import { useWizard } from 'react-use-wizard';
import { StepInlineHeader } from 'pages/packages/checkout/steps-wizard/components/step-inline-header';
import { FC } from 'react';
import cn from 'classnames';
import { Steps } from 'pages/packages/checkout/steps-wizard/index';

const StepsList: FC<{ steps: Steps[]; done?: boolean }> = ({ steps, done }) => {
  const { activeStep, goToStep } = useWizard();

  return (
    <div className={cn('grid grid-cols-1 divide-y divide-gray-800')}>
      {steps
        .filter((step) => (done ? step.index < activeStep : step.index > activeStep))
        .map((item) => {
          return (
            <StepInlineHeader
              onClick={() => goToStep(item.index)}
              key={item.index}
              title={item.title}
              disabled={item.disabled}
              done={done}
            />
          );
        })}
    </div>
  );
};

export default StepsList;
