import React, { ReactNode, useMemo } from 'react';
import busIcon from 'images/tomorrowland-icons/bus.svg';
import tomorrowlandIcon from 'images/tomorrowland-icons/tomorrowland.svg';
import hotelIcon from 'images/tomorrowland-icons/hotel.svg';
import campingIcon from 'images/tomorrowland-icons/camping.svg';
import SvgIcon from '@mui/material/SvgIcon';
import { PackagesList, ROUTES } from 'contants';
import { useNavigate } from 'react-router-dom';
import AirplaneTicketOutlinedIcon from '@mui/icons-material/AirplaneTicketOutlined';
import { useTranslation } from 'react-i18next';
import Chip from '@mui/joy/Chip';
import { track } from '@common/analytics/use-analytics';
import { useEnv } from 'hooks/use.env';
import { LandingPageCard } from 'pages/landing-page/card';

const PackagesSection = () => {
  const { isProd } = useEnv();
  const { t } = useTranslation('translation', { keyPrefix: 'landingPage' });
  const navigate = useNavigate();
  const SECTIONS = useMemo(
    () => [
      // {
      //   id: 'theme-hotel-packages',
      //   label: t('items.theme-hotel-packages.title'),
      //   desc: t('items.theme-hotel-packages.description'),
      //   icons: [
      //     <SvgIcon fontSize="small">
      //       <AutoAwesomeIcon />
      //     </SvgIcon>,
      //     <img src={hotelIcon} width="16px" />,
      //     <img src={tomorrowlandIcon} width="16px" />
      //   ],
      //   href: `${ROUTES.PackageBuider}/${PackagesList.ThemeHotelPackage}`
      // },
      {
        id: 'hotel-packages',
        label: t('items.hotel-packages.title'),
        desc: (
          <div>
            {t('items.hotel-packages.description')}
            <div className="mt-1 text-sm md:py-1 text-pink-700 px-2 bg-gray-900 rounded-md">
              Also available without tickets - hotel accommodation only.
            </div>
          </div>
        ),

        icons: (
          <div className="flex gap-2">
            <img key="hotel-package-hotel-icon" alt="bus" src={hotelIcon} width="16px" />
            <img
              key="hotel-package-tomorrowland-icon"
              alt="tomorrowland"
              src={tomorrowlandIcon}
              width="16px"
            />
          </div>
        ),
        href: `${ROUTES.PackageBuider}/${PackagesList.HotelPackage}`
      },
      {
        id: 'flight-packages',
        label: t('items.flight-packages.title'),
        desc: t('items.flight-packages.description'),
        soldOut: true,
        icons: (
          <div className="flex gap-2">
            <SvgIcon fontSize="small">
              <AirplaneTicketOutlinedIcon />
            </SvgIcon>
            <img key="flight-package-hotel-icon" alt="bus" src={hotelIcon} width="16px" />
            <img key="bus-package-camping-icon" alt="camping" src={campingIcon} width="16px" />
            <img
              key="flight-package-tomorrowland-icon"
              alt="tomorrowland"
              src={tomorrowlandIcon}
              width="16px"
            />
          </div>
        ),
        href: `${ROUTES.PackageBuider}/${PackagesList.FlightPackage}`
      },
      {
        id: 'bus-packages',
        label: t('items.bus-packages.title'),
        desc: (
          <div>
            {t('items.bus-packages.description')}
            <div className="mt-2 md:mt-1 text-sm md:py-1 text-pink-700 px-2 bg-gray-900 rounded-md">
              Also available without tickets - hotel accommodation only.
            </div>
          </div>
        ),
        icons: (
          <div className="flex gap-2">
            <img key="bus-package-bus-icon" alt="bus" src={busIcon} width="16px" />
            <img key="flight-package-hotel-icon" alt="bus" src={hotelIcon} width="16px" />
            <img key="bus-package-camping-icon" alt="camping" src={campingIcon} width="16px" />
            <img
              key="bus-package-tml-icon"
              alt="tomorrowland"
              src={tomorrowlandIcon}
              width="16px"
            />
          </div>
        ),
        href: `${ROUTES.PackageBuider}/${PackagesList.BusPackage}`
      }
      // {
      //   id: 'accommodation-only',
      //   label: t('items.accommodation-only.title'),
      //   desc: t('items.accommodation-only.description'),
      //   icons: (
      //     <div className="flex gap-2">
      //       <img key="bus-package-bus-icon" alt="bus" src={busIcon} width="16px" />
      //       <img key="flight-package-hotel-icon" alt="bus" src={hotelIcon} width="16px" />
      //     </div>
      //   ),
      //   href: `${ROUTES.PackageBuider}/${PackagesList.AccommodationOnly}`
      // }
      // {
      //   label: 'Official Shuttles',
      //   desc: 'Shutles from São Paulo to and from Tomorrowland, the easiest way to get to the festival.',
      //   icons: [busIcon],
      //   href: `${ROUTES.Packages}/${PackagesList.ShuttlesAddons}`
      // }
    ],
    [t]
  );

  const renderCard = (data: {
    id: string;
    label: string;
    desc: ReactNode;
    href: string;
    soldOut?: boolean;
    icons: React.ReactNode;
  }) => {
    const onCardClick = () => {
      void track('PACKAGE_SELECTION', 'track', { package: data.id });
      navigate(data.href);
    };

    return (
      <LandingPageCard
        disabled={data.soldOut}
        soldOut={data.soldOut}
        key={data.href}
        onClick={onCardClick}
        title={data.label}
        description={data.desc}
        icon={data.icons}
        ctaText={t('goToPackage')}
      />
    );
  };

  return (
    <div className="relative">
      <div className="flex flex-col mb-4 relative">
        <div className="text-lg md:text-2xl uppercase font-bold text-white">
          {' '}
          {t('travelToTomorrowlandBrasil')}
        </div>
        <div className="text-sm text-gray-400">{t('travelToTomorrowlandBrasilSubtitle')}</div>
      </div>
      <div className="mb-4 items-center text-gray-500">
        {isProd ? (
          <span className="text-sm">
            <Chip color="warning" size="sm" sx={{ mr: 1 }}>
              {t('onHold')}
            </Chip>
            {t('onHoldText')}
          </span>
        ) : null}
      </div>
      <div className={`grid gap-6 md:gap-8 grid-cols-1 sm:grid-cols-1 xl:grid-cols-2`}>
        {SECTIONS.map(renderCard)}
      </div>
    </div>
  );
};

export default PackagesSection;
