import { AvailabilityUnit } from 'legacy-types/inventory-availability';
import { useTranslation } from 'react-i18next';
import { Badge } from '@design-system/feedback/badge';

export const AvailabilityChip = (availability: AvailabilityUnit) => {
  const { t } = useTranslation();
  if (availability.isSoldOut) {
    return (
      <Badge
        color="danger"
        value={t('common.availabilityChips.soldOut')}
        className="uppercase w-fit"
      />
    );
  }

  if (availability.isOnHold) {
    return (
      <Badge
        color="warning"
        value={t('common.availabilityChips.onHold')}
        className="uppercase w-fit"
      />
    );
  }

  return null;
};
