import React, { useEffect, useState } from 'react';
import { RadioGroup } from '@headlessui/react';
import classNames from 'classnames';

export type StackCardOption = {
  id: string;
  name: string | React.ReactNode;
  description?: string | React.ReactNode;
  price?: string | React.ReactNode;
  priceLabel?: string;
  disabled?: boolean;
};

export type RadioGroupProps = {
  label?: string | React.ReactNode;
  error?: string | React.ReactNode;
  options: StackCardOption[];
  value?: StackCardOption;
  onChange?: (value: StackCardOption) => void;
};

export function StackedCards(props: RadioGroupProps) {
  const [selected, setSelected] = useState<StackCardOption>();

  useEffect(() => {
    if (props.value) {
      setSelected(props.value);
    }
  }, [props.value]);

  return (
    <RadioGroup
      value={selected}
      onChange={(opt) => {
        setSelected(opt);
        props.onChange?.(opt);
      }}>
      <div className="space-y-4">
        {props.options.map((opt) => (
          <RadioGroup.Option
            disabled={opt.disabled}
            key={opt.id}
            value={opt}
            className={classNames(
              'peer relative block cursor-pointer rounded-lg border px-6 py-4 shadow-sm sm:flex sm:justify-between',
              opt.disabled
                ? ' bg-gray-300 text-gray-500 ring-gray-200 cursor-not-allowed'
                : 'bg-white'
            )}>
            <>
              <span className="flex items-center">
                <span className="flex flex-col ">
                  <RadioGroup.Label
                    as="span"
                    className={classNames('font-medium text-gray-900', {
                      'text-gray-600': opt.disabled
                    })}>
                    {opt.name}
                  </RadioGroup.Label>
                  <RadioGroup.Description
                    as="span"
                    className="text-gray-600 pt-1 text-sm max-w-2xl">
                    <span className="block sm:inline">{opt.description}</span>
                  </RadioGroup.Description>
                </span>
              </span>
              <RadioGroup.Description
                as="span"
                className="mt-2 flex text-sm sm:ml-4 sm:mt-0 sm:flex-col sm:text-right">
                <span className="font-medium text-gray-900">{opt.price}</span>
                <span className="ml-1 text-gray-600 sm:ml-0">{opt.priceLabel}</span>
              </RadioGroup.Description>
              <span
                className={classNames(
                  'border-2',
                  'pointer-events-none absolute -inset-px rounded-lg',
                  opt.id === selected?.id ? 'border-gray-900' : 'border-gray-300'
                )}
              />
            </>
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
}
