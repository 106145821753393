import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { State } from 'country-state-city';
import Combobox from '@design-system/fields/combobox';
import { Input } from '@design-system/fields/input';

type CountryDropdownProps = {
  country?: string;
  value: string;
  onChange: (value: string) => void;
  error?: string;
  label?: string | React.ReactNode;
};

export const StateDropdown = ({ country, value, onChange, error, label }: CountryDropdownProps) => {
  const [state, setState] = useState<{ id: string; name: string } | undefined>();

  const options = useMemo(() => {
    return State.getStatesOfCountry(country).map((opt) => ({
      id: opt.isoCode,
      name: opt.name
    }));
  }, [country]);

  useEffect(() => {
    if (options.length > 0) {
      const foundState = options.find((opt) => opt.id === value);
      if (foundState) {
        setState(foundState);
      } else {
        setState(options[0]);
        onChange(options[0].id);
      }
    }
  }, [options, value, onChange]);

  return country && options.length === 0 ? (
    <Input
      error={error}
      label={label || 'Country / Region'}
      maxLength={100}
      value={value}
      onChange={(event) => onChange(event.target.value)}
    />
  ) : (
    <Combobox
      error={error}
      label={label || 'Country / Region'}
      options={options}
      value={state}
      onSelect={(opt) => onChange(opt.id)}
    />
  );
};
