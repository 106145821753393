/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cloud_logic_custom": [
        {
            "name": "InsideStorefrontApi",
            "endpoint": "https://v4a9mda03d.execute-api.us-east-2.amazonaws.com/main",
            "region": "us-east-2"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-2:1f842872-a5dc-49fa-a655-eaf649b510fe",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_hIKZ0Fw5F",
    "aws_user_pools_web_client_id": "65mr3f8kh6n1020psgfgphlkni",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "insidestorefront-20240330160157-hostingbucket-main",
    "aws_content_delivery_bucket_region": "us-east-2",
    "aws_content_delivery_url": "https://d3tdhv24cnkzln.cloudfront.net"
};


export default awsmobile;
