import { Wizard } from 'react-use-wizard';
import StepWizardContainer from 'pages/packages/checkout/steps-wizard/components/container';
import { PersonalDetailStep } from 'pages/packages/checkout/steps-wizard/steps/personal-detail';
import { AddressDetailsStep } from 'pages/packages/checkout/steps-wizard/steps/address-details';
import { PaymentDetailsStep } from 'pages/packages/checkout/steps-wizard/steps/payment-details';
import StepsList from 'pages/packages/checkout/steps-wizard/components/steps-list';
import { TermsAndCondotionsStep } from 'pages/packages/checkout/steps-wizard/steps/terms';
import { useCallback, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { GuestDetailsStep } from 'pages/packages/checkout/steps-wizard/steps/guest-details';
import { CheckoutOrder } from 'legacy-types/get-order-package.types';
import { useTranslation } from 'react-i18next';
import { Shpping } from 'pages/packages/checkout/steps-wizard/steps/shipping-details';
import { useCheckoutForm } from 'pages/packages/checkout/hooks/use-checkout-form';

export type Steps = {
  index: number;
  title: string;
  disabled?: boolean;
  component: JSX.Element;
};

export const CheckoutStepsWizard = ({ data }: { data: CheckoutOrder }) => {
  const { t } = useTranslation();
  const { checkoutHash } = useParams<{ checkoutHash?: string }>();
  const mainContentRef = useRef<HTMLDivElement>(null);
  const { formsDetails, updateFormDetails } = useCheckoutForm((s) => ({
    updateFormDetails: s.updateFormDetails,
    formsDetails: s.formsDetails
  }));

  const scrollTopTop = useCallback(() => {
    mainContentRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
  }, []);

  const isAllNoTickets = useMemo(
    () => data?.checkoutItems?.every((item) => item.ticket?.priceType === 'not_included'),
    [data?.checkoutItems]
  );

  const STEPS: Steps[] = useMemo(
    () => [
      {
        index: 0,
        title: t('checkoutPage.steps.personalDetails'),
        component: <PersonalDetailStep onComplete={scrollTopTop} />
      },
      {
        index: 1,
        title: t('checkoutPage.steps.address'),
        component: <AddressDetailsStep onComplete={scrollTopTop} />
      },
      {
        index: 2,
        disabled: isAllNoTickets,
        title: t('checkoutPage.steps.shipping'),
        component: (
          <Shpping
            data={data.checkoutItems}
            pax={data.checkoutItems?.reduce(
              (acc, item) => acc + item.accommodation.room.capacity,
              0
            )}
            formsDetails={formsDetails}
            onComplete={(shipping) => {
              updateFormDetails({ shipping });
              scrollTopTop();
            }}
          />
        )
      },
      {
        index: 3,
        title: t('checkoutPage.steps.guests'),
        component: <GuestDetailsStep data={data.checkoutItems} onComplete={scrollTopTop} />
      },
      {
        index: 4,
        title: t('checkoutPage.steps.payment'),
        component: <PaymentDetailsStep onComplete={scrollTopTop} />
      },
      {
        index: 5,
        title: t('checkoutPage.steps.terms'),
        component: <TermsAndCondotionsStep checkoutId={checkoutHash} />
      }
    ],
    [
      t,
      scrollTopTop,
      isAllNoTickets,
      data.checkoutItems,
      formsDetails,
      checkoutHash,
      updateFormDetails
    ]
  );

  return (
    <StepWizardContainer>
      <Wizard
        header={
          <div className="scroll-mt-40" ref={mainContentRef}>
            <StepsList steps={STEPS} done={true} />
          </div>
        }
        footer={<StepsList steps={STEPS} done={false} />}>
        {STEPS.map((step) => (
          <div key={`${step.index}`} className="border-y border-gray-800 py-2">
            {step.component}
          </div>
        ))}
      </Wizard>
    </StepWizardContainer>
  );
};
