import React, { forwardRef, ReactNode } from 'react';
import cn from 'classnames';

export const Step = forwardRef<
  HTMLDivElement,
  {
    title: string;
    description?: string;
    children: ReactNode;
  }
>(({ title, children, description }, ref) => {
  return (
    <div ref={ref} className="my-2">
      <div className="flex gap-1 items-center cursor-auto text-left text-lg font-medium ">
        {title}
      </div>
      <div className={cn('rounded-md text-black px-5 py-4 my-4 bg-gray-100')}>{children}</div>
    </div>
  );
});
