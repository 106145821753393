import * as React from 'react';
import { useMemo } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { AspectRatio } from '@mui/joy';
import { AccommodationTypeChips } from 'pages/packages/shop/components/accommodation-type-chips';
import priceToCurrency from 'utils/price-to-currency';
import styles from './accomodation-card.module.css';
import { AccommodationType } from 'pages/packages/types';
import { AccommodationMainPerks } from 'pages/packages/shop/components/accommodation-main-perks';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import SvgIcon from '@mui/material/SvgIcon';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Badge } from '@design-system/feedback/badge';
import { track } from '@common/analytics/use-analytics';
import { getImage } from '@common/images-handler';

export type AccomodationCardProps = {
  accommodation: AccommodationType;
  onClick: () => void;
  priceFrom: number;
  isSoldOut?: boolean;
  isOnHold?: boolean;
};

export default function AccomodationCard(props: AccomodationCardProps) {
  let eventTimeout: ReturnType<typeof setTimeout>;
  const { t } = useTranslation();

  const capacityList = useMemo(
    () =>
      Array.from({
        length: props.accommodation.roomsCapacity.max - props.accommodation.roomsCapacity.min + 1
      })
        .map((_, index) => `${index + props.accommodation.roomsCapacity.min}P`)
        .join(' / '),
    [props.accommodation]
  );

  return (
    <>
      <div
        onMouseEnter={() =>
          (eventTimeout = setTimeout(() => {
            void track('accommodation_card_hover', 'track', {
              name: props.accommodation.name,
              category: props.accommodation.category
            });
          }, 2000))
        }
        onMouseLeave={() => clearTimeout(eventTimeout)}
        className={cn(styles.accommodationContainer, 'bg-gray-100 rounded-md')}
        onClick={(e) => {
          e.preventDefault();
          props.onClick();
        }}>
        {/*<Chip size="sm" color="danger" sx={{ borderRadius: 1 }}>*/}
        {/*  <span className="uppercase tracking-wide">SOLD OUT</span>*/}
        {/*</Chip>*/}

        <article
          className={cn(
            'cursor-pointer overflow-hidden rounded-md text-gray-400 duration-500 ease-in-out'
          )}>
          <AspectRatio ratio="16/9" objectFit="cover" variant="solid">
            <img
              className={cn(styles.accommodationImage, {
                grayscale: props.isSoldOut || props.isOnHold
              })}
              src={getImage(props.accommodation.mainImage, {
                resize: { width: 600, height: 400, fit: 'cover' }
              })}
              width={600}
              height={400}
              alt={`${props.accommodation.name} accommodation`}
            />

            {props.isSoldOut || props.isOnHold ? (
              <div className="absolute top-24 z-10 items-center w-full">
                <div
                  className={cn(
                    'font-bold tracking-wide bg-white border-md text-center uppercase',
                    {
                      'text-yellow-900': props.isOnHold,
                      'text-red-800 ': props.isSoldOut
                    }
                  )}>
                  {t(`common.availabilityChips.${props.isOnHold ? 'onHold' : 'soldOut'}`)}
                </div>
              </div>
            ) : null}
            <div className="relative top-3 left-3 z-10">
              <Badge
                className="font-bold"
                color="pink"
                value={capacityList}
                icon={
                  <SvgIcon sx={{ fontSize: 16 }}>
                    <PeopleAltOutlinedIcon />
                  </SvgIcon>
                }
              />
            </div>
          </AspectRatio>

          <div className="p-4 border-t border-gray-500">
            <div className="pb-2">
              <AccommodationTypeChips category={props.accommodation.category} />
              <div className="text-lg duration-500 ease-in-out text-gray-900 font-bold">
                {t(`accommodation:${props.accommodation.id}.name`)}
              </div>
            </div>

            <div className="">
              <AccommodationMainPerks
                accommodation={props.accommodation}
                className="text-gray-800 my-0"
              />
            </div>

            <div className="flex list-none items-center justify-between px-0 mt-3 pt-3 border-t border-gray-400 pb-0">
              <div className="text-left">
                <span className="text-sm text-gray-800">{t('packageBuilder.priceFrom')}</span>
                <p className="m-0 font-medium text-gray-900">
                  {priceToCurrency(props.priceFrom, 'BRL')}
                </p>
              </div>
              <div>
                <SvgIcon className="text-black">
                  <ArrowForwardIosIcon />
                </SvgIcon>
              </div>
            </div>
          </div>
        </article>
      </div>
    </>
  );
}
