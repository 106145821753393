export const PAYPAL_CLIENT_ID = {
  main: 'AfcD4XtLfFHd1M3W2TvQ3xY4uSbO0d5v1vp6S_swQjQWt-2xLIbT80cIAoX8R45HkZIq3wVtvdkmHEk6',
  dev: 'AWD8C9LCFEF_O2SD7Ib9wzEETfQEC4GviqUf3sqnslgtgZd_zh4Emg6pPqsnv9KOhV48WsJcKX7Ti_YW'
};

export const API_URL = {
  HANDLE_SESSION: '/user/session', // "/api/v1/handle-session", => userSession
  LIST_ITEMS: '/packages/list', // "/api/v1/items/list", => packagesList
  PURCHASE_STATUS: '/order/status', // "/api/v1/purchase-status", => purchaseStatus
  SESSION_CHECKOUT: '/order/checkout', // "/api/items/checkout", => purchaseCheckout
  PAYPAL_CREATE_ORDER: '/paypal/create-order',
  PAYPAL_EXECUTE_PAYMENT: '/paypal/execute-payment',
  ORDER_PERSONALISATION: '/order/personalisation', // /order/personalisation/{orderId}/{guestId}
  ORDER_REQUEST_REFUND: '/order/request-refund' // /order/request-refund/{orderId}
};

export const BUILD_ENV = process.env.REACT_APP_BUILD_ENV || 'staging';

export const ROUTES = {
  Packages: '/packages',
  PackageBuider: '/packages/shop',
  PackageSummary: '/packages/summary',
  PackageCheckout: '/packages/checkout'
} as const;

export const PackagesList = {
  HotelPackage: 'hotel-packages',
  ThemeHotelPackage: 'theme-hotel-packages',
  FlightPackage: 'flight-packages',
  BusPackage: 'bus-packages',
  ShuttlesAddons: 'shuttles-addons',
  AccommodationOnly: 'accommodation-only'
} as const;

export const PackagesCategory = {
  ThemeHotelPackage: 'theme-hotel-packages',
  HotelPackage: 'hotel-packages',
  FlightPackage: 'flight-packages',
  BusPackage: 'bus-packages',
  AccommodationOnly: 'accommodation-only'
} as const;

export const AcceptedLanguageHeader = 'Accept-Language';

export const Identifier = {
  pathParam: 'identifier',
  storage: 'inside-user-identifier'
};

export const GenderNumeric = {
  Male: '1',
  Female: '2',
  Other: '9',
  Unknown: '0'
} as const;

export const GenderString = {
  M: GenderNumeric.Male,
  F: GenderNumeric.Female,
  O: GenderNumeric.Other
};
