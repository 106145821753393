import React, { useState } from 'react';
import { Dialog, DialogTitle } from '@design-system/dialog';
import { AddressDetailts } from 'pages/packages/checkout/steps-wizard/components/forms/address-detailts';
import PlusIcon from '@heroicons/react/24/outline/PlusIcon';
import { Shipping } from 'legacy-types/shipping';
import { useTranslation } from 'react-i18next';

type DifferentAddressCTAProps = {
  onSubmit: (address: Shipping['address']) => void;
};

export const DifferentAddressCTA = ({ onSubmit }: DifferentAddressCTAProps) => {
  const { t } = useTranslation();
  const [isAddAddressOpen, setIsAddAddressOpen] = useState(false);

  return (
    <>
      <Dialog
        onClose={() => setIsAddAddressOpen(false)}
        open={isAddAddressOpen}
        className="text-white">
        <DialogTitle onClose={() => setIsAddAddressOpen(false)}>
          {t('checkoutPage.form.shipping.addAddressModal.title')}
        </DialogTitle>
        <div className="mt-2 text-white h-full overflow-scroll">
          <AddressDetailts
            type="shipping"
            ctaText={t('checkoutPage.cta.addAddress')}
            onSubmit={(address) => {
              onSubmit({
                address1: address.address1,
                address2: address.address2,
                city: address.addressCity,
                state: address.addressState,
                country: address.addressCountry,
                postalCode: address.addressPostalCode
              });
              setIsAddAddressOpen(false);
            }}
          />
        </div>
      </Dialog>
      <div>
        <div
          role="button"
          onClick={() => setIsAddAddressOpen(true)}
          className="text-indigo-400 hover:text-indigo-500 mt-1 font-bold flex items-center space-x-1 cursor-pointer">
          <PlusIcon className="w-4" />
          {t('checkoutPage.form.shipping.useDifferentAddress')}
        </div>
      </div>
    </>
  );
};
