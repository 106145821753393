export const CheckoutStatus = {
  // The checkout is created and is pending processing
  PENDING: 'pending',

  // The checkout is pending payment
  PENDING_PAYMENT: 'pending_payment',

  // The checkout is available to proceed
  AVAILABLE: 'available',

  // The checkout is fulfilled, now status is managed by the order itself
  FULFILED: 'fulfilled',

  // The checkout is unavailable to proceed
  UNAVAILABLE: 'unavailable',

  // The checkout has expired
  EXPIRED: 'expired',

  // The checkout has been declined
  DECLINED: 'declined'
} as const;
