import * as React from 'react';
import { useMemo } from 'react';
import { AdjustmentsHorizontalIcon } from '@heroicons/react/24/solid';
import { useTranslation } from 'react-i18next';
import { AccommodationType } from 'legacy-types/accommodation';
import { Categories } from 'pages/types';
import { HotelCategories } from 'pages/constants';
import { Done } from '@mui/icons-material';
import Chip from '@mui/material/Chip';
import SvgIcon from '@mui/material/SvgIcon';

type AccommodationFiltersProps = {
  selectedType?: AccommodationType;
  options: Categories[] & string[];
  selected: string[];
  onChange: (value: string[]) => void;
};
export default function AccommodationFilters(props: AccommodationFiltersProps) {
  const { t } = useTranslation();

  const optionsToRender = useMemo(() => {
    const options: string[] = ['all'];
    props.options.forEach((option) => {
      if (
        !props.selectedType || props.selectedType !== 'camping'
          ? !([HotelCategories.EasyTents, HotelCategories.MG] as string[]).includes(option)
          : ([HotelCategories.EasyTents, HotelCategories.MG] as string[]).includes(option)
      ) {
        options.push(option);
      }
    });
    return options;
  }, [props.options, props.selectedType]);

  return (
    <div role="group" aria-labelledby="rank" className="py-4">
      <div className="p-3 bg-gray-900 text-white rounded-md">
        <div className="flex gap-1 align-middle items-center pb-2">
          <AdjustmentsHorizontalIcon className="w-5 h-5 text-gray-200" />
          <div className="text-md text-gray-300">{t('packageBuilder.filters.bytHotelType')}</div>
        </div>
        <div className="flex flex-wrap gap-2 pt-2">
          {optionsToRender.map((item, index) => (
            <Chip
              key={item}
              sx={{ minWidth: '90px' }}
              label={t(`common.hotelCategories.${item}`)}
              icon={
                props.selected.includes(item) ? (
                  <SvgIcon fontSize="small" className="text-gray-600">
                    <Done />
                  </SvgIcon>
                ) : undefined
              }
              onClick={() => {
                props.onChange([item]);
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
