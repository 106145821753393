import React, { FC } from 'react';
import { useStepsFlow } from 'pages/packages/shop/hooks/use-steps-flow';
import { HotelPerkToIcon } from 'utils/hotel-perk-to-icon';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import ImagesCarousel from 'components/images-carousel';
import { AccommodationTypeChips } from 'pages/packages/shop/components/accommodation-type-chips';
import { STEPS } from 'pages/packages/constants';
import { useTranslation } from 'react-i18next';
import MapWithOverlay from 'pages/packages/shop/components/map-with-overlay';
import { AccommodationType } from 'pages/packages/types';
import { AccommodationMainPerks } from 'pages/packages/shop/components/accommodation-main-perks';
import { InjectHtml } from 'pages/commom/components/inject-html';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons';

type AccommodationDetailProps = {
  onSelect: (value: string) => void;
};
export const AccommodationDetail: FC<AccommodationDetailProps> = () => {
  const { t } = useTranslation();
  const { steps, accommodations } = useStepsFlow((s) => ({
    steps: s.steps,
    accommodations: s.accommodations,
    currentStep: s.currentStep
  }));

  const selectedAccommodation = steps?.[STEPS.ACCOMODATION_LIST];

  if (!selectedAccommodation || !accommodations?.[selectedAccommodation]) return <div />;

  const hotel: AccommodationType = accommodations[selectedAccommodation];

  return (
    <div className="mb-12 sm:mb-0">
      <div className="p-4 bg-gray-100 rounded-md ">
        <div className="flex gap-2 mb-4 ">
          <ImagesCarousel imagesUrl={hotel.images} />
        </div>
        <AccommodationTypeChips category={hotel.category} />
        <div className="text-lg font-bold text-black">{t(`accommodation:${hotel.id}.name`)}</div>
        <div className="text-sm text-gray-800 pb-2 flex gap-1 items-center ">
          {hotel.location.address}
        </div>

        <AccommodationMainPerks withCapacity accommodation={hotel} className="py-3 text-gray-800" />
        {hotel.amenities ? (
          <div className="py-6 md:py-3 border-t mt-2 border-gray-400">
            <div className="font-bold text-gray-900 pb-4">
              {t('packageBuilder.steps.accomodationDetails.accommodationAmenities')}
            </div>
            <div className="grid grid-cols-2 sm:flex flex-wrap gap-2">
              {hotel.amenities.map((perk) => {
                return <HotelPerkToIcon key={perk} perk={perk as any} />;
              })}
            </div>
          </div>
        ) : null}
        <a
          href="https://help.insidemusicland.com/articles/9222075-tomorrowland-brasil-global-journey-politica-de-cancelamento"
          target="_blank"
          className="flex gap-1 text-gray-800 items-center text-sm pt-2 hover:text-black cursor-pointer" rel="noreferrer">
          {t('packageBuilder.steps.accomodationDetails.cancelationPolicy')}
          <FontAwesomeIcon className="h-3 w-3" icon={faArrowUpRightFromSquare} />
        </a>
      </div>
      <div className="flex flex-col gap-4 pt-4 px-2 ">
        <div className="mt-4 text-gray-200 py-6 flex flex-col gap-3">
          <div className="text-xl text-white font-bold">
            {t('packageBuilder.steps.accomodationDetails.aboutAccomodation')}
          </div>
          <div className="max-w-2xl text-gray-500">
            <InjectHtml text={t(`accommodation:${hotel.id}.description`)} />
          </div>
        </div>
        <MapWithOverlay hotel={hotel} />
      </div>
    </div>
  );
};
