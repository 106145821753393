import React, { ButtonHTMLAttributes, forwardRef } from 'react';
import cx from 'classnames';
import { Loading } from '@design-system/feedback/loading';

export type ButtonProps = {
  color?: 'primary' | 'secondary' | 'neutral';
  size?: 'sm' | 'md' | 'lg';
  className?: string;
  children?: React.ReactNode | string;
  loading?: boolean;
  fullWidth?: boolean;
  icon?: React.ReactNode;
  variant?: 'solid' | 'outline' | 'text';
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      icon,
      color = 'primary',
      size = 'md',
      variant = 'solid',
      className,
      children,
      fullWidth,
      loading,
      disabled,
      ...props
    },
    ref
  ) => {
    const colors = {
      primary: {
        solid:
          'bg-pink-600 hover:bg-pink-700 focus:ring-pink-500 focus:ring-offset-pink-200 text-white shadow-sm ',
        outline:
          'border border-pink-600 text-pink-600 hover:bg-pink-50 focus:ring-pink-500 focus:ring-offset-pink-200 shadow-sm ',
        text: 'text-pink-500 hover:text-pink-400 focus:ring-pink-500 focus:ring-offset-pink-200'
      },
      secondary: {
        solid:
          'bg-indigo-400 hover:bg-indigo-500 focus:ring-indigo-400 focus:ring-offset-indigo-300 text-white shadow-sm ',
        outline:
          'border border-indigo-600 text-indigo-600 hover:bg-indigo-50 focus:ring-indigo-500 focus:ring-offset-indigo-200 shadow-sm ',
        text: 'text-indigo-500 hover:text-indigo-400 focus:ring-indigo-500 focus:ring-offset-indigo-200 '
      },
      neutral: {
        solid:
          'bg-gray-800 hover:bg-gray-900 focus:ring-gray-500 focus:ring-offset-gray-200 text-gray-200 shadow-sm ',
        outline:
          'border border-gray-600 text-gray-600 hover:bg-gray-50 focus:ring-gray-500 focus:ring-offset-gray-200 shadow-sm ',
        text: 'text-gray-400 hover:text-gray-500 focus:ring-gray-500 focus:ring-offset-gray-200 bg-transparent'
      }
    };

    return (
      <button
        {...props}
        ref={ref}
        disabled={disabled || loading}
        type="button"
        className={cx(
          'rounded-md border border-transparent text-sm font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:bg-gray-700 disabled:text-gray-500 disabled:cursor-not-allowed cursor-pointer',
          colors[color][variant],
          className,
          fullWidth ? 'w-full ' : 'w-fit',
          { 'px-4 py-2': variant !== 'text' }
        )}>
        <div className="flex gap-1 text-center justify-center">
          <div className="flex gap-1 place-items-center ">{children}</div>
          {loading ? <Loading /> : icon ? <div className="">{icon}</div> : null}
        </div>
      </button>
    );
  }
);
