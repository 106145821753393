import React, { forwardRef, InputHTMLAttributes, useId } from 'react';

export type FieldProps = InputHTMLAttributes<HTMLDivElement> & {
  ref?: React.ForwardedRef<HTMLDivElement>;
  label?: string | React.ReactNode;
  error?: string | React.ReactNode;
};

export const FieldContainer = forwardRef<HTMLDivElement, FieldProps>((props, ref) => {
  const id = useId();
  return (
    <div id={id} className="w-full">
      {props.label ? (
        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
          {props.label}
        </label>
      ) : null}
      <div className={props.className}>{props.children}</div>
      {props.error ? (
        <p className="mt-2 text-sm text-red-600" id="email-error">
          {props.error}
        </p>
      ) : null}
    </div>
  );
});
