import {
  pagarmeAntiFraudFee,
  pagarmeCardFees,
  pagarmeGatewayFee,
  pagarmePixFee,
  stripeFee,
  stripeFeeFixed
} from './constants';
import { PaymentMethods } from 'legacy-types/payment';

export const calculateTotalAmount = (
  purchaseAmount: number,
  paymentMethod: PaymentMethods,
  installments = 1,
  itemsQuantity = 1
): { total: number; fee: number } => {
  let transactionFee = 0;
  switch (paymentMethod) {
    case 'stripe':
      transactionFee = purchaseAmount * stripeFee + stripeFeeFixed / itemsQuantity;
      break;
    case 'pagar.me/card':
      transactionFee = purchaseAmount * pagarmeCardFees[installments];
      break;
    case 'pagar.me/pix':
      transactionFee = purchaseAmount * pagarmePixFee;
      break;
    default:
      return { total: NaN, fee: NaN };
  }

  if (paymentMethod === 'pagar.me/card' || paymentMethod === 'pagar.me/pix') {
    transactionFee += (pagarmeGatewayFee + pagarmeAntiFraudFee) / itemsQuantity;
  }

  return {
    total: Math.ceil(purchaseAmount + transactionFee),
    fee: Math.ceil(transactionFee)
  };
};

export const getStatements = (
  paymentMethod: PaymentMethods,
  purchaseAmount: number,
  installments = 1
) => {
  return Array.from({ length: installments }, (_, i) => {
    const installment = i + 1;
    const { total, fee } = calculateTotalAmount(purchaseAmount, paymentMethod, installment);
    return {
      installment: installment,
      total: total / installment,
      fee
    };
  });
};
