import { UseStepsFlow } from 'pages/packages/types';
import { STEPS } from 'pages/packages/constants';
import { Journey } from 'legacy-types/journeys';
import { findJourneyById } from 'pages/packages/shop/helpers/find-journey-by-id';

export const getJourneyPrice = (get: () => UseStepsFlow, journeyType: Journey['type']) => {
  const steps = {
    journeys: get().journeys,
    outbound: get().steps?.[STEPS.JOURNEY_OUTBOUND]
  };

  const { journeys, outbound } = steps;

  const outboundJourney = findJourneyById(journeys, outbound);

  if (!outboundJourney) return 0;

  return outboundJourney.price;
};
