import React from 'react';
import cn from 'classnames';
import { Trans } from 'react-i18next';
import { useEnv } from 'hooks/use.env';

export const SimulatorBanner = ({ fixed = true }) => {
  const { isSimulator } = useEnv();
  if (!isSimulator) return null;

  return (
    <>
      <div
        className={cn(
          'pointer-events-none inset-x-0 bottom-0 z-50',
          fixed ? 'sm:px-6 sm:pb-5 lg:px-8 fixed' : 'relative'
        )}>
        <div className="pointer-events-auto flex items-center justify-between gap-x-6 bg-pink-800 px-6 py-2.5 rounded-md sm:py-3 sm:pl-4 sm:pr-3.5">
          <p className="text-sm leading-6 text-white text-center">
            <Trans i18nKey="common.simulatorAlert">
              <b>This is a price simulator.</b> Prices can slightly deviate from the prices of the
              Global Journey Sale on <u>March 28, 2024</u>.
            </Trans>
          </p>
        </div>
      </div>
    </>
  );
};
