import { Popover, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';

export const ContactUs = () => {
  const { t } = useTranslation();
  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button className="px-2 py-0.5 rounded-md bg-gray-900/70 flex gap-1 items-center">
            {t('contactUsPopover.title')}
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1">
            <Popover.Panel className="fixed inset-x-0 mx-auto w-full max-w-sm mt-4 z-10 sm:absolute sm:right-0 sm:left-auto sm:mx-0 sm:w-auto sm:max-w-md">
              <div className="overflow-hidden rounded-md shadow-lg">
                <div className="text-lg font-semibold pb-1 bg-gray-200 text-black py-2 px-3">
                  {t('contactUsPopover.title')}
                  <div className="text-xs text-gray-600 pb-1">
                    {t('contactUsPopover.description')}
                  </div>
                </div>
                <div className="relative bg-white  p-4 text-black flex flex-col gap-2">
                  <div>
                    <div>Email</div>
                    <a
                      href="mailto:tomorrowlandbrasil@insidemusicland.com"
                      className="text-gray-700 flex gap-1 items-center py-1 cursor-pointer hover:text-pink-700">
                      tomorrowlandbrasil@insidemusicland.com
                      <FontAwesomeIcon className="h-3 w-3" icon={faArrowUpRightFromSquare} />
                    </a>
                  </div>
                  <div>
                    <div>WhatsApp</div>
                    <a
                      href="https://wa.me/5511912052796"
                      target="_blank"
                      className="text-gray-700 flex gap-1 items-center py-1 cursor-pointer hover:text-pink-700"
                      rel="noreferrer">
                      +55 11 91205-2796
                      <FontAwesomeIcon className="h-3 w-3" icon={faArrowUpRightFromSquare} />
                    </a>
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};
