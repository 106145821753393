import priceToBRL from 'utils/priceToBRL';
import React, { useEffect } from 'react';
import { Shipping, ShippingOption } from 'legacy-types/shipping';
import { useTranslation } from 'react-i18next';

export const ShippingPriceDetails = ({
  shipping,
  shippigDetails
}: {
  shipping: Shipping;
  shippigDetails?: ShippingOption | null;
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = React.useState(false);

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => setIsLoading(false), 500);
  }, [shipping.shippingMethod]);

  if (!shipping.address) return null;

  if (isLoading) {
    return (
      <div className="bg-gray-300/70 p-6 rounded my-8 animate-pulse">
        <div className="h-4 bg-gray-200 rounded w-1/3 mb-4"></div>
        <div className="flex justify-between mt-4">
          <div className="h-4 bg-gray-200 rounded w-1/2"></div>
          <div className="h-4 bg-gray-200 rounded w-1/3"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-gray-300/70 p-4 rounded my-8">
      <h2 className="text-base font-semibold text-gray-900">
        {t('checkoutPage.form.shipping.shippingPrice')}
      </h2>
      <div className="flex justify-between mt-4">
        <div>
          {shipping.shippingMethod === 'ship'
            ? shippigDetails?.courier
            : t('checkoutPage.form.shipping.collection')}
        </div>
        <div>
          {shipping.shippingMethod === 'ship'
            ? priceToBRL.format(shippigDetails?.price || NaN)
            : t('checkoutPage.form.shipping.freeShipping')}
        </div>
      </div>
    </div>
  );
};
