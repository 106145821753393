import { Divider } from '@mui/joy';
import priceToBRL from 'utils/priceToBRL';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

type PackageCardProps = {
  children: React.ReactNode;
  footer?: React.ReactNode;
  header: string;
  helpText?: string;
};

export const PackagePrice = (props: {
  pax: number;
  total: number;
  withPaymentMethods?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col md:flex-row md:justify-between md:items-center gap-2">
      <div>
        <div className="text-gray-800 text-sm font-semibold">
          {t('packageBuilder.accomoddationRoomsWidget.priceForPax', {
            count: props.pax,
            price: priceToBRL.format(props.total)
          })}
        </div>
        <div className="text-xs text-gray-600">{t('common.prices.exclPaymentFee')}</div>
      </div>
    </div>
  );
};

export const PackageCard: FC<PackageCardProps> = ({ header, footer, children, helpText }) => {
  return (
    <div className="outline-gray-800 p-4 bg-gray-100 rounded-lg border border-gray-300">
      <div className="py-1 flex flex-col gap-1">
        <div className="text-md font-semibold tracking-wide text-gray-900 uppercase">{header}</div>
        <div className="text-sm tracking-wide text-gray-600">{helpText}</div>
      </div>
      <Divider sx={{ mt: 1 }} />

      <div className="flex flex-col gap-2 py-6">{children}</div>
      {footer ? (
        <>
          <Divider sx={{ mb: 1 }} />
          <div className="pt-1">{footer}</div>
        </>
      ) : null}
    </div>
  );
};
