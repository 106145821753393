import React from 'react';
import { useTranslation } from 'react-i18next';

export const StepHeader = (props: { step?: string; title?: string; desc?: string }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="text-2xl text-white hidden md:block">
        {props.title || t(`packageBuilder.steps.${props.step}.title`)}
      </div>
      <div className="text-sm text-gray-500 pb-4 md:border-b border-gray-900">
        {props.desc || t(`packageBuilder.steps.${props.step}.subtitle`)}
      </div>
    </>
  );
};
