import { Button } from '@design-system/fields/button';
import { useTranslation } from 'react-i18next';

export function NeedHelpCard() {
  const { t } = useTranslation();
  return (
    <div className="mt-4 max-w-2xl">
      <div>
        <div className="text-white text-lg mb-2 border-b-2 border-pink-700 tracking-wide w-fit">
          {t('needHelpComponent.title')}
        </div>
        <div className="text-md text-gray-400">
          <p>{t('needHelpComponent.description')}</p>
        </div>
      </div>
      <div className="pt-4">
        <a
          target="_blank"
          href="https://tomorrowlandbrasil.zendesk.com/hc/categories/24288784330388-Global-Journey-Travel-Packages"
          className="inline-block"
          rel="noreferrer">
          <Button
            variant="text"
            size="sm"
            className="flex items-center gap-2 rounded-sm"
            color="primary">
            {t('needHelpComponent.cta')}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="h-4 w-4">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
              />
            </svg>
          </Button>
        </a>
      </div>
    </div>
  );
}
