import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useStepsFlow } from 'pages/packages/shop/hooks/use-steps-flow';
import { StepComponent } from 'pages/packages/shop/steps';
import cn from 'classnames';
import { transformToDefaultSteps } from 'pages/packages/shop/helpers/transform-to-default-steps';
import { useNavigate, useParams } from 'react-router-dom';
import { flows } from 'pages/packages/shop/flows';
import { PackagesType, StepType } from 'pages/packages/types';
import { AccommodationRoomsWidget } from 'pages/packages/shop/components/accommodation-rooms-widget';
import { STEPS } from 'pages/packages/constants';
import StepsTab from 'pages/packages/shop/components/steps-tab';
import { Divider } from '@mui/material';
import { ROUTES } from 'contants';
import { AccommodationRoomsDrawer } from 'pages/packages/shop/components/accommodation-rooms-drawer';
import { Trans, useTranslation } from 'react-i18next';
import { PackagesPageContainer } from 'pages/packages/components/packages-page-container';
import { EmptyStateContainer } from 'pages/commom/empty-state-container';
import { useQuery } from 'react-query';
import Countdown from 'react-countdown';
import Cookies from 'js-cookie';
import { useFeatureFlag } from 'configcat-react';

export const PackageShop = () => {
  const { fetchInventoryAvailability, fetchInventory } = useStepsFlow((s) => ({
    fetchInventoryAvailability: s.fetchInventoryAvailability,
    fetchInventory: s.fetchInventory
  }));

  const { value: shoptimelimitenabled, loading: loadingFlags } = useFeatureFlag(
    'shoptimelimitenabled',
    false
  );

  const { isLoading } = useQuery(
    ['fetchInventoryAvailability'],
    async () => {
      return Promise.all([fetchInventoryAvailability(), fetchInventory()]);
    },
    {
      refetchOnWindowFocus: false
    }
  );

  useEffect(() => {
    const hasSessionCookie = Cookies.get('SESSION_START');

    if (!hasSessionCookie) {
      Cookies.set('SESSION_START', new Date(), { expires: 10 / 1440 });
    }
  }, []);

  const [isWarm, setIsWarm] = useState(false);
  const { t } = useTranslation();
  const mainContentRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const { type, step } = useParams<{ step: StepType; type: PackagesType }>();

  const { steps, isFetchingInventory, setStepsOrder, setSteps, setStep, setFlow } = useStepsFlow(
    (s) => ({
      isFetchingInventory: s.isFetchingInventory,
      setSteps: s.setSteps,
      setStepsOrder: s.setStepsOrder,
      setStep: s.setStep,
      setFlow: s.setFlow,
      steps: s.steps
    })
  );

  const sideBarImage = useMemo(() => type && flows[type]?.image, [type]);

  const stepsFlow = useMemo(() => {
    const flow = type ? flows[type] : undefined;

    if (flow) {
      setFlow(flow);
      return flow.steps;
    }

    return [];
  }, [type, setFlow]);

  useEffect(() => {
    if (type && !isWarm) {
      const stepsFlow = flows[type]?.steps || [];
      const defaultSteps = transformToDefaultSteps(stepsFlow);
      setSteps(defaultSteps);
      setStepsOrder(stepsFlow);
      navigate(`${ROUTES.PackageBuider}/${type}/${stepsFlow[0]}`);
      setIsWarm(true);
    }
  }, [isWarm, navigate, setSteps, setStepsOrder, type]);

  const LoadingContainer = () => (
    <EmptyStateContainer
      isLoading
      title={t('loadingStates.loadingSimple.title')}
      description={t('loadingStates.loadingSimple.description')}
    />
  );

  useLayoutEffect(() => {
    mainContentRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, [steps]);

  const PackageShopContent = () => {
    const currentDate = new Date(Cookies.get('SESSION_START') || '');
    const sessionExpireTime = currentDate.setMinutes(currentDate.getMinutes() + 10);

    return (
      <div className="relative overflow-auto" ref={mainContentRef}>
        {steps ? (
          <div className={cn('px-3 pt-10  md:px-8', {})}>
            {shoptimelimitenabled ? (
              <Countdown
                daysInHours
                date={sessionExpireTime}
                onComplete={() => {
                  window.location.href =
                    'https://globaljourneybrasil.queue-it.net/?c=globaljourneybrasil&e=globaljourney2024';
                }}
                renderer={(props) => (
                  <div className="ext-black mt-4 sm:mx-4 py-1 px-2 bg-gray-800 rounded-md sm:max-w-fit text-sm text-center">
                    <Trans
                      i18nKey="checkoutPage.timeToSelect"
                      values={{
                        time: `${String(props.minutes).padStart(2, '0')}:${String(
                          props.seconds
                        ).padStart(2, '0')}`
                      }}>
                      You have <span className="font-bold px-0">??:??</span> to complete your order
                    </Trans>
                  </div>
                )}
              />
            ) : null}
            <StepsTab
              steps={stepsFlow}
              onTabClick={(stepKey: StepType) => setStep(stepKey, null)}
            />
          </div>
        ) : null}
        <Divider sx={{ pt: 1 }} />
        <div className="mx-4 md:mx-12 relative pt-4 flex flex-col gap-3">
          <StepComponent />
        </div>
      </div>
    );
  };

  return (
    <>
      <PackagesPageContainer
        sidebarImage={sideBarImage}
        activeHeader={'shop'}
        sidebar={
          step === STEPS.ACCOMODATION ? (
            <AccommodationRoomsWidget title={t('packageBuilder.accomoddationRoomsWidget.title')} />
          ) : undefined
        }>
        {isFetchingInventory || isLoading || loadingFlags ? (
          <LoadingContainer />
        ) : (
          <PackageShopContent />
        )}
      </PackagesPageContainer>
      <AccommodationRoomsDrawer step={step} />
    </>
  );
};
