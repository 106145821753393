import { AccommodationType } from 'pages/packages/types';
import SvgIcon from '@mui/material/SvgIcon';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import * as React from 'react';
import cn from 'classnames';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenuOutlined';
import { getMealType } from 'pages/commom/components/get-meal-type';
import DepartureBoardIcon from '@mui/icons-material/DepartureBoardOutlined';
import { useTranslation } from 'react-i18next';

export const AccommodationMainPerks = ({
  accommodation,
  withCapacity,
  className
}: {
  accommodation: AccommodationType;
  withCapacity?: boolean;
  className?: string;
}) => {
  const { t } = useTranslation();

  return (
    <ul
      className={cn(
        'box-border flex gap-1 md:gap-3 flex-col md:flex-row list-none md:items-center ',
        className
      )}>
      <li className="flex items-center gap-1 text-left">
        <SvgIcon fontSize="small" sx={{ color: 'green' }}>
          <RestaurantMenuIcon />
        </SvgIcon>
        <span className="text-sm">{getMealType(accommodation.mealPlan, t)}</span>
      </li>

      {withCapacity ? (
        <li className="flex items-center gap-1 text-left">
          <SvgIcon fontSize="small" sx={{ color: 'green' }}>
            <PeopleAltOutlinedIcon />
          </SvgIcon>
          <span className="text-sm">
            {Array.from({
              length: accommodation.roomsCapacity.max - accommodation.roomsCapacity.min + 1
            })
              .map((_, index) => `${index + accommodation.roomsCapacity.min}P`)
              .join(' / ')}
          </span>
        </li>
      ) : null}

      <li className="flex gap-1 text-left">
        <SvgIcon fontSize="small" sx={{ color: 'green' }}>
          <DepartureBoardIcon />
        </SvgIcon>
        <span className="text-sm">
          {accommodation.location.distanceToEvent === 0
            ? t('packageBuilder.notNeeded')
            : t('packageBuilder.distanceToEvent', {
                distance: accommodation.location.distanceToEvent
              })}
        </span>
      </li>
    </ul>
  );
};
