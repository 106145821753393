import LandingPage from 'pages/landing-page/landing-page';
import Countdown from 'react-countdown';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';

// const GENERAL_SALES_DATE = new Date('2024-04-22T10:00:00-03:00');
// const PRE_SALES_DATE = new Date('2024-04-18T05:20:00-03:00');

export const PRE_SALES_DATE = new Date('2024-04-18T10:00:00-03:00');
export const GENERAL_SALES_DATE = new Date('2024-04-22T10:00:00-03:00');

const now = new Date();

export const CoutdownPage = ({ showPreSale }: { showPreSale: boolean }) => {
  const { t } = useTranslation();

  const isPreSale = showPreSale && now <= PRE_SALES_DATE;

  return (
    <Countdown
      onComplete={() => {
        window.location.reload();
      }}
      daysInHours
      date={(isPreSale ? PRE_SALES_DATE : GENERAL_SALES_DATE).getTime()}
      renderer={(props) => (
        <LandingPage showHint={false}>
          <h1 className="text-xl text-gray-200 text-center space-x-2 md:flex flex-col gap-1">
            <span>{t('countdown.title', { context: isPreSale ? 'pre_sale' : '' })}</span>
            <span className="md:hidden">•</span>
            <span>10:00 BRT / 15:00 CEST.</span>
          </h1>
          <div className="bg-black flex items-center justify-center px-5 pb-4">
            <div className="text-white">
              <div className="text-xl md:text-2xl text-center flex w-full items-center justify-center">
                <div className="text-2xl mr-1 font-extralight">{t('countdown.in')}</div>
                <div className="w-24 mx-1 p-2 bg-white text-black rounded-lg">
                  <div className="font-mono leading-none">{props.days}</div>
                  <div className="font-mono uppercase text-sm leading-none">
                    {t('countdown.days')}
                  </div>
                </div>
                <div className="w-24 mx-1 p-2 bg-white text-black rounded-lg">
                  <div className="font-mono leading-none">{props.hours}</div>
                  <div className="font-mono uppercase text-sm leading-none">
                    {t('countdown.hours')}
                  </div>
                </div>
                <div className="w-24 mx-1 p-2 bg-white text-black rounded-lg">
                  <div className="font-mono leading-none">{props.minutes}</div>
                  <div className="font-mono uppercase text-sm leading-none">
                    {t('countdown.minutes')}
                  </div>
                </div>
                <div className="hidden md:block w-24 mx-1 p-2 bg-white text-black rounded-lg">
                  <div className="font-mono leading-none">{props.seconds}</div>
                  <div className="font-mono uppercase text-sm leading-none">
                    {t('countdown.seconds')}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <a
            href="https://my.tomorrowland.com/"
            className="bg-gradient-custom text-custom-light p-[.813rem] px-8 uppercase tracking-wide text-sm flex items-center gap-2 mb-12 cursor-pointer font-semibold">
            {t('countdown.preRegister')}
            <FontAwesomeIcon className="h-3 w-3" icon={faChevronRight} />
          </a>
        </LandingPage>
      )}
    />
  );
};
