import Cookies from 'js-cookie';
import { Trans, useTranslation } from 'react-i18next';
import { useState } from 'react';
import Backdrop from '@mui/material/Backdrop';

const PRIVACY_POLICY_COOKIE = 'storefrontCookieConsent';

export function PrivacyNotice() {
  const [isCookieConsent, setIsCookieConsent] = useState(false);
  const { t, i18n } = useTranslation();
  if (Cookies.get(PRIVACY_POLICY_COOKIE) || isCookieConsent) return null;

  const handleCookieConsent = () => {
    setIsCookieConsent(true);
    Cookies.set(String(PRIVACY_POLICY_COOKIE), String('true'), { expires: 365 });
  };

  return (
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
      <div className="pointer-events-none fixed inset-x-0 bottom-0 px-6 pb-6 z-max">
        <div className="pointer-events-auto ml-auto max-w-xl rounded-xl bg-white p-6 shadow-lg ring-1 ring-gray-900/10">
          <p className="text-sm leading-6 text-gray-900">
            <Trans i18n={i18n} i18nKey="common.privacyPolicy.message">
              To enhance your experience, our website uses cookies which are essential for the site
              to function effectively. These cookies help us improve site performance, offer
              personalized features, and analyze site traffic to serve you better. By continuing to
              browse our site, you agree to our use of cookies.
              <a
                target="_blank"
                href="https://help.insidemusicland.com/articles/9020887-privacy-policy"
                className="font-semibold text-indigo-600"
                rel="noreferrer">
                Learn More
              </a>
              .
            </Trans>
          </p>
          <div className="mt-4 flex items-center gap-x-5">
            <button
              onClick={() => handleCookieConsent()}
              type="button"
              className="rounded-md bg-gray-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900">
              {t('common.privacyPolicy.accept')}
            </button>
          </div>
        </div>
      </div>
    </Backdrop>
  );
}
