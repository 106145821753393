import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { SupportedLocales } from 'legacy-types/legacy/locale';

export const termsMapping: Record<SupportedLocales, string> = {
  'pt-BR': 'https://brasil.tomorrowland.com/pt/termos-condicoes/',
  'en-GB': 'https://brasil.tomorrowland.com/en/terms-conditions/',
  'es-ES': 'https://brasil.tomorrowland.com/en/terms-conditions/'
};

export const TermsAndConditions = () => {
  const { i18n } = useTranslation();

  const languageMappting = {
    'pt-BR': 'pt',
    'en-GB': 'en',
    'es-ES': 'en'
  };

  const language = languageMappting[i18n.language] || 'en';

  return (
    <div className="text-sm">
      <div>
        <Trans i18nKey="checkoutPage.terms" className="text-gray-800 text-sm md:text-left">
          By clicking on <b>"I Agree & Continue"</b> you agree to the present
          <a
            className="text-pink-600 border-b-2 border-pink-700"
            href={`https://brasil.tomorrowland.com/${language}/global-journey-terms-conditions`}
            target="_blank"
            rel="noreferrer">
            Terms and Conditions of Sale for Global Journey
          </a>
          s well as to the Festival Organizer’s General Terms and Conditions for Tomorrowland Brasil
          2024,
          <a
            className="text-pink-600 border-b-2 border-pink-700"
            href={termsMapping[i18n.language]}
            target="_blank"
            rel="noreferrer">
            available here
          </a>
          and the
          <a
            target="_blank"
            className="text-pink-600 border-b-2 border-pink-700"
            href="https://help.insidemusicland.com/articles/9222075-tomorrowland-brasil-global-journey-politica-de-cancelamento"
            rel="noreferrer">
            cancelation policy
          </a>{' '}
          for the selected packages
        </Trans>
      </div>
    </div>
  );
};
