import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStepsFlow } from 'pages/packages/shop/hooks/use-steps-flow';
import { Journey, JourneyLocation } from 'legacy-types/journeys';
import { ItemCard } from 'pages/packages/shop/components/item-card';
import { Country, State } from 'country-state-city';
import { StepHeader } from 'pages/packages/shop/components/step-header';
import { track } from '@common/analytics/use-analytics';

type JourneyOriginProps = {
  type: 'state' | 'country';
  onSelect: (origin: string) => void;
};

const getAllOrigins = (journeys: JourneyLocation, type?: JourneyOriginProps['type']) => {
  const origin = new Map<string, Journey['origin']>();
  // Flatten the array of origins using flatMap for better readability and efficiency
  Object.values(journeys).flatMap((countryJourneys) =>
    Object.values(countryJourneys).forEach((journey) => {
      switch (type) {
        case 'state':
          if (journey.type === 'bus') origin.set(journey.origin.state, journey.origin);
          break;
        case 'country':
          if (journey.type === 'flight') origin.set(journey.origin.country, journey.origin);
          break;
        default:
          break;
      }
    })
  );

  return Array.from(origin);
};

export const JourneyOrigin = (props: JourneyOriginProps) => {
  const { t } = useTranslation();

  const journeys = useStepsFlow((state) => state.journeys);

  const locations = getAllOrigins(journeys, props.type).sort((a, b) => {
    const [, locationA] = a;
    const [, locationB] = b;

    return locationA.country.localeCompare(locationB.country);
  });

  return (
    <div className="cursor-pointer">
      <StepHeader step="journeyOrigin" />
      <div className="flex flex-col gap-6 pt-6">
        {locations.map(([key, location]) => {
          const country = Country.getCountryByCode(location.country);
          const state = State.getStateByCodeAndCountry(location.state, location.country);

          return (
            <div
              key={`${key}_${location.country}-${location.state}`}
              className=""
              onClick={(e) => {
                e.preventDefault();
                props.onSelect(props.type === 'state' ? location.state : location.country);
                void track('journey_origin_selected', 'track', {
                  country: location.country,
                  state: location.state,
                  type: props.type
                });
              }}>
              <ItemCard
                title={props.type === 'state' ? state?.name : country?.name}
                subtitle={''}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
