import React from 'react';
import { GlobalJourneyIcon } from 'images/tomorrowland-brasil/gj-logo';

export interface LogoProps {
  img?: string;
  imgLight?: string;
  className?: string;
  maxH?: string;
}

const Logo: React.FC<LogoProps> = ({ maxH = 'max-h-12' }) => {
  return <GlobalJourneyIcon className={`block w-9 h-9 fill-pink-700 `} />;
};

export default Logo;
