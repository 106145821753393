import * as React from 'react';
import { ReactElement, useMemo } from 'react';
import RadioGroup from '@mui/joy/RadioGroup';
import Chip from '@mui/joy/Chip';
import { PaymentMethods } from 'legacy-types/payment';
import { StackCardOption, StackedCards } from '@design-system/components/stacked-cards';
import { useTranslation } from 'react-i18next';
import { AmexSVG, ApplePaySVG, MastercardSVG, PixSVG, VisaSVG } from 'svg/payment-methods';

type PaymentMethodsSelectorProps = {
  value: string;
  onChange: (value: string) => void;
  countryIso: string;
  nationality: string;
  config: {
    paymentMethodCreditCardValue: boolean;
    paymentMethodPixValue: boolean;
    paymentMethodCreditInstalmentsValue: boolean;
  };
};

export const PaymentMethodsSelector = (props: PaymentMethodsSelectorProps) => {
  const {
    paymentMethodCreditInstalmentsValue,
    paymentMethodCreditCardValue,
    paymentMethodPixValue
  } = props.config;

  console.log(props.config);

  const { t } = useTranslation();
  const PaymentMethodsList = useMemo(() => {
    const options: {
      label: string | ReactElement;
      value: PaymentMethods;
      description?: string | ReactElement;
      disabled?: boolean;
      hidden?: boolean;
      icon?: ReactElement;
    }[] = [];

    options.push({
      label: t('checkoutPage.payment.paymentMethods.creditCard'),
      disabled: !paymentMethodCreditCardValue,
      hidden: !paymentMethodCreditCardValue,
      value: 'stripe',
      icon: (
        <div className="flex gap-1 pr-2">
          <MastercardSVG className="w-8" />
          <VisaSVG className="w-8" />
          <AmexSVG className="w-8" />
          <ApplePaySVG className="w-8" />
        </div>
      )
    });

    options.push({
      label: t('checkoutPage.payment.paymentMethods.creditCardInstallments'),
      description: (
        <Chip color="success" size="sm">
          {t('checkoutPage.payment.brazilOnly')}
        </Chip>
      ),
      value: 'pagar.me/card',
      disabled: props.countryIso !== 'BR' || props.nationality !== 'BR',
      hidden: !paymentMethodCreditInstalmentsValue,
      icon: (
        <div className="flex gap-1 pr-2">
          <MastercardSVG className="w-8" />
          <VisaSVG className="w-8" />
          <AmexSVG className="w-8" />
        </div>
      )
    });

    options.push({
      label: t('checkoutPage.payment.paymentMethods.pix'),
      description: (
        <Chip color="success" size="sm">
          {t('checkoutPage.payment.brazilOnly')}
        </Chip>
      ),
      value: 'pagar.me/pix',
      disabled: props.countryIso !== 'BR' || props.nationality !== 'BR',
      hidden: !paymentMethodPixValue,
      icon: <PixSVG className="w-8" />
    });

    return options.filter((opt) => !opt.hidden);
  }, [
    paymentMethodCreditCardValue,
    paymentMethodCreditInstalmentsValue,
    paymentMethodPixValue,
    props.countryIso,
    t
  ]);

  if (PaymentMethodsList.length <= 1) return null;

  const options: StackCardOption[] = PaymentMethodsList.map((opt) => {
    return {
      id: opt.value,
      name: opt.label,
      disabled: opt.disabled,
      description: (
        <div className="pt-2 flex flex-col gap-2">
          {opt.description}
          {opt.icon}
        </div>
      ),
      price: '',
      priceLabel: ''
    };
  });

  return (
    <>
      <RadioGroup aria-label="Your plan" name="people" defaultValue="Individual">
        <div>
          <StackedCards
            value={options.find((opt) => opt.id === props.value)}
            onChange={(opt) => props.onChange(opt.id)}
            options={options}
            label=""
          />
        </div>
      </RadioGroup>
    </>
  );
};
