import { AddressDetailsForm } from 'pages/packages/checkout/types';

export const mapFormAddress = (address: AddressDetailsForm) => {
  return {
    address1: address.address1,
    address2: address.address2,
    city: address.addressCity,
    state: address.addressState,
    country: address.addressCountry,
    postalCode: address.addressPostalCode
  };
};
