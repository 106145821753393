import { PackagesType, StepType } from 'pages/packages/types';
import { JOURNEY_TYPE, STEPS } from 'pages/packages/constants';
import { Categories } from 'pages/types';
import { PackagesCategory } from 'contants';
import { JourneyType } from 'legacy-types/journeys';

export type Flow = {
  steps: StepType[];
  image: string;
  accomodationCategories?: Categories[];
  accomodationCategoriesFilter?: {
    location?: Record<string, Categories[]>;
  };
  journeyType?: JourneyType;
  accommodationTypeFilter?: boolean;
};

export type Flows = Partial<{
  [key in PackagesType]: Flow;
}>;

const COMMON_STEPS = [
  STEPS.ACCOMODATION_LIST,
  STEPS.ACCOMODATION,
  STEPS.TICKET_SELECTION,
  STEPS.SUMMARY
];

export const flows: Flows = {
  [PackagesCategory.HotelPackage]: {
    image: '/images/tomorrowland/packages/hotel_packages.jpeg',
    accomodationCategories: ['thematic', 'bronze', 'silver', 'gold'],
    steps: [STEPS.CHECKIN_DATE, STEPS.CHECKOUT_DATE, ...COMMON_STEPS]
  },
  [PackagesCategory.FlightPackage]: {
    image: '/images/tomorrowland/packages/flight_packages.jpeg',
    accommodationTypeFilter: true,
    journeyType: JOURNEY_TYPE.FLIGHT,
    accomodationCategories: [
      'bronze',
      'silver',
      'gold',
      'thematic',
      'easy_tents',
      'magnificent_greens'
    ],
    steps: [
      STEPS.JOURNEY_ORIGIN_COUNTRY,
      STEPS.JOURNEY_ORIGIN,
      STEPS.JOURNEY_OUTBOUND,
      STEPS.JOURNEY_RETURN,
      ...COMMON_STEPS
    ]
  },
  [PackagesCategory.BusPackage]: {
    image: '/images/tomorrowland/packages/bus_package.jpeg',
    accommodationTypeFilter: true,
    journeyType: JOURNEY_TYPE.BUS,
    accomodationCategories: [
      'bronze',
      'silver',
      'gold',
      'thematic',
      'easy_tents',
      'magnificent_greens'
    ],
    // accomodationCategoriesFilter: {
    //   location: {
    //     SP: ['bronze', 'silver', 'gold', 'thematic']
    //   }
    // },
    steps: [
      STEPS.JOURNEY_ORIGIN_STATE,
      STEPS.JOURNEY_ORIGIN,
      STEPS.JOURNEY_OUTBOUND,
      STEPS.JOURNEY_RETURN,
      ...COMMON_STEPS
    ]
  },
  [PackagesCategory.AccommodationOnly]: {
    image: '/images/tomorrowland/packages/hotel_packages.jpeg',
    accomodationCategories: ['thematic', 'bronze', 'silver', 'gold'],
    steps: [
      STEPS.CHECKIN_DATE,
      STEPS.CHECKOUT_DATE,
      STEPS.ACCOMODATION_LIST,
      STEPS.ACCOMODATION,
      STEPS.SUMMARY
    ]
  }
};
