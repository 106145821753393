import cn from 'classnames';
import { DialogContent, DialogTitle, Drawer, ModalClose } from '@mui/joy';
import { Divider } from '@mui/material';
import { AccommodationRoomsWidget } from 'pages/packages/shop/components/accommodation-rooms-widget';
import React, { useEffect, useState } from 'react';
import { STEPS } from 'pages/packages/constants';
import { useStepsFlow } from 'pages/packages/shop/hooks/use-steps-flow';
import { StepType } from 'pages/packages/types';
import { useTranslation } from 'react-i18next';
import { Button } from '@design-system/fields/button';

export const AccommodationRoomsDrawer = (props: { step?: StepType }) => {
  const { t } = useTranslation();
  const steps = useStepsFlow((s) => s.steps);
  const accommodationsRooms = useStepsFlow((s) => s.accommodationsRooms);
  const resetSelectedRooms = useStepsFlow((s) => s.resetSelectedRooms);
  const [showRooms, setShowRooms] = useState(false);
  const selectedAccommodation = steps?.[STEPS.ACCOMODATION_LIST];
  const room = selectedAccommodation ? accommodationsRooms?.[selectedAccommodation] : undefined;

  useEffect(() => setShowRooms(false), []);

  if (!room || !selectedAccommodation || props.step !== STEPS.ACCOMODATION) return null;

  return (
    <div className={cn('z-50 fixed bg-black lg:hidden top-auto bottom-0 w-full')}>
      <Drawer
        slotProps={{
          content: {
            sx: {
              bgcolor: 'black'
            }
          }
        }}
        variant="solid"
        open={showRooms}
        size="lg"
        onClose={() => {
          resetSelectedRooms();
          setShowRooms(false);
        }}>
        <DialogTitle>
          <div className="text-gray-200 border-b-2 border-pink-700">
            {t('packageBuilder.accomoddationRoomsWidget.title')}
          </div>
        </DialogTitle>
        <ModalClose />
        <DialogContent sx={{ gap: 2 }}>
          <AccommodationRoomsWidget />
        </DialogContent>
      </Drawer>
      <Divider />
      <div className="p-4 flex flex-col gap-3">
        <Button
          fullWidth
          onClick={() => {
            resetSelectedRooms();
            setShowRooms(true);
          }}>
          {t('packageBuilder.accomoddationRoomsWidget.ctaButton')}
        </Button>
      </div>
    </div>
  );
};
