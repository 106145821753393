import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Step } from 'pages/packages/checkout/steps-wizard/components/step';
import { useTranslation } from 'react-i18next';
import { Button } from '@design-system/fields/button';
import { ShippingMethods } from 'pages/packages/checkout/steps-wizard/steps/shipping-details/shipping-method';
import { Shipping } from 'legacy-types/shipping';
import { FormDetails } from 'pages/packages/checkout/hooks/use-checkout-form';
import { useWizard } from 'react-use-wizard';
import { ShippingPriceDetails } from 'pages/packages/checkout/steps-wizard/steps/shipping-details/shipping-price-details';
import { produce } from 'immer';
import { mapFormAddress } from 'pages/packages/checkout/steps-wizard/steps/shipping-details/helpers/map-form-address';
import { CheckoutItem } from 'legacy-types/checkout';
import { useStepsFlow } from 'pages/packages/shop/hooks/use-steps-flow';
import { useShippingOptions } from 'pages/packages/checkout/hooks/use-shipping-store';

export type ShppingStepProps = {
  data: CheckoutItem[];
  pax: number;
  formsDetails: FormDetails;
  onComplete: (shipping: Shipping) => void;
};

export const Shpping = ({ formsDetails, onComplete, pax, data }: ShppingStepProps) => {
  const { getShippingByLocation } = useShippingOptions();

  const accommodations = useStepsFlow((s) => s.accommodations);

  const { t } = useTranslation();
  const { nextStep } = useWizard();

  const [shipping, setShipping] = useState<Shipping>({
    shippingMethod: 'collect'
  });

  const acommodation = accommodations?.[data[0].accommodation.id];

  const shippigDetails = useMemo(
    () =>
      shipping.address && getShippingByLocation(shipping.address.country, shipping.address.state),
    [getShippingByLocation, shipping.address]
  );

  const allFreeTicket = data.every((d) => d.ticket?.priceType === 'not_included');

  useEffect(() => {
    const mainBuyerAddress = formsDetails.addressDetails;
    const mainBuyerDetails = formsDetails.personalDetails;

    if (mainBuyerAddress && !shipping.address) {
      setShipping(
        produce((draft) => {
          draft.name = `${mainBuyerDetails?.firstName} ${mainBuyerDetails?.lastName}`;
          draft.address = mapFormAddress(mainBuyerAddress);
        })
      );
    }
  }, [allFreeTicket, formsDetails, onComplete, setShipping, shipping]);

  const onContinue = useCallback(async () => {
    onComplete(
      produce(shipping, (draft) => {
        if (draft.shippingMethod === 'collect') {
          delete draft.uuid;
        }
      })
    );
    await nextStep();
  }, [nextStep, onComplete, shipping]);

  // enforce shipping method to ship if country is Brazil
  useEffect(() => {
    if (formsDetails.addressDetails?.addressCountry === 'BR') {
      setShipping(
        produce((draft) => {
          draft.shippingMethod = 'ship';
          draft.uuid = shippigDetails?.uuid;
        })
      );
    }
  }, [formsDetails, shippigDetails]);

  useEffect(() => {
    (async () => {
      allFreeTicket && (await nextStep());
    })();
  }, [allFreeTicket, nextStep, onContinue]);

  if (allFreeTicket) return null;

  return (
    <Step title={t('checkoutPage.steps.shipping')}>
      <div className="flex flex-col">
        <ShippingMethods
          shipTo={acommodation?.type}
          accommodationName={acommodation?.name}
          shippigDetails={shippigDetails}
          shipping={shipping}
          setShipping={(shippingDetail) => {
            setShipping(shippingDetail);
          }}
          config={{
            hideCollect: formsDetails.addressDetails?.addressCountry === 'BR',
            hideShip: false
          }}
        />
        <ShippingPriceDetails shipping={shipping} shippigDetails={shippigDetails} />
      </div>

      <Button onClick={onContinue} className="w-full">
        {t('checkoutPage.cta.continue')}
      </Button>
    </Step>
  );
};
