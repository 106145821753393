/**
 * Pagar me
 */
export const pagarmeAntiFraudFee = 0.3;
export const pagarmeGatewayFee = 0.3;

export const pagarmePixFee = 0.01;
export const pagarmeCardFees = {
  1: 0.03627,
  2: 0.0464,
  3: 0.0546,
  4: 0.063,
  5: 0.0715,
  6: 0.0801,
  7: 0.09,
  8: 0.099,
  9: 0.1081,
  10: 0.1173,
  11: 0.1266,
  12: 0.1363
};

/**
 * Stripe
 */
export const stripeFee = 0.0416;
export const stripeFeeFixed = 0.45;
