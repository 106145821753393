import { create } from 'zustand';
import {
  AddressDetailsForm,
  PaymenyDetailsForm,
  PersonalDetailsForm
} from 'pages/packages/checkout/types';
import { API_URLS, apiFetch } from 'utils/apiFetch';
import { PaymentRequest } from 'legacy-types/payment';
import { OrderCustomerDetails, OrderGuestDetails } from 'legacy-types/customer';
import { Shipping } from 'legacy-types/shipping';

export type FormDetails = {
  personalDetails?: PersonalDetailsForm;
  addressDetails?: AddressDetailsForm;
  paymentDetails?: PaymenyDetailsForm;
  guests?: Record<string, OrderGuestDetails[]>;
  shipping: Shipping;
};

type useCheckoutFormState = {
  updateFormDetails: (form: Partial<FormDetails>) => void;
  processCheckout: (checkoutHash: string, locale: OrderCustomerDetails['locale']) => Promise<void>;
  formsDetails: FormDetails;
  isLoading: boolean;
};

export const useCheckoutForm = create<useCheckoutFormState>()((set, get) => ({
  isLoading: false,
  formsDetails: {
    shipping: {
      shippingMethod: 'collect'
    },
    personalDetails: undefined,
    addressDetails: undefined,
    paymentDetails: undefined,
    guests: undefined
  },
  updateFormDetails: (form) => {
    const formsDetails = get().formsDetails;
    set({ formsDetails: { ...formsDetails, ...form } });
  },
  processCheckout: async (checkoutHash, locale) => {
    set({ isLoading: true });
    try {
      const data = get().formsDetails;

      if (
        !data.personalDetails ||
        !data.addressDetails ||
        !data.paymentDetails ||
        !data.guests ||
        !data.shipping
      ) {
        // TOOD: handle with error message
        return;
      }

      const requestBody: PaymentRequest = {
        customer: {
          ...data.addressDetails,
          ...data.personalDetails,
          locale,
          documentType: data.paymentDetails.documentType,
          documentValue: data.paymentDetails.documentValue
        },
        guests: data.guests,
        paymentMethod: data.paymentDetails.paymentMethod,
        shipping: data.shipping,
        installments: data.paymentDetails.installments
      };

      const url = await apiFetch(`${API_URLS.CHECKOUT_SUBMIT_PAYMENT}/${checkoutHash}`).post(
        requestBody
      );

      window.location.replace(url);
    } catch (err) {
      console.error(err);

      set({ isLoading: false });
    }
  }
}));
