import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { GuestDetails } from 'pages/packages/checkout/steps-wizard/components/forms/guest-details';
import { produce } from 'immer';
import { OrderGuestDetails } from 'legacy-types/customer';
import { GuestListRow } from 'pages/commom/components/order-guests/guest-list-row';
import { useWizard } from 'react-use-wizard';
import { Button } from '@design-system/fields/button';
import { useTranslation } from 'react-i18next';
import { useCheckoutForm } from 'pages/packages/checkout/hooks/use-checkout-form';
import PlusCircleIcon from '@heroicons/react/24/outline/PlusIcon';
import { v4 as uuidv4 } from 'uuid';

export type GuestsListProps = {
  itemId: string;
  capacity: number;
  defaultGuests?: OrderGuestDetails[];
  onComplete: (itemId: string, data: OrderGuestDetails[]) => void;
  onAddGuest: (guest: OrderGuestDetails) => void;
  onRemove: (guestId: string) => void;
};

export const GuestsList: FC<GuestsListProps> = ({
  itemId,
  capacity,
  onComplete,
  defaultGuests,
  onRemove,
  onAddGuest
}) => {
  const { t } = useTranslation();
  const formsDetails = useCheckoutForm((s) => s.formsDetails);
  const guestsListRef = useRef<HTMLDivElement>(null);

  const { nextStep, isLastStep } = useWizard();
  const [isAdd, setIsAdd] = React.useState(true);
  const [guests, setGuests] = React.useState<OrderGuestDetails[]>();

  const isComplete = guests?.length === capacity;

  const onContinue = useCallback(async () => {
    if (!guests) return;

    onComplete(itemId, guests);
    setIsAdd(false);
    await nextStep();
  }, [onComplete, itemId, guests, nextStep]);

  useEffect(() => {
    !guests?.length && defaultGuests && setGuests(defaultGuests);
  }, [defaultGuests, guests]);

  const isMainBuyerInTheList = useMemo(() => {
    return (
      [...(defaultGuests || []), ...(guests || [])].some(
        (guest) => guest.email === formsDetails.personalDetails?.email
      ) || false
    );
  }, [defaultGuests, formsDetails.personalDetails?.email, guests]);

  const handleSetMainBuyerData = useCallback(() => {
    const persinalDetials = formsDetails.personalDetails;

    if (persinalDetials) {
      setGuests(
        produce(guests, (draft) => {
          draft?.push({
            id: uuidv4(),
            firstName: persinalDetials.firstName,
            lastName: persinalDetials.lastName,
            email: persinalDetials.email,
            nationality: persinalDetials.nationality,
            gender: persinalDetials?.gender,
            birthDate: persinalDetials.birthDate
          });
        })
      );
    }
  }, [formsDetails.personalDetails, guests]);

  return (
    <div id={`guest-list-${itemId}`}>
      <div className="pb-2 font-bold">
        {t('checkoutPage.formGuests.guestN', {
          n: guests?.length || 0,
          max: capacity
        })}
      </div>
      <div
        ref={guestsListRef}
        role="list"
        className="divide-y divide-gray-100 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 rounded-md scroll-mt-32 scroll-pt-32 ">
        {guests?.map((guest) => {
          return (
            <GuestListRow
              key={`${itemId}-${guest.email}`}
              guest={guest}
              onRemove={() => {
                setIsAdd(true);
                setGuests(
                  produce(guests, (draft) => {
                    return draft.filter((g) => g.id !== guest.id);
                  })
                );
                onRemove(guest.id);
              }}
            />
          );
        })}
      </div>
      {!isMainBuyerInTheList ? (
        <div
          role="button"
          onClick={handleSetMainBuyerData}
          className="px-2 py-1 border border-pink-300 rounded-md w-fit flex items-center gap-1 mt-4 text-sm font-bold text-pink-600 hover:border-pink-700 hover:bg-gray-200 cursor-pointer">
          <PlusCircleIcon className="w-4" />
          {t('checkoutPage.formGuests.useMainBuyerData')}
        </div>
      ) : null}
      {!isComplete && isAdd ? (
        <div className="pt-4">
          <GuestDetails
            onSubmit={(data) => {
              onAddGuest(data);
              setGuests((prev = []) => {
                return produce(prev, (draft) => {
                  draft.push(data);
                });
              });
              guestsListRef.current?.scrollIntoView({ behavior: 'smooth' });
            }}
          />
        </div>
      ) : null}
      {isComplete ? (
        <Button className="mt-4" fullWidth onClick={onContinue} type="submit">
          {t('checkoutPage.cta.continue')}
        </Button>
      ) : null}
    </div>
  );
};
