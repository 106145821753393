import { sanitize } from 'isomorphic-dompurify';
import { useMemo } from 'react';

export const InjectHtml = ({ text }: { text: string }) => {
  const sanitazedData = useMemo(
    () => ({
      __html: text ? sanitize(text) : ''
    }),
    [text]
  );

  return <div dangerouslySetInnerHTML={sanitazedData} />;
};
