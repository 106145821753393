import { Controller, useForm } from 'react-hook-form';
import { GenderNumeric } from 'contants';
import React, { FC } from 'react';
import { PersonalDetailsForm } from 'pages/packages/checkout/types';
import { BirthDateInput } from 'pages/packages/checkout/components/birth-date-input';
import { CountryDropdown } from 'pages/packages/checkout/components/country-dropdown';
import AccessibilityIcon from '@mui/icons-material/AccessibleForwardOutlined';
import SvgIcon from '@mui/material/SvgIcon';
import { Input } from '@design-system/fields/input';
import { RadioGroups } from '@design-system/fields/radio-groups';
import { Button } from '@design-system/fields/button';
import 'react-phone-number-input/style.css';
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { FieldContainer } from '@design-system/fields/field-container';
import { useGeolocation } from '@common/hooks/use-geolocation';
import { Trans, useTranslation } from 'react-i18next';

type PersonalDetailsProps = {
  onSubmit: (values: PersonalDetailsForm) => void;
  defaultValues?: Partial<PersonalDetailsForm>;
};

export const PersonalDetails: FC<PersonalDetailsProps> = ({ onSubmit, defaultValues }) => {
  const { countryCode } = useGeolocation();
  const { t } = useTranslation();
  const form = useForm<PersonalDetailsForm>({
    defaultValues: defaultValues
  });

  return (
    <>
      <div className="grid grid-rows-1 sm:grid-cols-2 gap-4 my-2 ">
        <Input
          label={t('checkoutPage.form.email')}
          error={form.formState.errors.email?.message}
          autoComplete="email"
          {...form.register('email', {
            required: t('checkoutPage.form.mandatoryField')
          })}
        />
        <Controller
          rules={{ required: t('checkoutPage.form.mandatoryField') }}
          control={form.control}
          name={'nationality'}
          render={({ field }) => (
            <CountryDropdown
              label={t('checkoutPage.form.nationality')}
              error={form.formState.errors.nationality?.message}
              value={field.value}
              onChange={(c) => {
                field.onChange(c);
              }}
            />
          )}
        />
        <Input
          error={form.formState.errors.firstName?.message}
          label={t('checkoutPage.form.firstName')}
          autoComplete="firstName"
          {...form.register('firstName', {
            required: t('checkoutPage.form.mandatoryField')
          })}
        />
        <Input
          {...form.register('lastName', {
            required: t('checkoutPage.form.mandatoryField')
          })}
          error={form.formState.errors.lastName?.message}
          label={t('checkoutPage.form.lastName')}
          autoComplete="lastName"
        />

        <div>
          <FieldContainer
            error={form.formState.errors.phoneNumber?.message}
            label={t('checkoutPage.form.phoneNumber')}>
            <PhoneInputWithCountry
              rules={{
                required: t('checkoutPage.form.mandatoryField'),
                validate: (value: string) =>
                  isValidPhoneNumber(value) || t('checkoutPage.form.invalidPhone')
              }}
              defaultCountry={countryCode}
              control={form.control}
              name="phoneNumber"
              className="w-full"
              inputComponent={Input}
            />
          </FieldContainer>
        </div>

        {/*<Input*/}
        {/*  {...form.register('phoneNumber', {*/}
        {/*    required: 'Phone number is mandatory'*/}
        {/*  })}*/}
        {/*  error={form.formState.errors.phoneNumber?.message}*/}
        {/*  label="Phone Number"*/}
        {/*  autoComplete="phone"*/}
        {/*/>*/}
        <BirthDateInput
          error={form.formState.errors.birthDate?.message}
          label={
            <Trans i18nKey="checkoutPage.form.dateOfBirth">
              Date of birth{' '}
              <span className="text-sm text-gray-700 font-normal">(day/month/year)</span>
            </Trans>
          }
          {...form.register('birthDate', {
            pattern: {
              value: /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/(19|20)\d\d$/i,
              message: t('checkoutPage.form.invalidDate')
            },

            required: t('checkoutPage.form.mandatoryField')
          })}
        />
        <Controller
          rules={{ required: 'Gender is mandatory' }}
          control={form.control}
          name={'gender'}
          render={({ field }) => (
            <RadioGroups
              label={t('checkoutPage.form.gender')}
              onChange={(c) => field.onChange(c)}
              error={form.formState.errors.gender?.message}
              value={field.value}
              options={[
                { id: GenderNumeric.Male, name: t('common.genders.male') },
                {
                  id: GenderNumeric.Female,
                  name: t('common.genders.female')
                },
                { id: GenderNumeric.Other, name: t('common.genders.other') }
              ]}
            />
          )}
        />
      </div>

      <div className="pt-4">
        <div className="flex gap-2 p-2 border border-dashed border-gray-700 rounded-md items-center">
          <SvgIcon className="text-gray-800">
            <AccessibilityIcon />
          </SvgIcon>
          <div className="flex flex-col gap-1">
            <span className="text-sm">{t('checkoutPage.specialAssistanceBanner')}</span>
          </div>
        </div>
      </div>

      <Button className="mt-4" fullWidth onClick={form.handleSubmit(onSubmit)} type="submit">
        {t('checkoutPage.cta.continue')}
      </Button>
    </>
  );
};
