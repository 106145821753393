import { Badge, BadgeProps } from '@design-system/feedback/badge';

import { OrderStatusType } from 'legacy-types/order';
import { useTranslation } from 'react-i18next';

const chipColor: Record<OrderStatusType, BadgeProps['color']> = {
  pending: 'indigo',
  confirmed: 'success',
  expired: 'warning',
  cancelled: 'danger'
};

export const OrderStatusChip = ({ status }: { status: OrderStatusType }) => {
  const { t } = useTranslation();
  return (
    <Badge
      color={chipColor[status]}
      className="uppercase"
      value={t(`common.orderStatus.${status}`)}
    />
  );
};
