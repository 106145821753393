import * as React from 'react';
import cn from 'classnames';
import { CardOverflow } from '@mui/joy';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SvgIcon from '@mui/material/SvgIcon';

type ItemCardProps = {
  title: string | React.ReactNode | JSX.Element;
  subtitle?: string;
  imageUrl?: string;
  imageRatio?: number;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  disabled?: boolean;
};
export const ItemCard = (props: ItemCardProps) => {
  return (
    <div
      role="button"
      onClick={(e) => {
        e.preventDefault();
        if (!props.disabled && props.onClick) {
          props.onClick(e);
        }
      }}>
      <div
        className={cn(`p-4 flex justify-between items-center rounded-md `, {
          'bg-gray-100': !props.disabled,
          'bg-gray-900 cursor-not-allowed': props.disabled
        })}>
        <div className="flex gap-4 items-start pr-2">
          {props.imageUrl ? (
            <img
              src={props.imageUrl}
              srcSet={props.imageUrl}
              loading="lazy"
              alt=""
              className="object-cover w-12 h-12 hidden md:block"
            />
          ) : null}
          <div>
            <div
              className={cn('text-lg text-black font-bold', {
                'text-gray-500': props.disabled
              })}
              id="card-description">
              {props.title}
            </div>
            {props.subtitle ? (
              <div
                className={cn('text-md text-gray-700', {
                  'text-gray-600': props.disabled
                })}>
                {props.subtitle}
              </div>
            ) : null}
          </div>
        </div>
        {!props.disabled ? (
          <CardOverflow sx={{ alignItems: 'center', pr: 1 }}>
            <SvgIcon className="text-black">
              <ArrowForwardIosIcon />
            </SvgIcon>
          </CardOverflow>
        ) : null}
      </div>
    </div>
  );
};
