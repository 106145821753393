import { Controller, useForm } from 'react-hook-form';
import { GenderNumeric } from 'contants';
import React, { FC, useCallback } from 'react';
import { Input } from '@design-system/fields/input';
import { CountryDropdown } from 'pages/packages/checkout/components/country-dropdown';
import { OrderGuestDetails } from 'legacy-types/customer';
import { BirthDateInput } from 'pages/packages/checkout/components/birth-date-input';
import { Button } from '@design-system/fields/button';
import { RadioGroups } from '@design-system/fields/radio-groups';
import { Trans, useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

type PersonalDetailsProps = {
  onSubmit: (values: OrderGuestDetails) => void;
};

export const GuestDetails: FC<PersonalDetailsProps> = ({ onSubmit }) => {
  const { t } = useTranslation();
  const form = useForm<OrderGuestDetails>({
    defaultValues: {
      id: `temp_${uuidv4()}`,
      gender: GenderNumeric.Male
    }
  });

  const handleReset = useCallback(() => {
    form.resetField('email');
    form.resetField('firstName');
    form.resetField('lastName');
    form.resetField('nationality');
    form.resetField('birthDate');
    form.resetField('gender');
    form.clearErrors();
  }, [form]);

  const handleSubmit = useCallback(
    (values: OrderGuestDetails) => {
      onSubmit(values);
      handleReset();
    },
    [handleReset, onSubmit]
  );

  return (
    <>
      <div className="grid grid-rows-1 sm:grid-cols-2 gap-4 my-2 ">
        <Input
          label={t('checkoutPage.form.email')}
          error={form.formState.errors.email?.message}
          autoComplete="email"
          {...form.register('email', {
            required: t('checkoutPage.form.mandatoryField')
          })}
        />
        <Controller
          rules={{ required: t('checkoutPage.form.mandatoryField') }}
          control={form.control}
          name={'nationality'}
          render={({ field }) => (
            <div>
              <CountryDropdown
                label={t('checkoutPage.form.nationality')}
                error={form.formState.errors.nationality?.message}
                value={field.value}
                onChange={(c) => {
                  field.onChange(c);
                }}
              />
            </div>
          )}
        />
        <Input
          error={form.formState.errors.firstName?.message}
          label={t('checkoutPage.form.firstName')}
          autoComplete="firstName"
          {...form.register('firstName', {
            required: t('checkoutPage.form.mandatoryField')
          })}
        />
        <Input
          {...form.register('lastName', {
            required: t('checkoutPage.form.mandatoryField')
          })}
          error={form.formState.errors.lastName?.message}
          label={t('checkoutPage.form.lastName')}
          autoComplete="lastName"
        />

        <Controller
          rules={{
            pattern: {
              value: /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/(19|20)\d\d$/i,
              message: t('checkoutPage.form.invalidDate')
            },

            required: t('checkoutPage.form.mandatoryField')
          }}
          control={form.control}
          name={'birthDate'}
          render={({ field }) => (
            <div>
              <BirthDateInput
                name="birth_date"
                value={field.value || ''}
                onChange={field.onChange}
                error={form.formState.errors.birthDate?.message}
                label={
                  <Trans i18nKey="checkoutPage.form.dateOfBirth">
                    Date of birth{' '}
                    <span className="text-sm text-gray-700 font-normal">(day/month/year)</span>
                  </Trans>
                }
              />
            </div>
          )}
        />

        <Controller
          rules={{ required: 'Gender is mandatory' }}
          control={form.control}
          name={'gender'}
          render={({ field }) => (
            <RadioGroups
              label={t('checkoutPage.form.gender')}
              onChange={(c) => field.onChange(c)}
              error={form.formState.errors.gender?.message}
              value={field.value}
              options={[
                { id: GenderNumeric.Male, name: t('common.genders.male') },
                {
                  id: GenderNumeric.Female,
                  name: t('common.genders.female')
                },
                { id: GenderNumeric.Other, name: t('common.genders.other') }
              ]}
            />
          )}
        />
      </div>

      <Button
        color="secondary"
        className="mt-4"
        fullWidth
        onClick={form.handleSubmit(handleSubmit)}
        type="submit">
        {t('checkoutPage.formGuests.cta')}
      </Button>
    </>
  );
};
