import { useTranslation } from 'react-i18next';

export const OrderCancelled = () => {
  const { t } = useTranslation();
  return (
    <div className="bg-gray-50 rounded-md">
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-base font-semibold leading-6 text-gray-900">
          {t('orderStatusPage.views.cancelled.title')}
        </h3>
        <div className="mt-2 max-w-xl text-sm text-gray-600">
          <p>{t('orderStatusPage.views.cancelled.message')}</p>
        </div>
      </div>
    </div>
  );
};
