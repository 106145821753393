import { create } from 'zustand';
import { Order } from 'legacy-types/order';

type UserOrderStoreProps = {
  data: Order | undefined | null;
  isLoading: boolean;
  error: unknown | null;
};

const useOrderStore = create<UserOrderStoreProps>((set) => ({
  data: null,
  isLoading: false,
  error: null
}));

export default useOrderStore;
