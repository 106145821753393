import { useCheckoutStore } from 'pages/packages/hooks/useCheckoutStore';
import { useQuery } from 'react-query';
import { useCallback, useMemo, useState } from 'react';
import { PaymentRequest } from 'legacy-types/payment';

export const useCheckoutData = (checkoutHash?: string) => {
  const [expired, setExpired] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const getCheckouRequestInfo = useCheckoutStore((state) => state.getCheckouRequestInfo);
  const processCheckout = useCheckoutStore((state) => state.processCheckout);

  const { data, refetch, isLoading, error } = useQuery(
    ['CHECKOUT_ORDER', checkoutHash],
    () => {
      return checkoutHash ? getCheckouRequestInfo(checkoutHash) : undefined;
    },
    {
      enabled: !expired,
      onSettled: (data) => {
        if (data?.error && data?.code === 'CHECKOUT_EXPIRED') {
          setExpired(true);
        }
      }
    }
  );

  const { total, pax } = useMemo(
    () =>
      data?.checkoutItems
        ? data.checkoutItems.reduce(
            (acc, item) => {
              acc.total +=
                item.accommodation.room.price +
                (item.ticket?.price || 0) +
                (item.journey?.price || 0);
              acc.pax += item.accommodation.room.capacity;
              return acc;
            },
            { total: 0, pax: 0 }
          )
        : { total: 0, pax: 0 },
    [data?.checkoutItems]
  );

  const handleOnSubmit = useCallback(
    async (value: PaymentRequest) => {
      if (checkoutHash) {
        try {
          setSubmitting(true);
          window.location.href = await processCheckout(checkoutHash, value);
        } catch (err) {
          setSubmitting(false);
          console.error(err);
        }
      }
    },
    [checkoutHash, processCheckout]
  );

  return {
    data,
    refetch,
    total,
    pax,
    expired,
    setExpired,
    isLoading,
    error,
    isSubmitting,
    handleOnSubmit
  };
};
