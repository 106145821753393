import React from 'react';
import { PackagesPageContainer } from 'pages/packages/components/packages-page-container';
import { useNavigate, useParams } from 'react-router-dom';
import { useCheckoutData } from 'pages/packages/checkout/hooks/use-checkout-data';
import { LoadingContainer } from 'pages/commom/loading-container';
import { CheckoutStatus } from '@common/checkout/constants';
import { CheckoutView } from 'pages/packages/checkout/views/checkout';
import { CheckoutOrder } from 'legacy-types/get-order-package.types';
import { CheckoutPendingView } from 'pages/packages/checkout/views/checkout-pending';
import { CheckoutUnavilableView } from 'pages/packages/checkout/views/checkout-unavilable';
import { CheckoutExpiredView } from 'pages/packages/checkout/views/checkout-expired';
import { useTranslation } from 'react-i18next';
import { useStepsFlow } from 'pages/packages/shop/hooks/use-steps-flow';
import { ShippingProvider } from 'pages/packages/checkout/providers/ShippingProvider';

const CheckoutPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isFetchingInventory = useStepsFlow((s) => s.isFetchingInventory);
  const { checkoutHash } = useParams<{ checkoutHash?: string }>();

  if (!checkoutHash) throw new Error('Checkout hash is missing');

  const { expired, data, isLoading, refetch, setExpired } = useCheckoutData(checkoutHash);

  /**
   * user already has an order, redirect to order status page
   */
  if (data?.orderId) {
    navigate(`/order/status/${data.orderId}`);
  }

  if (isLoading || isFetchingInventory) {
    return (
      <LoadingContainer
        title={t('loadingStates.loading.title')}
        description={t('loadingStates.loading.description')}
      />
    );
  }

  /**
   * Handle the view based on the checkout status
   */
  if (data?.status === CheckoutStatus.PENDING) {
    return <CheckoutPendingView refetch={refetch} />;
  }

  if (data?.status === CheckoutStatus.UNAVAILABLE) {
    return <CheckoutUnavilableView />;
  }

  if (expired || data?.status === CheckoutStatus.EXPIRED) {
    return <CheckoutExpiredView />;
  }

  return (
    <ShippingProvider>
      <PackagesPageContainer activeHeader="checkout" className="bg-black text-white">
        <CheckoutView data={data as CheckoutOrder} setExpired={setExpired} />
      </PackagesPageContainer>
    </ShippingProvider>
  );
};

export default CheckoutPage;
