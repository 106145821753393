import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { RadioGroup } from '@headlessui/react';

export type RadioOption = { id: string; name: string };

export type RadioGroupProps = {
  label?: string | React.ReactNode;
  error?: string | React.ReactNode;
  options: RadioOption[];
  onChange?: (value: RadioOption['id']) => void;
  value?: RadioOption['id'];
};

export const RadioGroups = (props: RadioGroupProps) => {
  const [selected, setSelected] = useState<RadioOption['id'] | undefined>();

  useEffect(() => {
    if (!selected) {
      setSelected(props.value || props.options[0].id);
      props.onChange?.(props.value || props.options[0].id);
    }
  }, [props, props.value, selected]);

  return (
    <div>
      {props.label ? (
        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
          {props.label}
        </label>
      ) : null}
      <div className="space-y-4 sm:flex sm:items-center sm:space-x-6 sm:space-y-0">
        <RadioGroup
          value={selected || props.options[0].id}
          onChange={(opt) => {
            setSelected(opt);
            props.onChange?.(opt);
          }}
          className="mt-2">
          <RadioGroup.Label className="sr-only">Choose a memory option</RadioGroup.Label>
          <div className="flex gap-2 flex-col md:flex-row">
            {props.options.map((option) => (
              <RadioGroup.Option
                key={option.name}
                value={option.id}
                className={({ active, checked }) =>
                  classNames(
                    'border-2 cursor-pointer focus:outline-none',
                    checked ? 'border-gray-900' : 'border-gray-300',
                    'flex items-center justify-center rounded-md py-2 px-4 text-sm font-semibold sm:flex-1'
                  )
                }
                disabled={false}>
                <RadioGroup.Label as="span" className="px-2">
                  {option.name}
                </RadioGroup.Label>
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>
      </div>
      {props.error ? (
        <p className="mt-2 text-sm text-red-600" id="email-error">
          {props.error}
        </p>
      ) : null}
    </div>
  );
};
