import React, { FC, useMemo } from 'react';
import { ItemCard } from 'pages/packages/shop/components/item-card';
import { Enumerable } from 'legacy-types/legacy/common';
import { JOURNEY_TYPE, STEPS } from 'pages/packages/constants';
import { useTranslation } from 'react-i18next';
import { useStepsFlow } from 'pages/packages/shop/hooks/use-steps-flow';
import { Country, State } from 'country-state-city';
import { AvailabilityChip } from 'pages/commom/components/availability-chip';
import { useMediaQuery } from '@mui/material';
import { Journey } from 'legacy-types/journeys';
import { StepHeader } from 'pages/packages/shop/components/step-header';
import { track } from '@common/analytics/use-analytics';

type AccommodationProps = {
  type: Enumerable<typeof JOURNEY_TYPE>;
  onSelect: ({ journeyState, journeyId }: { journeyId: string; journeyState: string }) => void;
};

const sortJourneys = (a: Journey, b: Journey) => {
  if (a.origin.country < b.origin.country) return -1;
  if (a.origin.country > b.origin.country) return 1;

  if (a.type === 'bus') {
    if (a.origin.state < b.origin.state) return -1;
    if (a.origin.state > b.origin.state) return 1;
  } else {
    if (a.outbound.departure.name < b.outbound.departure.name) return -1;
    if (a.outbound.departure.name > b.outbound.departure.name) return 1;
  }

  return 0;
};

export const JourneyLocations: FC<AccommodationProps> = (props) => {
  const { t } = useTranslation();
  const matches = useMediaQuery('(min-width:600px)');
  const journeys = useStepsFlow((state) => state.journeys);

  const journeysAvailability = useStepsFlow((s) => s.inventoryAvailability.journeys);
  const steps = useStepsFlow((s) => s.steps);
  const countryOrigin = steps?.[STEPS.JOURNEY_ORIGIN_COUNTRY];
  const stateOrigin = steps?.[STEPS.JOURNEY_ORIGIN_STATE];

  const location = useMemo(() => {
    const journeysList: Journey[] = [];

    Object.keys(journeys)?.forEach(
      (countryStateCode) =>
        Object.values(journeys[countryStateCode])?.forEach((journeyDetails) => {
            if (props.type === 'flight' && countryOrigin === journeyDetails.origin.country) {
                journeysList.push(journeyDetails);
            }

            if (props.type === 'bus' && stateOrigin === journeyDetails.origin.state) {
                journeysList.push(journeyDetails);
            }
        })
    );

    return journeysList;
  }, [countryOrigin, journeys, props.type, stateOrigin]);

  return (
    <div className="cursor-pointer">
      <StepHeader step="journeyLocation" />
      <div className="flex flex-col gap-6 pt-6">
        {location.sort(sortJourneys).map((journeyDetails) => {
          if (journeyDetails.type !== props.type) return null;

          const country = Country.getCountryByCode(journeyDetails.origin.country);
          const availability = journeysAvailability[journeyDetails.id];
          const isDisabled = availability?.isSoldOut || availability?.isOnHold;
          const state = State.getStateByCodeAndCountry(
            journeyDetails.origin.state,
            journeyDetails.origin.country
          );

          const Title = () => {
            return (
              <div className="flex flex-col gap-2">
                <div className="w-fit">
                  <AvailabilityChip {...availability} />
                </div>
                {t('packageBuilder.steps.journeySchedule.fromLocations', {
                  locations: journeyDetails.outbound.departure.name
                })}
              </div>
            );
          };

          return (
            <div
              key={journeyDetails.id}
              onClick={
                isDisabled
                  ? undefined
                  : (e) => {
                      e.preventDefault();
                      props.onSelect({
                        journeyId: journeyDetails.id,
                        journeyState: journeyDetails.origin.state
                      });
                      void track('journey_location_selected', 'track', {
                        departure: journeyDetails.outbound.departure.name,
                        country: journeyDetails.origin.country,
                        state: journeyDetails.origin.state,
                        type: props.type
                      });
                    }
              }
              className="">
              <ItemCard
                disabled={isDisabled}
                title={<Title />}
                subtitle={`${state?.name}, ${country?.name}`}
                imageRatio={matches ? 50 : 30}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
