import * as React from 'react';
import { useEffect } from 'react';
import { Country } from 'country-state-city';
import Combobox from '@design-system/fields/combobox';

type CountryDropdownProps = {
  value?: string;
  onChange: (value: string) => void;
  error?: string;
  label?: string | React.ReactNode;
};

export const CountryDropdown = (props: CountryDropdownProps) => {
  const [country, setCountry] = React.useState<{ id: string; name: string } | undefined>();
  const options = Country.getAllCountries().map((opt) => {
    return {
      id: opt.isoCode,
      name: opt.name
    };
  });

  useEffect(() => {
    if (props?.value !== country?.id) {
      setCountry(options.find((opt) => opt.id === props?.value));
    }
  }, [country, options, props]);

  return (
    <Combobox
      error={props.error}
      label={props.label || 'Country / Region'}
      options={options}
      value={country}
      onSelect={(opt) => {
        setCountry(opt);
        props.onChange(opt.id);
      }}
    />
  );
};
