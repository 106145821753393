export const formatDate = (date: string, locale: string, time?: boolean, tz?: string) => {
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    timeZone: 'UTC'
  };

  if (time) {
    options.hour = '2-digit';
    options.minute = '2-digit';
  }

  if (tz) {
    options.timeZone = tz;
    options.timeZoneName = 'shortGeneric';
  }

  const utcDate = new Date(`${date}${time ? 'Z' : ''}`);

  return Intl.DateTimeFormat(locale, options).format(utcDate);
};
