import React from 'react';
import { ErrorPage } from 'images/empty-states/error-page';
import { EmptyStateContainer } from 'pages/commom/empty-state-container';
import { useTranslation } from 'react-i18next';

export const ErrorBoundaryFallback = () => {
  const { t } = useTranslation();
  return (
    <EmptyStateContainer
      illustration={<ErrorPage className="fill-pink-800 w-28" />}
      title={t('errorBoundary.title')}
      description={t('errorBoundary.description')}
      buttonText={t('errorBoundary.cta')}
      buttonAction={() => window.location.replace('/')}
    />
  );
};
