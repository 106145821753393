import React, { FC, useMemo } from 'react';
import { getI18n, useTranslation } from 'react-i18next';
import { ItemCard } from 'pages/packages/shop/components/item-card';
import { formatDate } from 'pages/packages/shop/helpers/format-date';
import { Enumerable } from 'legacy-types/legacy/common';
import { UseStepsFlow } from 'pages/packages/types';
import { useStepsFlow } from 'pages/packages/shop/hooks/use-steps-flow';
import { StepHeader } from 'pages/packages/shop/components/step-header';
import { track } from '@common/analytics/use-analytics';

export const AccommodationDatesType = {
  CHECKIN: 'checkinDate',
  CHECKOUT: 'checkoutDate'
} as const;

type CheckinDateProps = {
  type: Enumerable<typeof AccommodationDatesType>;
  value?: string | null;
  onSelect: (date: string) => void;
};

function extractCheckinCheckoutDates(accommodationRooms: UseStepsFlow['accommodationsRooms']) {
  let checkinDatesSet: Set<string> = new Set();
  let checkoutDatesSet: Set<string> = new Set();

  // Iterate over accommodationRooms
  for (let accommodationId in accommodationRooms) {
    const rooms = accommodationRooms[accommodationId];
    for (let roomId in rooms) {
      const prices = rooms[roomId].prices;
      prices &&
        prices.forEach((priceEntry) => {
          checkinDatesSet.add(priceEntry.checkin);
          checkoutDatesSet.add(priceEntry.checkout);
        });
    }
  }

  // Convert Sets back to arrays
  const checkinDates = Array.from(checkinDatesSet);
  const checkoutDates = Array.from(checkoutDatesSet);

  return { checkinDates, checkoutDates };
}

export const AccommodationDates: FC<CheckinDateProps> = ({ onSelect, type }) => {
  const { t } = useTranslation();
  const locale = getI18n().language;
  const accommodationsRooms = useStepsFlow((state) => state.accommodationsRooms);

  const { checkinDates, checkoutDates } = extractCheckinCheckoutDates(accommodationsRooms);

  const dates = useMemo(
    () => (type === AccommodationDatesType.CHECKIN ? checkinDates : checkoutDates),
    [checkinDates, checkoutDates, type]
  );

  return (
    <div>
      <StepHeader
        title={t(`packageBuilder.steps.${type}.title`)}
        desc={t(`packageBuilder.steps.${type}.subtitle`)}
      />
      <div className="flex flex-col gap-4 pt-6">
        {dates.map((d) => {
          return (
            <div key={d} className="cursor-pointer">
              <ItemCard
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onSelect(d);
                  void track('accommodation_date_click', 'track', { date: d, type });
                }}
                title={formatDate(d, locale)}
                subtitle={t(`packageBuilder.steps.${type}.time`, { context: d })}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
