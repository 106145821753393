export const AmexSVG = ({ className }: { className: string }) => {
  return (
    <svg
      className={className}
      width="35"
      height="24"
      viewBox="0 0 70 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="69" height="47" rx="5.5" fill="#1F72CD" stroke="#D9D9D9" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5493 17L6 31.4935H13.8405L14.8125 29.1826H17.0342L18.0062 31.4935H26.6364V29.7298L27.4054 31.4935H31.8696L32.6386 29.6925V31.4935H50.587L52.7695 29.2426L54.813 31.4935L64.0317 31.5121L57.4617 24.2872L64.0317 17H54.956L52.8315 19.2093L50.8523 17H31.3268L29.6501 20.7409L27.9341 17H20.11V18.7037L19.2396 17H12.5493ZM39.3516 19.0581H49.6584L52.8108 22.4633L56.0648 19.0581H59.2172L54.4275 24.2852L59.2172 29.452H55.9218L52.7695 26.0073L49.4989 29.452H39.3516V19.0581ZM41.8968 23.1099V21.2114V21.2096H48.328L51.1342 24.2458L48.2036 27.2986H41.8968V25.226H47.5197V23.1099H41.8968ZM14.0664 19.0581H17.8883L22.2324 28.8862V19.0581H26.4191L29.7745 26.1048L32.8668 19.0581H37.0326V29.4581H34.4978L34.4771 21.3087L30.7817 29.4581H28.5142L24.7981 21.3087V29.4581H19.5836L18.595 27.1266H13.254L12.2675 29.4561H9.47358L14.0664 19.0581ZM14.166 24.9712L15.9256 20.8177L17.6832 24.9712H14.166Z"
        fill="white"
      />
    </svg>
  );
};
