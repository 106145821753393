import { AnalyticsBrowser } from '@segment/analytics-next';
import { ConstumerIdentifier } from 'legacy-types/user-identifier';
import { BUILD_ENV } from 'contants';

const isLocalDev = !process.env?.REACT_APP_BUILD_ENV;

const apiKey = {
  dev: 'pt8mAqzAqvueQ54IoYs56MI7sMFNlMq6',
  production: 'EWN0NIbPQQ4URn13WPCHhbSAJwzWx0Db',
  simulator: 'EWN0NIbPQQ4URn13WPCHhbSAJwzWx0Db'
};

export const analytics = AnalyticsBrowser.load({
  writeKey: apiKey[BUILD_ENV] || apiKey.dev
});

export const indentify = (consumer?: ConstumerIdentifier) => {
  if (consumer) {
    return analytics.identify(consumer?.sub, {
      firstName: consumer.data.fName,
      lastName: consumer.data.lName,
      email: consumer.data.email
    });
  }
};

export const track = (event: string, type: 'track' | 'page' = 'track', properties?: any) => {
  if (isLocalDev) return;

  void analytics.track(event, {
    type,
    page: {
      path: window.location.pathname,
      url: window.location.href,
      referrer: document.referrer,
      title: document.title,
      search: window.location.search
    },
    values: {
      env: BUILD_ENV,
      ...properties
    }
  });
};
