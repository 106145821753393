import React, { FC, useMemo, useState } from 'react';
import cn from 'classnames';
import AccomodationCard from 'pages/packages/shop/components/accomodation-card';
import {
  findLowestPriceRoom,
  hasCheckinDatesAvailable
} from 'pages/packages/shop/helpers/finder-lowest-room-price';
import { useStepsFlow } from 'pages/packages/shop/hooks/use-steps-flow';
import AccommodationFilters from 'pages/packages/shop/components/accommodation-filters';
import { STEPS } from 'pages/packages/constants';
import { useTranslation } from 'react-i18next';
import {
  Accommodation as AccommodationDetails,
  AccommodationType
} from 'legacy-types/accommodation';
import { ItemCard } from 'pages/packages/shop/components/item-card';
import { Transition } from '@headlessui/react';
import { NoDatesFound } from 'images/empty-states/no-dates-found';
import { StepHeader } from 'pages/packages/shop/components/step-header';
import { track } from '@common/analytics/use-analytics';
import { useNavigate } from 'react-router-dom';
import { TicketType } from 'legacy-types/ticket';

type AccommodationProps = {
  onSelect: (accommodationId: string) => void;
};

type HotelsToRenderType = {
  hotel: AccommodationDetails;
  lowestPrice: number;
  isSoldOut: boolean;
  isOnHold: boolean;
};

const categoryOrder = {
  bronze: 4,
  silver: 3,
  gold: 2,
  thematic: 1,
  easy_tents: 2,
  magnificent_greens: 1
};

const sortingFunction = (a: HotelsToRenderType, b: HotelsToRenderType) => {
  if (a.isSoldOut && !b.isSoldOut) return 1;
  if (!a.isSoldOut && b.isSoldOut) return -1;

  // Map categories to their order values
  const orderA = categoryOrder[a?.hotel.category.toLowerCase()] || Number.MAX_VALUE;
  const orderB = categoryOrder[b?.hotel.category.toLowerCase()] || Number.MAX_VALUE;

  // First, compare by category order
  if (orderA !== orderB) return orderA - orderB;

  // If categories are the same, compare by lowestPrice
  return (a?.lowestPrice ?? 0) - (b?.lowestPrice ?? 0);
};

export const Accommodation: FC<AccommodationProps> = (props) => {
  const [selectedType, setSelectedType] = useState<AccommodationType>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    tickets,
    selectedJourneyPrice,
    steps,
    accommodations,
    inventoryAvailability,
    accommodationsRooms,
    flow
  } = useStepsFlow((s) => ({
    steps: s.steps,
    tickets: s.tickets,
    accommodations: s.accommodations,
    inventoryAvailability: s.inventoryAvailability,
    accommodationsRooms: s.accommodationsRooms,
    selectedJourneyPrice: s.selectedJourneyPrice,
    flow: s.flow
  }));

  const cheapestTicket = useMemo(() => {
    return Object.values(tickets).reduce(
      (cheapest, ticket) => {
        if (ticket.price === 0) return cheapest;
        return ticket.price < cheapest.price ? ticket : cheapest;
      },
      { price: Infinity } as TicketType
    );
  }, [tickets]);

  const journyPrice = selectedJourneyPrice();
  const checkinDate = steps?.[STEPS.CHECKIN_DATE];
  const journeyOrigin = steps?.[STEPS.JOURNEY_ORIGIN_STATE];
  const checkoutDate = steps?.[STEPS.CHECKOUT_DATE];

  const [categoryFilter, setCategoryFilter] = useState<string[] | undefined>(['all']);

  const hotelsToRender = useMemo(() => {
    if (!steps || !checkinDate || !checkoutDate || !accommodations) {
      return [];
    }

    // Filter out nulls directly using Boolean as a function
    return Object.entries(accommodations)
      .map(([_, hotel]) => {
        const rooms = accommodationsRooms?.[hotel?.id];

        const isValidCategory =
          categoryFilter?.includes('all') ||
          (categoryFilter ? categoryFilter.includes(hotel.category) : true);
        const isInFlowCategories = flow?.accomodationCategories?.includes(hotel.category);
        const isTypeSelected = !selectedType || hotel?.type === selectedType;
        const isAvailable = rooms && hasCheckinDatesAvailable(rooms, checkinDate, checkoutDate);

        if (journeyOrigin && flow?.accomodationCategoriesFilter?.location?.[journeyOrigin]) {
          const categories = flow?.accomodationCategoriesFilter?.location[journeyOrigin];
          if (!categories?.includes(hotel.category)) return null;
        }

        // Consolidated condition to determine if the hotel should be included
        if (
          !hotel ||
          !rooms ||
          !isValidCategory ||
          !isInFlowCategories ||
          !isTypeSelected ||
          !isAvailable
        ) {
          return null;
        }

        const lowestPrice =
          cheapestTicket.price +
          journyPrice +
          findLowestPriceRoom(rooms, checkinDate, checkoutDate);

        const isSoldOut = Object.values(rooms).every((room) => {
          return inventoryAvailability.accommodationRooms[room.id].isSoldOut;
        });

        const isOnHold = Object.values(rooms).every((room) => {
          return inventoryAvailability.accommodationRooms[room.id].isOnHold;
        });

        return { hotel, isSoldOut, isOnHold, lowestPrice };
      })
      .filter(Boolean) as HotelsToRenderType[];
  }, [
    steps,
    checkinDate,
    checkoutDate,
    accommodations,
    accommodationsRooms,
    categoryFilter,
    flow?.accomodationCategories,
    flow?.accomodationCategoriesFilter?.location,
    selectedType,
    journeyOrigin,
    journyPrice,
    inventoryAvailability.accommodationRooms
  ]);

  if (!checkinDate || !checkoutDate) {
    navigate('/');
  }

  const types = useMemo(
    () =>
      hotelsToRender.reduce((acc, hotel) => {
        acc.add(hotel.hotel.type);
        return acc;
      }, new Set<AccommodationType>()),
    [hotelsToRender]
  );

  const AccommodsationContent = () => (
    <div>
      <AccommodationFilters
        selectedType={selectedType}
        options={flow?.accomodationCategories || []}
        selected={categoryFilter || []}
        onChange={setCategoryFilter}
      />
      {hotelsToRender.length >= 1 ? (
        <div className={cn('grid grid-cols-fluid justify-start gap-8 pt-6')}>
          {hotelsToRender.sort(sortingFunction).map((item) => {
            return item ? (
              <AccomodationCard
                isSoldOut={item.isSoldOut}
                isOnHold={item.isOnHold}
                key={item.hotel.id}
                onClick={() => {
                  props.onSelect(item.hotel.id);
                  void track('accommodation_card_click', 'track', {
                    name: item.hotel.name,
                    category: item.hotel.category
                  });
                }}
                accommodation={item.hotel}
                priceFrom={item.lowestPrice}
              />
            ) : null;
          })}
        </div>
      ) : (
        <div className="pt-8 flex justify-center flex-col items-center align-middle">
          <NoDatesFound className="fill-pink-800 w-28" />
          <div className="text-lg text-white">No results found</div>
          <div className="mt-2 text-sm text-gray-500 pb-4">
            Try to look for another date or accommodation type
          </div>
        </div>
      )}
    </div>
  );

  const AccommodationTypeSelect = () => (
    <div className="grid grid-rows-2 gap-4">
      {Array.from(types).map((value, index) => (
        <ItemCard
          key={`${value}_${index}`}
          onClick={() => {
            setSelectedType(value);
            void track('accommodation_type_select', 'track', {
              type: value
            });
          }}
          title={t(`packageBuilder.steps.accomodation.options.${value}`)}
        />
      ))}
    </div>
  );

  return (
    <div>
      <StepHeader step="accomodation" />
      <div className="pt-2">
        {flow?.accommodationTypeFilter ? (
          <div className={`${selectedType ? 'hidden' : 'visible'}`}>
            <AccommodationTypeSelect />
          </div>
        ) : null}
        <Transition
          show={!flow?.accommodationTypeFilter || Boolean(selectedType)}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95">
          <div>
            <AccommodsationContent />
          </div>
        </Transition>
      </div>
    </div>
  );
};
