import { OrderPaidView } from 'pages/order/views/order-paid';
import { Order } from 'legacy-types/order';
import { OrderExpired } from 'pages/order/views/order-expired';
import { CheckoutStatus } from '@common/checkout/constants';
import { OrderPendingPayment } from 'pages/order/views/order-pending-payment';
import { OrderCancelled } from 'pages/order/views/order_cancelled';
import { OrderPending } from 'pages/order/views/order-pending';

export const OrderView = (props: { order: Order }) => {
  switch (props.order.orderStatus) {
    case 'cancelled':
      return <OrderCancelled />;
    case 'pending':
      if (
        props.order.checkoutStatus === CheckoutStatus.PENDING_PAYMENT &&
        props.order.paymentLink
      ) {
        return <OrderPendingPayment paymentUrl={props.order.paymentLink?.url} />;
      }
      return <OrderPending />;
    case 'expired':
      return <OrderExpired order={props.order} />;
    case 'confirmed':
      return <OrderPaidView order={props.order} />;
    default:
      return null;
  }
};


// https://checkout.insidemusicland.com/c/pay/cs_test_a1AvE31LRtGpYGQts7DHsPFMUswmyYBvseuc59LlyFbz3NEScaoucFHWff#fidkdWxOYHwnPyd1blpxYHZxWjA0SGNTMlJANUh%2FQkxwXzZ8PVNsSmhjNjNvV3NNVHBET31SSERAQVF0XVNmYHZMSHJJUn9naVxtUU9id0MzPXxrczYzb3NWVU1hTGE8X2dKUmFJcURvVj0yNTVuUF9ybE5INycpJ2N3amhWYHdzYHcnP3F3cGApJ2lkfGpwcVF8dWAnPyd2bGtiaWBabHFgaCcpJ2BrZGdpYFVpZGZgbWppYWB3dic%2FcXdwYHgl
// https://checkout.insidemusicland.com/c/pay/cs_test_a1AvE31LRtGpYGQts7DHsPFMUswmyYBvseuc59LlyFbz3NEScaoucFHWff#fidkdWxOYHwnPyd1blpxYHZxWjA0SGNTMlJANUh%2FQkxwXzZ8PVNsSmhjNjNvV3NNVHBET31SSERAQVF0XVNmYHZMSHJJUn9naVxtUU9id0MzPXxrczYzb3NWVU1hTGE8X2dKUmFJcURvVj0