import { Controller, useForm } from 'react-hook-form';
import { PaymentMethodsSelector } from 'pages/packages/checkout/components/payment-methods-selector';
import SvgIcon from '@mui/material/SvgIcon';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import React, { FC, useEffect } from 'react';
import DocumentTypeSelect from 'pages/packages/checkout/components/form/document-type-select';
import { PaymenyDetailsForm } from 'pages/packages/checkout/types';
import { TotalPanel } from 'pages/packages/checkout/total-panel';
import { useCheckoutForm } from 'pages/packages/checkout/hooks/use-checkout-form';
import { useParams } from 'react-router-dom';
import { useCheckoutData } from 'pages/packages/checkout/hooks/use-checkout-data';
import { calculateTotalAmount, getStatements } from '@common/calculate-total-amount';
import priceToBRL from 'utils/priceToBRL';
import { DocumentTypeInput } from 'pages/packages/checkout/components/form/document-value-input';
import { SelectMenu, SelectOption } from '@design-system/fields/select-menu';
import { Button } from '@design-system/fields/button';
import { Trans, useTranslation } from 'react-i18next';
import { useFeatureFlag } from 'configcat-react';
import { useShippingOptions } from 'pages/packages/checkout/hooks/use-shipping-store';

type PaymentMethodDetailsProps = {
  onSubmit: (value: PaymenyDetailsForm) => void;
  defaultValues?: Partial<PaymenyDetailsForm>;
};

export const PaymentMethodDetails: FC<PaymentMethodDetailsProps> = ({
  onSubmit,
  defaultValues
}) => {
  const { value: paymentMethodCreditCardValue } = useFeatureFlag('paymentMethodCreditCard', false);
  const { value: paymentMethodPixValue } = useFeatureFlag('paymentMethodPix', false);
  const { value: paymentMethodCreditInstalmentsValue } = useFeatureFlag(
    'paymentMethodCreditInstalments',
    false
  );
  const { t } = useTranslation();
  const { getShippingByLocation } = useShippingOptions();
  const { checkoutHash } = useParams<{ checkoutHash?: string }>();
  const { data, pax } = useCheckoutData(checkoutHash);
  const nationality = useCheckoutForm((s) => s.formsDetails.personalDetails?.nationality);
  const country = useCheckoutForm((s) => s.formsDetails.addressDetails?.addressCountry);
  const shipping = useCheckoutForm((s) => s.formsDetails.shipping);

  const form = useForm<PaymenyDetailsForm>({
    defaultValues: defaultValues || {
      documentType: 'passport',
      paymentMethod: 'stripe',
      installments: 1
    }
  });

  const paymentMethod = form.watch('paymentMethod');
  const documentType = form.watch('documentType');
  const installments = form.watch('installments') || 1;

  const isBrazil = nationality === 'BR';

  useEffect(() => {
    if (isBrazil) {
      form.setValue('documentType', 'cpf');
    }
  }, [isBrazil, form]);

  const shippingPrice =
    shipping.shippingMethod === 'ship'
      ? (shipping.address &&
          getShippingByLocation(shipping.address.country, shipping.address.state)?.price) ||
        NaN
      : 0;

  const calc = calculateTotalAmount(
    Number(data?.totalCheckoutPrice || NaN) + shippingPrice,
    paymentMethod,
    installments || 1
  );

  const totalPurchaseAmout = calc.total;

  const installmentsOptions: SelectOption[] = getStatements(
    paymentMethod,
    Number(data?.totalCheckoutPrice || NaN) + shippingPrice,
    12
  ).map((insta) => ({
    value: String(insta.installment),
    label: t('checkoutPage.payment.installmentsOf', {
      count: insta.installment,
      value: priceToBRL.format(insta.total)
    })
  }));

  return (
    <>
      <div className="pb-4">
        <div className="grid grid-rows-1 sm:grid-cols-2 gap-4 my-2">
          <Controller
            control={form.control}
            name={'documentType'}
            render={({ field }) => (
              <DocumentTypeSelect
                error={form.formState.errors.documentType?.message}
                disabled={isBrazil}
                value={field.value}
                onChange={(vaue) => field.onChange(vaue)}
              />
            )}
          />
          <DocumentTypeInput
            {...form.register('documentValue', {
              required: documentType === 'cpf' ? 'CPF is mandatory' : 'Document is mandatory',
              pattern:
                documentType === 'cpf'
                  ? {
                      value: /^\d{3}\.\d{3}\.\d{3}-\d{2}$/i,
                      message: 'CPF is invalid'
                    }
                  : undefined
            })}
            name="documentValue"
            type={documentType}
            error={form.formState.errors.documentValue?.message}
            label={t('checkoutPage.form.documentNumber')}
          />
        </div>
        <div className="pt-6">
          <Controller
            control={form.control}
            name={'paymentMethod'}
            render={({ field }) => (
              <PaymentMethodsSelector
                config={{
                  paymentMethodCreditCardValue,
                  paymentMethodPixValue,
                  paymentMethodCreditInstalmentsValue
                }}
                nationality={nationality || 'BR'}
                countryIso={country || 'BR'}
                value={field.value}
                onChange={(value) => {
                  form.setValue('installments', 1);
                  field.onChange(value);
                }}
              />
            )}
          />
        </div>
        {paymentMethod === 'pagar.me/card' ? (
          <div className="py-2">
            <Controller
              control={form.control}
              name={'installments'}
              render={({ field }) => (
                <SelectMenu
                  label={t('checkoutPage.form.installments')}
                  options={installmentsOptions}
                  error={form.formState.errors.installments?.message}
                  value={installmentsOptions.find((opt) => opt.value === String(field.value))}
                  onSelect={(value) => {
                    value && field.onChange(value.value);
                  }}
                />
              )}
            />
          </div>
        ) : null}

        <TotalPanel
          shippingPrice={shipping.shippingMethod === 'ship' ? shippingPrice : undefined}
          total={Number(data?.totalCheckoutPrice)}
          totalWithFee={totalPurchaseAmout}
          fee={calc?.fee}
          pax={pax}
        />
        <div className="text-xs text-gray-700 flex items-center gap-1 pt-2">
          <SvgIcon fontSize="small">
            <InfoOutlinedIcon />
          </SvgIcon>
          <Trans i18nKey="checkoutPage.payment.moreInfoFees">
            For more info about payment methods and fees,{' '}
            <a
              href="https://help.insidemusicland.com/articles/8932036-what-are-the-different-payment-methods-during-the-global-journey-sale"
              target="_blank"
              className="text-pink-600"
              rel="noreferrer">
              click here.
            </a>
          </Trans>
        </div>
      </div>
      <Button
        className="mt-4"
        fullWidth
        onClick={form.handleSubmit(onSubmit)}
        type="submit"
        disabled={
          !paymentMethodCreditCardValue &&
          !paymentMethodPixValue &&
          !paymentMethodCreditInstalmentsValue
        }>
        {t('checkoutPage.cta.continue')}
      </Button>
    </>
  );
};
