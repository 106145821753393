import React, { useMemo } from 'react';
import { Step } from '../components/step';
import { useWizard } from 'react-use-wizard';
import { AddressDetailts } from 'pages/packages/checkout/steps-wizard/components/forms/address-detailts';
import { useCheckoutForm } from 'pages/packages/checkout/hooks/use-checkout-form';
import { useConsumerIdentifier } from 'hooks/use-consumer-identifier';
import { useTranslation } from 'react-i18next';

export const AddressDetailsStep = ({ onComplete }: { onComplete: () => void }) => {
  const { t } = useTranslation();
  const { nextStep } = useWizard();
  const consumer = useConsumerIdentifier((s) => s.consumer);

  const { formsDetails, updateFormDetails } = useCheckoutForm((s) => ({
    updateFormDetails: s.updateFormDetails,
    formsDetails: s.formsDetails
  }));

  const defaultValues = useMemo(() => {
    if (formsDetails.addressDetails) return formsDetails.addressDetails;

    if (consumer) {
      return {
        addressCountry: consumer?.data?.countryCode,
        addressState: consumer?.data?.countyOrProvince,
        addressCity: consumer?.data?.city,
        addressPostalCode: consumer?.data?.zipOrPostcode,
        address1: consumer?.data
          ? `${consumer?.data?.street} ${consumer?.data?.buildingNumber}`
          : ''
      };
    }

    return {
      nationality: formsDetails.personalDetails?.nationality
    };
  }, [consumer, formsDetails.addressDetails, formsDetails.personalDetails?.nationality]);

  return (
    <Step title={t('checkoutPage.steps.address')}>
      <AddressDetailts
        type="billing"
        defaultValues={defaultValues}
        onSubmit={async (data) => {
          updateFormDetails({ addressDetails: data });
          onComplete();
          await nextStep();
        }}
      />
    </Step>
  );
};
