import { AccommodationType, PackageSummary, UseStepsFlow } from 'pages/packages/types';
import { TFunction } from 'i18next';
import { ICONS } from 'pages/packages/constants';
import { AccommodationRoomType } from 'legacy-types/accommodation';
import { OrderPackage } from 'legacy-types/order';

export const buildAccommodationData = (
  get: () => UseStepsFlow,
  orderPackage: OrderPackage,
  t: TFunction,
  accommodation: AccommodationType,
  room: AccommodationRoomType,
  isFreeTicket: boolean
) => {
  const items: PackageSummary[] = [];
  const assets: PackageSummary[] = [];

  if (accommodation && room) {
    const description = `${orderPackage.accommodation.room.checkin} 15:00 BRT - ${orderPackage.accommodation.room.checkout} 12:00 BRT`;

    items.push({
      name: `${accommodation.name} - ${room.name}`,
      desc: description,
      icon: ICONS.BED
    });

    accommodation.assets?.forEach((assetKey) => {
      const asset = get().assets[assetKey];

      const ignoreAsset =
        isFreeTicket && ['055bc7b9-819c-44a2-b1b5-d03a52de62de'].includes(asset.id);

      if (!ignoreAsset && asset) {
        assets.push({
          name: t(`assets:${assetKey}.name`),
          desc: t(`assets:${assetKey}.description`)
        });
      }
    });
  }

  return { items, assets };
};
