import { PersonalDetails } from 'pages/packages/checkout/steps-wizard/components/forms/personal-details';
import React, { useMemo } from 'react';
import { useWizard } from 'react-use-wizard';
import { Step } from '../components/step';
import { useCheckoutForm } from 'pages/packages/checkout/hooks/use-checkout-form';
import { useConsumerIdentifier } from 'hooks/use-consumer-identifier';
import { GenderNumeric, GenderString } from 'contants';
import { useTranslation } from 'react-i18next';

export const PersonalDetailStep = ({ onComplete }: { onComplete: () => void }) => {
  const { t } = useTranslation();
  const { nextStep } = useWizard();
  const consumer = useConsumerIdentifier((s) => s.consumer);
  const { formsDetails, updateFormDetails } = useCheckoutForm((s) => ({
    updateFormDetails: s.updateFormDetails,
    formsDetails: s.formsDetails
  }));

  const defaultValues = useMemo(() => {
    if (formsDetails.personalDetails) return formsDetails.personalDetails;

    if (consumer && consumer?.aud === 'INTERNAL') {
      return {
        email: consumer.data.email,
        firstName: consumer.data.fName,
        lastName: consumer.data.lName
      };
    }

    if (consumer) {
      return {
        email: consumer.data.email,
        nationality: consumer.data.countryCode,
        firstName: consumer.data.fName,
        lastName: consumer.data.lName,
        phoneNumber: consumer.data.phone,
        birthDate: consumer.data.birthDate.split('/').reverse().join('/'),
        gender: GenderString?.[consumer.data.gender] || GenderNumeric.Unknown,
        pwd: false
      };
    }
  }, [consumer, formsDetails.personalDetails]);

  return (
    <Step title={t('checkoutPage.steps.personalDetails')}>
      <PersonalDetails
        defaultValues={defaultValues}
        onSubmit={async (data) => {
          updateFormDetails({ personalDetails: data });
          onComplete();
          await nextStep();
        }}
      />
    </Step>
  );
};
