import React, { FC } from 'react';
import { Step } from '../components/step';
import { useCheckoutForm } from 'pages/packages/checkout/hooks/use-checkout-form';
import { TermsAndConditions } from 'pages/packages/checkout/steps-wizard/components/terms-and-conditions';
import { Button } from '@design-system/fields/button';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { useTranslation } from 'react-i18next';
import { OrderCustomerDetails } from 'legacy-types/customer';

type TermsAndCondotionsStepProps = {
  checkoutId?: string;
};

export const TermsAndCondotionsStep: FC<TermsAndCondotionsStepProps> = ({ checkoutId }) => {
  const { t, i18n } = useTranslation();
  const { processCheckout, isLoading } = useCheckoutForm((s) => ({
    processCheckout: s.processCheckout,
    isLoading: s.isLoading
  }));

  return (
    <Step title={t('checkoutPage.steps.terms')}>
      <div className="md:pr-12 py-4">
        <TermsAndConditions />
      </div>

      <Button
        className="mt-4"
        fullWidth
        loading={isLoading}
        disabled={!checkoutId || isLoading}
        icon={<ChevronRightIcon className="-mr-0.5 h-5 w-5" />}
        onClick={async () => {
          checkoutId &&
            (await processCheckout(
              checkoutId,
              (i18n.language || 'en') as OrderCustomerDetails['locale']
            ));
        }}
        type="submit">
        {t('checkoutPage.cta.goToPayment')}
      </Button>
    </Step>
  );
};
