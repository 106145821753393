import React, { FC, ReactElement } from 'react';

const StepWizardContainer: FC<{ children: ReactElement }> = ({ children }) => {
  return (
    <div>
      <h1 className="sr-only">Checkout</h1>

      <div className="scroll-mt-10 my-4 py-4">{children}</div>
    </div>
  );
};

export default StepWizardContainer;
