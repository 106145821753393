import React from 'react';
import { EmptyStateContainer } from 'pages/commom/empty-state-container';

export const LoadingContainer = (props: {
  title: string;
  description: string;
  className?: string;
}) => {
  return <EmptyStateContainer isLoading title={props.title} description={props.description} />;
};
