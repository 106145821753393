import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MapContainer, Marker, Polyline, TileLayer } from 'react-leaflet';
import L, { LatLngExpression } from 'leaflet';
import tomorrowlandLogo from 'images/tomorrowland-icons/TML_ICON_ORIGINAL_PANTONE_7425C.svg';
import locationLogo from 'images/maps/location-dot.svg';
import 'leaflet/dist/leaflet.css';

const containerStyle = {
  height: '400px',
  zIndex: 1
};

const festivalPosition: LatLngExpression = [-23.3401494, -47.3458979];

const MapWithOverlay = ({ hotel }) => {
  const { t } = useTranslation();
  const [showOverlay, setShowOverlay] = useState(true);

  const handleOverlayClick = () => setShowOverlay(false);

  const accommodationPosition: LatLngExpression = useMemo(
    () => [hotel.location.coordinates.lat, hotel.location.coordinates.lng],
    [hotel]
  );

  // Custom icon
  const customIcon = new L.Icon({
    iconUrl: tomorrowlandLogo,
    iconSize: [50, 50]
  });

  const locationIcon = new L.Icon({
    iconUrl: locationLogo,
    iconSize: [40, 40]
  });

  return (
    <div className="relative overflow-hidden w-full h-[300px] sm:h-[400px] rounded-lg">
      {showOverlay && (
        <div
          className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-70 flex items-center justify-center z-10"
          onClick={handleOverlayClick}>
          <p className="text-xl font-bold text-white tracking-wide">
            {t('packageBuilder.clickToSeeMap')}
          </p>
        </div>
      )}
      <MapContainer
        attributionControl={false}
        style={containerStyle}
        center={accommodationPosition}
        zoom={12}
        scrollWheelZoom={true}>
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <Marker position={accommodationPosition} icon={locationIcon} />
        <Marker position={festivalPosition} icon={customIcon} />
        <Polyline
          positions={[accommodationPosition, festivalPosition]}
          pathOptions={{ color: '#000000' }}
        />
      </MapContainer>
    </div>
  );
};

export default MapWithOverlay;
