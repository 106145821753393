import React from 'react';
import { PackagesPageContainer } from 'pages/packages/components/packages-page-container';
import { useParams } from 'react-router-dom';
import useOrderStore from 'pages/order/hooks/use-order-data';
import { LoadingContainer } from 'pages/commom/loading-container';
import { OrderStatusChip } from 'pages/order/compoments/order-status-chip';
import { OrderView } from 'pages/order/views';
import { NeedHelpCard } from 'pages/order/compoments/need-help-card';
import { useTranslation } from 'react-i18next';
import { useOrderSummary } from 'pages/commom/components/order-summary/use-order-summary';
import { useQuery } from 'react-query';
import { Order, OrderStatusType } from 'legacy-types/order';
import { API_URLS, apiFetch } from 'utils/apiFetch';

const autoRefreshStatus = new Set<OrderStatusType | undefined>(['pending']);

export const OrderStatus = () => {
  const [refreshEnabled, setRefreshEnabled] = React.useState(false);
  const { t, i18n } = useTranslation();
  const { orderId } = useParams<{ orderId?: string }>();

  const { isLoading, data } = useQuery<Order>(
    ['OrderStatus', orderId],
    () => {
      return apiFetch<Order>(`${API_URLS.ORDER_STATUS}/${orderId}`).get();
    },
    {
      onSuccess: (data: Order) => {
        useOrderStore.setState({ data, isLoading: false });
        setRefreshEnabled(autoRefreshStatus.has(data.orderStatus));
      },
      enabled: Boolean(orderId),
      refetchOnWindowFocus: true,
      refetchInterval: refreshEnabled ? 10000 : false // 10 seconds
    }
  );

  const orderDateFormat = new Intl.DateTimeFormat(i18n.language, {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  });

  const { ShowSummaryCTA, SummaryCards } = useOrderSummary(data?.itemsPackage || [], false, true);

  if (isLoading) {
    return (
      <LoadingContainer
        title={t('loadingStates.loading.title')}
        description={t('loadingStates.loading.description')}
      />
    );
  }

  // TODO: Empty state for no data
  if (!data?.orderStatus) return null;

  return (
    <PackagesPageContainer
      activeHeader="payment"
      orderStatus={data.orderStatus}
      sidebarImage="https://cdn.uc.assets.prezly.com/e4d1ffb9-d14a-4986-a516-053e3c26572d/-/preview/2000x1500/-/format/auto/">
      <div className="flex justify-center">
        <div className="max-w-3xl w-full px-5 mt-20">
          <div className="py-3">
            <OrderStatusChip status={data.orderStatus} />
          </div>
          <p className="mt-2 text-4xl font-bold tracking-tight text-gray-200 sm:text-5xl">
            {data.checkoutStatus === 'pending'
              ? t([`checkoutStatus.${data.checkoutStatus}.title`])
              : t([`orderStatusPage.${data.orderStatus}.title`])}
          </p>
          <p className="mt-4 mb-10 text-base text-gray-500">
            {data.checkoutStatus === 'pending'
              ? t([`checkoutStatus.${data.checkoutStatus}.description`])
              : t([`orderStatusPage.${data.orderStatus}.description`])}
          </p>
          <div className="my-4 text-sm sm:flex sm:justify-between">
            <dl className="flex flex-col md:flex-row gap-2 align-middle">
              <dd className="font-bold text-pink-600 uppercase">
                {t('orderStatusPage.orderNumber')}
              </dd>
              <dd className="font-medium text-gray-100">{data.confirmationCode}</dd>
              <div className="hidden md:block">-</div>
              <dd className="font-medium text-gray-100">
                <time dateTime={data.createdAt}>
                  {orderDateFormat.format(new Date(data.createdAt || ''))}
                </time>
              </dd>
            </dl>
            <div className="mt-4 sm:mt-0">
              <div className="font-medium text-pink-700 hover:text-indigo-500">
                {ShowSummaryCTA}
              </div>
            </div>
          </div>
          <div className="pb-6">{SummaryCards}</div>
          <OrderView order={data} />
          <div className="py-4">
            <NeedHelpCard />
          </div>
        </div>
      </div>
    </PackagesPageContainer>
  );
};
