import { createContext, useContext } from 'react';
import { ShippingOption } from 'legacy-types/shipping';
import { createStore, useStore } from 'zustand';

export type UseShippingStore = {
  options: ShippingOption[];
  isLoading: boolean;
  setOptions: (options: ShippingOption[]) => void;
  setIsLoading: (isLoading: boolean) => void;
  getShippingByLocation: (countryIso: string, regionIso?: string) => ShippingOption | null;
};

export const useShippingStore = createStore<UseShippingStore>((set, get) => ({
  options: [],
  isLoading: false,
  setOptions: (options) => set({ options }),
  setIsLoading: (isLoading) => set({ isLoading }),
  getShippingByLocation: (countryIso, regionIso) => {
    const { options } = get();
    
    return (
      options.find(
        (option) =>
          option.country === countryIso && (option.state === regionIso || option.state === null)
      ) || null
    );
  }
}));

export const ShippingOptionsContext = createContext(useShippingStore);

export const useShippingOptions = () => {
  const store = useContext(ShippingOptionsContext);
  return useStore(store);
};
