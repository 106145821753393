import React from 'react';
import { Order } from 'legacy-types/order';
import priceToBRL from 'utils/priceToBRL';
import { useTranslation } from 'react-i18next';

export function OrderPaidView(props: { order: Order }) {
  const { t } = useTranslation();
  const customer = props.order.customer;
  const payment = props.order.payment;
  const shipping = props.order.shipping;

  if (!customer || !payment) return null;

  const isAllFreeTickets = props.order.items.every(
    (item) => item.ticket.priceType === 'not_included'
  );

  return (
    <>
      <div className="p-6 bg-white rounded-md grid gap-4 md:grid-cols-2 md:justify-between">
        <div>
          <div className="font-medium text-gray-900 border-b-2 border-pink-700 max-w-fit">
            {t('orderStatusPage.mainBuyer')}
          </div>
          <div className="flex-auto text-gray-900 text-sm mt-2">
            <p>
              {customer.firstName} {customer.lastName}
            </p>
            <p>{customer.email}</p>
            <p>{customer.phoneNumber}</p>
          </div>
        </div>
        <div className="flex flex-col md:justify-end md:text-right md:place-self-end">
          <dt className="font-medium text-gray-900 border-b-2 border-pink-700 max-w-fit ">
            {t('orderStatusPage.addressInfo')}
          </dt>
          <div>
            <address className="not-italic text-gray-900 text-sm mt-2">
              <span className="block">{customer.address1}</span>
              {customer.address2 ? <span className="block">{customer.address2}</span> : null}
              <span className="block">
                {customer.addressState}, {customer.addressCountry}
              </span>
              <span className="block">{customer.addressPostalCode}</span>
            </address>
          </div>
        </div>
      </div>

      <div className="mt-6 p-6 bg-white rounded-md gap-4 grid md:grid-cols-2 md:justify-between">
        <div>
          <div className="font-medium text-gray-900 border-b-2 border-pink-700 max-w-fit">
            {t('orderStatusPage.paymentInfo')}
          </div>
          <div className="mt-4 flex-shrink-0 text-gray-900 text-sm">
            <p className="uppercase font-bold">{payment.cardBrand}</p>
            {payment.cardLast4 ? <p>Ending with {payment.cardLast4}</p> : null}
            <p className="font-bold text-red-800">{priceToBRL.format(payment.paymentAmount)}</p>
          </div>
        </div>
        {!isAllFreeTickets && shipping ? (
          <div className="flex flex-col md:justify-end md:text-right md:place-self-end">
            <dt className="md:self-end font-medium text-gray-900 border-b-2 border-pink-700 max-w-fit ">
              {t('orderStatusPage.braceletsShipping')}
            </dt>
            <div className="text-sm text-gray-900">
              <div className="mt-4 flex-shrink-0">
                <p className="text-black font-bold uppercase">
                  {t(`common.shippingMethods.${shipping?.shippingMethod}`)}
                </p>
                {shipping.shippingMethod === 'ship' && shipping?.address ? (
                  <address className="not-italic">
                    <span className="block">{shipping.address.address1}</span>
                    {shipping.address.address2 ? (
                      <span className="block">{shipping.address.address2}</span>
                    ) : null}
                    <span className="block">
                      {shipping.address.city}, {shipping.address.country}
                    </span>
                    <span className="block">{shipping.address.postalCode}</span>
                  </address>
                ) : null}
                {shipping.shippingMethod === 'collect' ? (
                  <span>
                    {t(
                      `checkoutPage.form.shipping.collectAt_${props.order.items[0].accommodation.type}`,
                      { accommodation: props.order.items[0].accommodation.name }
                    )}
                  </span>
                ) : null}
              </div>
            </div>
          </div>
        ) : null}
      </div>

      <div className="p-6 bg-white rounded-md mt-6">
        <dl className="gap-4 text-sm text-gray-900">
          <div>
            <div className="py-2 font-bold tracking-wide">
              {props.order.items[0].accommodation.name}
            </div>
            <dt className="font-medium text-gray-900 border-b-2 border-pink-700 max-w-fit">
              {t('orderStatusPage.guests')}
            </dt>
            <dd className="mt-2">
              <div className="flex flex-col gap-1 divide-y divide-gray-300">
                {props.order.items.map((item, index) => (
                  <div className="grid sm:grid-cols-2 w-full justify-between py-1 items-center">
                    <div>
                      <p className="text-gray-900 font-bold">{item.accommodation.room.name}</p>
                      <p className="text-gray-700">{item.ticket.name}</p>
                    </div>
                    <div className="md:text-right">
                      <div className="flex flex-col gap-1">
                        {props.order.guests[item.itemId].map((guest) => (
                          <div className="">
                            {guest.firstName} {guest.lastName}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </dd>
          </div>
        </dl>
      </div>
      {/*<section className="m-4 pt-6">*/}
      {/*  <div className="text-xl mb-6 font-bold tracking-wide">Your experience</div>*/}
      {/*  <OrderPaidTimeline />*/}
      {/*</section>*/}
    </>
  );
}
