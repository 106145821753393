import React, { FC, useCallback, useMemo, useState } from 'react';
import { useStepsFlow } from 'pages/packages/shop/hooks/use-steps-flow';
import { SummaryCard } from 'pages/packages/shop/components/summary-card';
import { PackagePriceBottomNav } from 'pages/packages/shop/components/package-price-bottom-nav';
import { ROUTES } from 'contants';
import { useNavigate } from 'react-router-dom';
import { useCheckoutStore } from 'pages/packages/hooks/useCheckoutStore';
import { PaymentMethods } from 'pages/packages/shop/components/payment-methods';
import { useTranslation } from 'react-i18next';
import { StepHeader } from 'pages/packages/shop/components/step-header';
import { SimulatorBanner } from 'pages/commom/components/simulator-banner';
import { useConsumerIdentifier } from 'hooks/use-consumer-identifier';
import { useEnv } from 'hooks/use.env';
import { Turnstile, TurnstileInstance } from '@marsidev/react-turnstile';
import { useFeatureFlag } from 'configcat-react';

type CheckinDateProps = {
  onSelect: (date: string) => void;
};

export const OrderSummary: FC<CheckinDateProps> = (props) => {
  const ref = React.useRef<TurnstileInstance>();
  const [turnstileToken, setTurnstileToken] = useState<string | undefined>();
  const recaptchaContainerRef = React.useRef<HTMLDivElement>(null);
  const { value: isCheckoutEnabled } = useFeatureFlag('checkoutEnabled', false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isSimulator } = useEnv();
  const [isCreatingRequest, setIsCreating] = useState(false);
  const consumer = useConsumerIdentifier((s) => s.consumer);
  const createCheckoutRequest = useCheckoutStore((state) => state.createCheckoutRequest);
  const { getTotalamount, selectedPackages } = useStepsFlow((state) => ({
    getTotalamount: state.getTotalamount,
    selectedPackages: state.selectedPackages
  }));

  const { total, pax } = getTotalamount();

  const onContinue = useCallback(async () => {
    if (isSimulator) return;

    try {
      if (!turnstileToken) {
        recaptchaContainerRef.current?.scrollIntoView({ behavior: 'smooth' });
        return;
      }

      setIsCreating(true);
      const hash = await createCheckoutRequest(selectedPackages, consumer?.sub, turnstileToken);
      navigate(`${ROUTES.PackageCheckout}/${hash}`);
    } catch (error) {
      console.error(error);
    } finally {
      setIsCreating(false);
    }
  }, [
    consumer?.sub,
    createCheckoutRequest,
    isSimulator,
    navigate,
    selectedPackages,
    turnstileToken
  ]);

  const roomsListTicketSelection = useMemo(() => {
    return selectedPackages.map((orderPackage, index) => {
      const totalInfo = getTotalamount([orderPackage]);
      return (
        <div
          key={`summary_${orderPackage.id}_${index}`}
          className="py-2 bg-black flex flex-col gap-2">
          <div className="flex flex-col gap-4 pt-1">
            <SummaryCard
              key={`${orderPackage.id}_${index}`}
              withPaymentMethods={true}
              orderPackage={orderPackage}
              count={index + 1}
              total={totalInfo.total}
              pax={totalInfo.pax}
              onRemove={console.log}
            />
          </div>
        </div>
      );
    });
  }, [getTotalamount, selectedPackages]);

  return (
    <div className="mb-12">
      <div className="">
        <div className="pb-8 md:pb-4">
          <div className="">
            <StepHeader step="summary" />

            <div className="flex flex-col gap-2 pt-2">{roomsListTicketSelection}</div>
          </div>
        </div>
      </div>
      <div>
        <PaymentMethods />
      </div>

      {isSimulator ? (
        <div className="pt-4">
          <SimulatorBanner fixed={false} />
        </div>
      ) : null}

      {isSimulator ? null : (
        <>
          <div ref={recaptchaContainerRef} className="pt-4 w-full flex md:justify-end sticky">
            <Turnstile
              ref={ref}
              onSuccess={setTurnstileToken}
              siteKey={process.env.REACT_APP_GCAPTCHA || ''}
            />
          </div>
        </>
      )}

      <PackagePriceBottomNav
        ctaHidden={isSimulator}
        ctaText={t('packageBuilder.cta.placeOrder')}
        disabled={!isCheckoutEnabled || isSimulator}
        isLoading={isCreatingRequest || (!isSimulator && !ref?.current)}
        total={total}
        pax={pax}
        onContinue={onContinue}
      />
    </div>
  );
};
