import priceToBRL from 'utils/priceToBRL';
import { Button } from '@design-system/fields/button';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Popover } from '@headlessui/react';
import { Divider } from '@mui/material';

type PriceBottomNavProps = {
  total: number;
  pax: number;
  disabled?: boolean;
  isLoading?: boolean;
  onContinue: () => void;
  ctaText?: string;
  ctaHidden?: boolean;
};
export const PackagePriceBottomNav: FC<PriceBottomNavProps> = memo(
  ({ disabled, total, pax, onContinue, isLoading, ctaHidden, ctaText }) => {
    const { t } = useTranslation();

    const ctaButton = ctaHidden ? null : (
      <Button
        className="w-full md:w-fit"
        loading={isLoading}
        disabled={disabled || isLoading}
        onClick={(e) => {
          e.preventDefault();
          onContinue();
        }}
        icon={<ChevronRightIcon />}>
        {ctaText || t('packageBuilder.cta.continue')}
      </Button>
    );

    return (
      <>
        <Popover className="z-max fixed inset-x-0 bottom-0 flex flex-col-reverse text-sm font-medium text-gray-900 md:hidden">
          <div className="lg:w-1/3 fixed top-auto bottom-0 w-full bg-gray-900 hiddem md:block">
            <Divider />
            <div className="p-4 flex flex-col gap-3">
              <div className="flex justify-between text-sm font-semibold tracking-wide uppercase text-white">
                <div>{priceToBRL.format(total)}</div>
                <div>
                  {t('packageBuilder.accomoddationRoomsWidget.forPaxQuantity', { count: pax })}
                </div>
              </div>
              {ctaButton}
            </div>
          </div>
        </Popover>
        <div className="z-20 w-full relative my-4 hidden md:block">
          <div className="px-0 md:px-0 flex border-t border-gray-700 mt-6 py-2 md:py-3 bg-black justify-between rounded-sm items-center">
            <div className="flex gap-2 tracking-wide uppercase items-baseline">
              <div className="text-white font-semibold text-md">{priceToBRL.format(total)}</div>
              <div className="text-gray-400 text-sm">
                {t('packageBuilder.accomoddationRoomsWidget.forPaxQuantity', { count: pax })}
              </div>
            </div>
            {ctaButton}
          </div>
        </div>
      </>
    );
  }
);
