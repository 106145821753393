import SvgIcon from '@mui/material/SvgIcon';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import cn from 'classnames';
import EditNoteIcon from '@mui/icons-material/EditNote';
import React, { FC, memo } from 'react';

type StepInlineHeaderProps = {
  title: string;
  description?: string;
  done?: boolean;
  disabled?: boolean;
  onClick: () => void;
};

export const StepInlineHeader: FC<StepInlineHeaderProps> = memo(
  ({ title, done, disabled, onClick, description }) => {
    return (
      <div
        className={cn(`py-4`, {
          hidden: disabled
        })}>
        <button disabled={!done || disabled} className="peer hidden" />
        <div className="justify-between items-center peer-disabled:text-gray-500 text-gray-100 flex gap-1  w-full">
          <div className="flex gap-1 items-center cursor-auto text-left text-lg font-medium">
            {title}
            {done ? (
              <SvgIcon fontSize="small" className="pr-1">
                <CheckCircleIcon className="h-3 w-3 text-green-400" />
              </SvgIcon>
            ) : null}
          </div>
          <div
            className={cn({
              hidden: !done
            })}>
            <button onClick={onClick}>
              <SvgIcon className="text-white hover:text-pink-700">
                <EditNoteIcon />
              </SvgIcon>
            </button>
          </div>
        </div>

        {description ? <div className="peer-disabled:text-gray-600">{description}</div> : null}
      </div>
    );
  }
);
