import Countdown from 'react-countdown';
import { Trans } from 'react-i18next';
import { CheckoutStepsWizard } from 'pages/packages/checkout/steps-wizard';
import React, { Dispatch, SetStateAction } from 'react';
import { CheckoutOrder } from 'legacy-types/get-order-package.types';
import { useOrderSummary } from 'pages/commom/components/order-summary/use-order-summary';

export const CheckoutView = ({
  data,
  setExpired
}: {
  data: CheckoutOrder;
  setExpired: Dispatch<SetStateAction<boolean>>;
}) => {
  const { ShowSummaryCTA, SummaryCards } = useOrderSummary(data.packageInfo);

  return (
    <div className="mx-auto mt-16 w-full md:max-w-3xl px-3 md:px-0">
      <div className="flex items-center pb-6">
        <Countdown
          daysInHours
          date={new Date(data?.expireAt || '').getTime()}
          onComplete={() => setExpired(true)}
          renderer={(props) => (
            <div className="p-2 bg-gray-800 flex items-center justify-center w-full rounded-md text-sm md:text-base">
              <Trans
                i18nKey="checkoutPage.timeToCheckout"
                values={{
                  time: `${String(props.minutes).padStart(2, '0')}:${String(props.seconds).padStart(
                    2,
                    '0'
                  )}`
                }}>
                You have <span className="font-bold px-1">??:??</span> to complete your order
              </Trans>
            </div>
          )}
        />
      </div>
      <div className="flex justify-between items-center">
        <div className="text-3xl text-white font-bold">Checkout</div>
        {ShowSummaryCTA}
      </div>
      {SummaryCards}
      {data?.checkoutItems ? <CheckoutStepsWizard data={data} /> : null}
    </div>
  );
};
