import { useQuery } from 'react-query';
import { CountryCode } from 'libphonenumber-js';

export const useGeolocation = () => {
  const { data, isLoading } = useQuery<{ country_code: CountryCode }>(
    'geolocation',
    async () => {
      const response = await fetch('https://ipapi.co/json/');
      return response.json();
    },
    { refetchOnWindowFocus: false }
  );

  return {
    countryCode: data?.country_code,
    isLoading
  };
};
