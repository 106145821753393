import priceToBRL from 'utils/priceToBRL';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

type TotalPanelProps = {
  total?: number;
  totalWithFee?: number;
  fee?: number;
  pax?: number;
  shippingPrice?: number;
};

export const TotalPanel: FC<TotalPanelProps> = ({
  total,
  shippingPrice,
  totalWithFee,
  fee,
  pax
}) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="mt-2 border-b py-2 border-gray-500">
        <div className="flex items-center justify-between text-gray-900">
          <p className="text-sm font-medium ">{t('checkoutPage.payment.subtotal')}</p>
          <p className="font-semibold">{priceToBRL.format(Number(total))}</p>
        </div>
        {shippingPrice ? (
          <div className="flex items-center justify-between text-sm py-1 text-gray-700">
            <p className="font-medium ">{t('checkoutPage.payment.shipping')}</p>
            <p className="font-semibold ">{priceToBRL.format(shippingPrice)}</p>
          </div>
        ) : null}
        <div className="flex items-center justify-between text-sm py-1 text-gray-700">
          <p className="font-medium ">{t('checkoutPage.payment.paymentFee')}</p>
          <p className="font-semibold ">{priceToBRL.format(Number(fee) || NaN)}</p>
        </div>
      </div>

      <div className="mt-4 flex items-center justify-between text-gray-900">
        <p className="text-sm font-medium">
          {t('checkoutPage.payment.totalForPaxQuantity', { count: pax })}
        </p>
        <p className="text-xl font-semibold">{priceToBRL.format(Number(totalWithFee))}</p>
      </div>
    </>
  );
};
