import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import React, { ButtonHTMLAttributes, forwardRef, ReactNode } from 'react';
import Chip from '@mui/joy/Chip';

type LandingPageCardProps = {
  title: string;
  key: string;
  description: ReactNode;
  icon: React.ReactNode;
  ctaText: string;
  onClick: () => void;
  soldOut?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const LandingPageCard = forwardRef<HTMLDivElement, LandingPageCardProps>(
  ({ onClick, ctaText, title, icon, description, soldOut, disabled, ...props }, ref) => {
    return (
      <button
        {...props}
        disabled={disabled}
        onClick={onClick}
        className="peer disabled:bg-gray-900/40 bg-gray-900/60 disabled:hover:outline-0 hover:outline outline-gray-800 h-fit md:min-h-[210px] md:max-h-[250px] relative isolate overflow-hiddenshadow-2xl rounded-md px-10 py-8 items-center md:items-start flex-col md:flex-row flex gap-1 justify-between">
        <div className=" max-w-md text-center lg:text-left">
          <h2 className="text-2xl font-bold tracking-tight text-white flex flex-col md:flex-row justify-between items-center gap-2">
            <span className="border-b-2 border-pink-700">{title}</span>{' '}
            <div className="mt-1 md:mt-0">
              {soldOut ? <Chip color="danger">SOLD OUT</Chip> : icon || null}
            </div>
          </h2>
          <p className="mt-4 text-sm leading-8 text-gray-300">{description}</p>
        </div>
        <div className="flex gap-1 mt-4 self-center text-pink-600 md:text-white">
          <span className="md:hidden">{ctaText}</span>
          <ArrowForwardIosIcon />
        </div>
      </button>
    );
  }
);
