import { create } from 'zustand';
import { API_URLS, apiFetch } from 'utils/apiFetch';
import { immer } from 'zustand/middleware/immer';
import { OrderCrypto } from '@common/crypto/crypto-order';
import { OrderPackage } from 'legacy-types/order';
import { PaymentRequest } from 'legacy-types/payment';
import { CheckoutOrder } from 'legacy-types/get-order-package.types';
import { v4 as uuidv4 } from 'uuid';

export type useCheckoutStoreState = {};

type Error = { error: boolean; code: string };

export type useCheckoutStoreActions = {
  processCheckout: (checkoutHash: string, data: PaymentRequest) => Promise<string>;
  createCheckoutRequest: (
    order: OrderPackage[],
    externalUserId?: string,
    captchaToken?: string
  ) => Promise<void>;
  getCheckouRequestInfo: (uuid: string) => Promise<CheckoutOrder & Error>;
};

export const useCheckoutStore = create(
  immer<useCheckoutStoreActions & useCheckoutStoreState>((set) => ({
    createCheckoutRequest: (order, externalUserId, captchaToken) => {
      const sessionId = uuidv4(); // TODO: get from cookie storage?

      return apiFetch(API_URLS.CHECKOUT_REQUEST).post({
        token: captchaToken,
        session_id: sessionId,
        hash: OrderCrypto(sessionId).encode(order),
        external_user_id: externalUserId
      });
    },
    getCheckouRequestInfo: (uuid) => {
      return apiFetch(API_URLS.CHECKOUT_REQUEST).get({
        uuid
      });
    },
    processCheckout: (checkoutHash, data) => {
      return apiFetch(`${API_URLS.CHECKOUT_SUBMIT_PAYMENT}/${checkoutHash}`).post(data);
    }
  }))
);
