import React, { forwardRef, InputHTMLAttributes } from 'react';
import { IMaskMixin } from 'react-imask';
import { Input } from '@design-system/fields/input';

interface CustomProps extends InputHTMLAttributes<HTMLInputElement> {
  type: 'cpf' | 'passport' | 'dni';
  name: string;
  error?: string;
  label?: string | React.ReactNode;
}

const MaskedStyledInput = IMaskMixin(({ inputRef, ...maskProps }) => (
  <Input
    {...maskProps}
    label={maskProps.label}
    error={maskProps['aria-errormessage']}
    ref={inputRef as any}
  />
));

export const DocumentTypeInput = forwardRef<HTMLInputElement, CustomProps>(
  ({ type, error, ...props }, ref) => {
    return (
      <MaskedStyledInput
        {...props}
        placeholder={type === 'cpf' ? '000.000.000-00' : ''}
        aria-errormessage={error}
        label={props.label}
        mask={type === 'cpf' ? '000.000.000-00' : ''}
        definitions={
          type === 'cpf'
            ? {
                '#': /^\d{3}\.\d{3}\.\d{3}-\d{2}$/i
              }
            : undefined
        }
        inputRef={ref}
        onAccept={(value: any) => props.onChange?.({ target: { name: props.name, value } } as any)}
        overwrite
      />
    );
  }
);
