import { StepType } from 'pages/packages/types';

export function transformToDefaultSteps(stepsArray: StepType[]) {
  const defaultSteps = {};

  stepsArray.forEach((step) => {
    defaultSteps[step] = null;
  });

  return defaultSteps;
}
