import { LoadingContainer } from 'pages/commom/loading-container';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

export const CheckoutPendingView = ({ refetch }: { refetch: () => void }) => {
  const { t } = useTranslation();
  const refetchDurationRef = useRef(5000);

  useEffect(() => {
    const intervalId = setTimeout(refetch, refetchDurationRef.current);
    refetchDurationRef.current += 5000;

    return () => clearTimeout(intervalId);
  }, [refetch]);

  return (
    <LoadingContainer
      title={t('loadingStates.processing.title')}
      description={t('loadingStates.processing.description')}
    />
  );
};
