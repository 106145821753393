import React from 'react';
import { useTranslation } from 'react-i18next';
import { HotelCategories } from 'pages/constants';
import { AccommodationCategoryType } from 'legacy-types/accommodation';

const colors: { [key in AccommodationCategoryType]: string } = {
  [HotelCategories.Bronze]: 'bg-orange-900',
  [HotelCategories.Silver]: 'bg-gray-400',
  [HotelCategories.Gold]: 'bg-yellow-800',
  [HotelCategories.Thematic]: 'bg-pink-300',
  [HotelCategories.EasyTents]: 'bg-blue-200',
  [HotelCategories.MG]: 'bg-green-200'
};

const Chip = (props: { color: string; text: string }) => (
  <div
    className={`rounded-sm my-1 px-2 bf- font-bold ${props.color} text-black w-fit text-xs uppercase`}>
    {props.text}
  </div>
);

export const AccommodationTypeChips = (props: { category: AccommodationCategoryType }) => {
  const { t } = useTranslation();
  return (
    <Chip color={colors[props.category]} text={t(`common.hotelCategories.${props.category}`)} />
  );
};
