import * as React from 'react';
import { SelectMenu } from '@design-system/fields/select-menu';
import { useTranslation } from 'react-i18next';

export default function DocumentTypeSelect(props: {
  value: string;
  error?: string;
  disabled?: boolean;
  onChange: (value: string) => void;
}) {
  const { t } = useTranslation();
  const options = [
    { value: 'passport', label: t('common.documentTypes.passport') },
    { value: 'cpf', label: t('common.documentTypes.CPF') },
    { value: 'dni', label: t('common.documentTypes.DNI') } // Documento Nacional de Identidad
  ];

  return (
    <SelectMenu
      disabled={props.disabled}
      label={t('checkoutPage.form.documentType')}
      options={options}
      error={props.error}
      value={options.find((opt) => opt.value === props.value)}
      onSelect={(value) => {
        value && props.onChange(value.value);
      }}
    />
  );
}
