// Extracted reusable functions
import { UseStepsFlow } from 'pages/packages/types';
import { JOURNEY_TYPE, STEPS } from 'pages/packages/constants';
import { OrderPackage } from 'legacy-types/order';
import { v4 as uuidv4 } from 'uuid';
import { AccommodationRoomPriceType } from 'legacy-types/accommodation';

export const getJourneys = (get: () => UseStepsFlow): OrderPackage['journeys'] => {
  const journeyType = get().flow?.journeyType;
  const location = get().steps?.[STEPS.JOURNEY_ORIGIN];
  const journeyReturnId = get().steps?.[STEPS.JOURNEY_RETURN];
  const journeys = get().journeys;

  if (!journeyReturnId || !location) {
    return undefined;
  }

  const journeyPrice = location
    ? Object.values(journeys).find((j) => j?.[location])?.[location]
    : undefined;

  if (!journeyPrice?.priceId) {
    throw new Error('Invalid journey data');
  }

  return {
    id: journeyReturnId,
    type: journeyType || JOURNEY_TYPE.FLIGHT,
    location,
    priceId: journeyPrice.priceId
  };
};

export const createRoomPackage = (
  roomId: string,
  accommodationId: string,
  roomPrice: AccommodationRoomPriceType,
  ticket?: OrderPackage['ticket'],
  journeys?: OrderPackage['journeys']
): OrderPackage => {
  return {
    id: uuidv4(),
    accommodation: {
      id: accommodationId,
      room: {
        id: roomId,
        priceId: roomPrice.id,
        checkin: roomPrice.checkin,
        checkout: roomPrice.checkout
      }
    },
    ticket: ticket,
    journeys: journeys
  };
};
