import React from 'react';
import ReactDOM from 'react-dom/client';
import * as configcat from 'configcat-js';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { QueryClient, QueryClientProvider } from 'react-query';
import { API, Auth } from 'aws-amplify';
import awsConfig from './aws-exports';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n/i18n';
import { track } from '@common/analytics/use-analytics';
import * as Sentry from '@sentry/react';
import { ConfigCatProvider, PollingMode } from 'configcat-react';

import './styles/index.scss';
import './index.css';
import './fonts/line-awesome-1.3.0/css/line-awesome.css';
import { PrivacyNotice } from '@common/components/privacy-notice';
import { ConsoleLogger } from '@aws-amplify/core';
import { BUILD_ENV } from 'contants';

API.configure(awsConfig);

Auth.configure({
  identityPoolId: awsConfig.aws_cognito_identity_pool_id,
  region: awsConfig.aws_cognito_region,
  userPoolId: awsConfig.aws_user_pools_id,
  userPoolWebClientId: awsConfig.aws_user_pools_web_client_id
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const queryClient = new QueryClient();

Sentry.init({
  dsn: 'https://36c1fa7de41408cbff7e08f3d936557b@o4506773128282112.ingest.sentry.io/4506775647027200',
  environment: process.env.ENV,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false
    })
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.15, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const logger = configcat.createConsoleLogger(configcat.LogLevel.Off);

ConsoleLogger.LOG_LEVEL = BUILD_ENV === 'production' ? 'ERROR' : 'DEBUG';

const renderApp = async () => {
  void track('PAGE_VIEW', 'page');

  return root.render(
    <I18nextProvider i18n={i18n}>
      <QueryClientProvider client={queryClient}>
        <ConfigCatProvider
          sdkKey={process.env.REACT_APP_CONFIGCAT || ''}
          pollingMode={PollingMode.LazyLoad}
          options={{ logger, pollIntervalSeconds: 60 }}>
          <App />
          <PrivacyNotice />
        </ConfigCatProvider>
      </QueryClientProvider>
    </I18nextProvider>
  );
};

renderApp().then();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
