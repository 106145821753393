import React, { Fragment, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Combobox as ComboboxHS } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon, ExclamationCircleIcon } from '@heroicons/react/20/solid';

export type SelectOption = { id: string; name: string };

export type SelectProps = {
  value?: SelectOption;
  onSelect: (value: SelectOption) => void;
  options: SelectOption[];
  label?: string | React.ReactNode;
  error?: string;
};

const Combobox = ({ value, onSelect, options, label, error }: SelectProps) => {
  const [selected, setSelected] = useState<SelectOption | undefined>();
  const [query, setQuery] = useState('');

  const filteredOptions = useMemo(
    () =>
      query === ''
        ? options
        : options.filter((opt) => {
            return opt.name.toLowerCase().includes(query.toLowerCase());
          }),
    [options, query]
  );

  useEffect(() => {
    if (value?.id !== selected?.id) {
      setSelected(value);
    }
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ComboboxHS
      as="div"
      value={selected || options[0]}
      onChange={(value) => {
        setSelected(value);
        onSelect(value);
      }}>
      {label ? (
        <ComboboxHS.Label className="block text-sm font-medium leading-6 text-gray-900">
          {label}
        </ComboboxHS.Label>
      ) : null}
      <div className="relative mt-2">
        <ComboboxHS.Input
          className={classNames(
            'h-10 w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6',
            'disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200',
            {
              'text-red-900 ring-red-300 placeholder:text-red-300': error
            }
          )}
          onChange={(event) => setQuery(event.target.value)}
          displayValue={() => selected?.name || ''}
        />
        {error ? (
          <div className="pointer-events-none absolute inset-y-0 right-4 flex items-center pr-3">
            <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
          </div>
        ) : null}
        <ComboboxHS.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </ComboboxHS.Button>

        {filteredOptions.length > 0 && (
          <ComboboxHS.Options
            className={classNames(
              'absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'
            )}>
            {filteredOptions.map((opt) => (
              <ComboboxHS.Option
                key={opt.id}
                value={opt}
                className={({ active }) =>
                  classNames(
                    'relative cursor-default select-none py-2 pl-3 pr-9',
                    active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                  )
                }>
                {({ active, selected }) => (
                  <>
                    <span className={classNames('block truncate', selected && 'font-semibold')}>
                      {opt.name}
                    </span>

                    {selected && (
                      <span
                        className={classNames(
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                          active ? 'text-white' : 'text-indigo-600'
                        )}>
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </ComboboxHS.Option>
            ))}
          </ComboboxHS.Options>
        )}
      </div>
      {error ? (
        <p className="mt-2 text-sm text-red-600" id="email-error">
          {error}
        </p>
      ) : null}
    </ComboboxHS>
  );
};

export default Combobox;
