import React, { forwardRef, InputHTMLAttributes, useId } from 'react';
import cx from 'classnames';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { FieldContainer } from '@design-system/fields/field-container';
import { Input as HeadlessInput } from '@headlessui/react';

export type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  ref?: React.ForwardedRef<HTMLInputElement>;
  label?: string | React.ReactNode;
  error?: string | React.ReactNode;
};

export const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const id = useId();
  return (
    <FieldContainer label={props.label} error={props.error}>
      <div className="relative mt-2 rounded-md shadow-sm">
        <HeadlessInput
          ref={ref}
          type="text"
          id={id}
          {...props}
          className={cx(
            'h-10 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6',
            'disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200',
            {
              'text-red-900 ring-red-300 placeholder:text-red-300': props.error
            }
          )}
        />
        {props.error ? (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
          </div>
        ) : null}
      </div>
    </FieldContainer>
  );
});
