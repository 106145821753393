import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useStepsFlow } from 'pages/packages/shop/hooks/use-steps-flow';
import {
  AccommodationDates,
  AccommodationDatesType
} from 'pages/packages/shop/steps/accommodation-dates';
import { Accommodation } from 'pages/packages/shop/steps/accommodation';
import { AccommodationDetail } from 'pages/packages/shop/steps/accommodation-detail';
import { JourneyLocations } from 'pages/packages/shop/steps/journey-locations';
import { STEPS } from 'pages/packages/constants';
import { PackagesType, StepType, StepValue } from 'pages/packages/types';
import { JourneySchedule } from 'pages/packages/shop/steps/journey-schedule';
import { TicketSelection } from 'pages/packages/shop/steps/ticket-selection';
import { OrderSummary } from 'pages/packages/shop/steps/summary';
import { Transition } from '@headlessui/react';
import { JourneyOrigin } from 'pages/packages/shop/steps/journey-origin';
import { Badge } from '@design-system/feedback/badge';
import { useEnv } from 'hooks/use.env';
import { useTranslation } from 'react-i18next';

export const StepComponent = () => {
  const { t } = useTranslation();
  const { isSimulator } = useEnv();
  const { type, step } = useParams<{ step: StepType; type: PackagesType }>();
  const navigate = useNavigate();
  const { onSelectStep, flow, setStep, steps, resetSelectedRooms } = useStepsFlow((s) => ({
    onSelectStep: s.onSelectStep,
    setStep: s.setStep,
    flow: s.flow,
    steps: s.steps,
    resetSelectedRooms: s.resetSelectedRooms
  }));

  useEffect(() => {
    if (!step) navigate('/');
  }, [navigate, step]);

  const onSelect = (value: StepValue) => {
    if (step && type) {
      const navigateOptions = onSelectStep(step, type, value);
      navigate(navigateOptions);
    }
  };

  const stepComponents = {
    [STEPS.CHECKIN_DATE]: (
      <AccommodationDates
        type={AccommodationDatesType.CHECKIN}
        value={steps?.[STEPS.CHECKIN_DATE]}
        onSelect={onSelect}
      />
    ),
    [STEPS.CHECKOUT_DATE]: (
      <AccommodationDates
        type={AccommodationDatesType.CHECKOUT}
        value={steps?.[STEPS.CHECKIN_DATE]}
        onSelect={onSelect}
      />
    ),
    [STEPS.ACCOMODATION_LIST]: (
      <Accommodation
        onSelect={(accommodationId) => {
          resetSelectedRooms();
          onSelect(accommodationId);
        }}
      />
    ),
    [STEPS.ACCOMODATION]: <AccommodationDetail onSelect={onSelect} />,
    [STEPS.JOURNEY_ORIGIN_STATE]: <JourneyOrigin onSelect={onSelect} type="state" />,
    [STEPS.JOURNEY_ORIGIN_COUNTRY]: <JourneyOrigin onSelect={onSelect} type="country" />,
    [STEPS.JOURNEY_ORIGIN]: flow?.journeyType ? (
      <JourneyLocations
        type={flow.journeyType}
        onSelect={({ journeyId, journeyState }) => {
          setStep(STEPS.JOURNEY_ORIGIN_STATE, journeyState);
          onSelect(journeyId);
        }}
      />
    ) : null,
    [STEPS.JOURNEY_OUTBOUND]: flow?.journeyType ? (
      <JourneySchedule
        routeType="outbound"
        journeyType={flow.journeyType}
        journeyId={steps?.[STEPS.JOURNEY_ORIGIN]}
        onSelect={(id, info) => {
          const arrivalDate = info.arrival.date.split(' ')[0];
          setStep(STEPS.CHECKIN_DATE, arrivalDate);
          onSelect(id);
        }}
      />
    ) : null,
    [STEPS.JOURNEY_RETURN]: flow?.journeyType ? (
      <JourneySchedule
        routeType="return"
        journeyType={flow.journeyType}
        journeyId={steps?.[STEPS.JOURNEY_OUTBOUND]}
        onSelect={(id, info) => {
          const departureDate = info.departure.date.split(' ')[0];
          setStep(STEPS.CHECKOUT_DATE, departureDate);
          onSelect(id);
        }}
      />
    ) : null,
    [STEPS.TICKET_SELECTION]: <TicketSelection onSelect={onSelect} />,
    [STEPS.SUMMARY]: <OrderSummary onSelect={onSelect} />
  };

  return step ? (
    <Transition
      show={true}
      enter="ease-out duration-300"
      enterFrom="opacity-0 scale-95"
      enterTo="opacity-100 scale-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-95">
      {isSimulator ? (
        <Badge value={t('common.simualtor')} color="warning" className="mb-4" />
      ) : null}
      <div>{stepComponents[step]}</div>
    </Transition>
  ) : (
    <div />
  );
};
