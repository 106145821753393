import * as React from 'react';
import { useCallback } from 'react';
import cn from 'classnames';
import './StepsTab.css';
import { useTranslation } from 'react-i18next'; // Import the CSS file for custom styles
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { PackagesType, StepType } from 'pages/packages/types';
import { ROUTES } from 'contants';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SvgIcon from '@mui/material/SvgIcon';
import { useStepsFlow } from 'pages/packages/shop/hooks/use-steps-flow';
import { ChevronLeftOutlined } from '@mui/icons-material';
import { Button } from '@design-system/fields/button';

const StepsTab = ({ steps, onTabClick }) => {
  const journeyType = useStepsFlow((s) => s.flow?.journeyType);
  const { type, step } = useParams<{ step: StepType; type: PackagesType }>();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isScrolled, setIsScrolled] = React.useState(false);
  const navRef = React.useRef<any>(null);
  const stepRefs = React.useRef<any>({});

  const checkScroll = () => {
    if (navRef.current) {
      const isScrolled = navRef.current.scrollLeft > 0;
      setIsScrolled(isScrolled);
    }
  };

  React.useEffect(() => {
    if (step && stepRefs.current[step] && navRef.current) {
      const activeElement = stepRefs.current[step];
      navRef.current.scrollTo({
        left:
          activeElement.offsetLeft -
          navRef.current.offsetLeft -
          (navRef.current.offsetWidth / 2 - activeElement.offsetWidth / 2),
        behavior: 'smooth'
      });
    }
  }, [step]);

  const renderIcon = () => {
    return (
      <SvgIcon fontSize="small" className="pr-1">
        <CheckCircleIcon className="h-3 w-3 text-green-400" />
      </SvgIcon>
    );
  };

  const activeStepIndex = step ? steps.indexOf(step) : 0;
  const prevStep = steps[activeStepIndex - 1] || steps[0];

  const onPrevious = useCallback(() => {
    navigate({
      pathname: `${ROUTES.PackageBuider}/${type}/${prevStep}`,
      search: location.search
    });
  }, [prevStep, location.search, navigate, type]);

  return (
    <div className="w-full ">
      <div className="py-2 sm:px-4 lg:flex items-center lg:justify-left justify-self-center">
        <div className="lg:hidden flex justify-between items-center  pt-2 ">
          <Button variant="text" color="neutral" onClick={onPrevious}>
            <SvgIcon fontSize="small">
              <ChevronLeftOutlined />
            </SvgIcon>
            Go back
          </Button>
          <div className="font-bold tracking-wide text-lg border-b-2 mr-2 border-b-pink-800">
            {t(`packageBuilder.stepsTabs.${steps[activeStepIndex]}`, {
              context: journeyType
            })}
          </div>
        </div>
        <nav
          ref={navRef}
          onScroll={checkScroll}
          className="flex-nowrap gap-6 overflow-x-auto scrolling-touch scroll-smooth px-3 sm:px-0 hidden lg:flex">
          {steps.map((stepKey) => {
            const currentStepIndex = steps.indexOf(stepKey);
            const previousStep = currentStepIndex < activeStepIndex;

            return (
              <a
                onClick={() => {
                  if (previousStep) {
                    navigate({
                      pathname: `${ROUTES.PackageBuider}/${type}/${stepKey}`,
                      search: location.search
                    });
                  }
                }}
                key={stepKey}
                ref={(el) => (stepRefs.current[stepKey] = el)}
                className={cn(
                  'inline-flex  whitespace-nowrap border-b-2 border-transparent py-0 mt-2 text-sm font-medium text-gray-600 transition-all duration-200 ease-in-out',
                  {
                    'text-sm font-semibold text-pink-800 transition-all duration-200 ease-in-out border-b-pink-800':
                      stepKey === step,
                    'cursor-pointer': previousStep
                  }
                )}>
                {previousStep ? renderIcon() : null}
                {t(`packageBuilder.stepsTabs.${stepKey}`, {
                  context: journeyType
                })}
              </a>
            );
          })}
        </nav>
        {isScrolled && <div className="gradient-effect-start"></div>}
        <div className="gradient-effect-end"></div>
      </div>
    </div>
  );
};

export default StepsTab;
