import React from 'react';
import styles from './landing-page.module.css';
import { useTranslation } from 'react-i18next';
import Footer from 'shared/Footer/Footer';
import TomorrowlandLogo from 'images/tomorrowland-icons/tl_logo_color.svg';
import { Divider } from '@mui/joy';
import { SimulatorBanner } from 'pages/commom/components/simulator-banner';

function LandingPage({
  children,
  showHint = true
}: {
  children: React.ReactNode;
  showHint?: boolean;
}) {
  const { t } = useTranslation('translation', { keyPrefix: 'landingPage' });
  return (
    <div className="bg-black pb-40 md:pb-12">
      <div className={styles.heroImageContainer}>
        <img
          className={styles.accommodationImage}
          src="/images/tomorrowland/background.jpeg"
          width={1500}
          height={800}
          alt="Tomorrowland Brazil 2023 theme background"
        />
      </div>
      <div className="container  items-center justify-center flex flex-col px-4 gap-8  relative pt-[60px] md:pt-[100px] mb-12">
        <div className="flex justify-center flex-col items-center max-w-xl pt-12">
          <img
            alt="Event logo"
            className="w-[70px] md:w-[100px] self-center"
            src={TomorrowlandLogo}
          />
          <div className="pt-4 tracking-widest leading-[clamp(1.75rem,8vw,3.25rem)] font-bold text-3xl md:text-4xl text-center text-white uppercase max-w-sm md:max-w-lg">
            {t('subTitle')}
          </div>
          <div className="tracking-widest text-2xl pt-2 text-center pb-4 text-white">
            {t('title')}
          </div>
          <SimulatorBanner fixed={false} />
          <Divider sx={{ mt: 2, width: '50%', alignSelf: 'center' }} className="" />
          {showHint ? (
            <div className="pt-6 font-bold text-center px-4 text-gray-400 text-lg">{t('hint')}</div>
          ) : null}
        </div>

        <div className="divider" />
        {children}
      </div>
      <footer className="self-center h-96">
        <Footer />
      </footer>
    </div>
  );
}

export default LandingPage;
