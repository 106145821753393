import React, { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { CountryDropdown } from 'pages/packages/checkout/components/country-dropdown';
import { StateDropdown } from 'pages/packages/checkout/components/state-dropdown';
import { AddressDetailsForm } from 'pages/packages/checkout/types';
import { Input } from '@design-system/fields/input';
import { Button } from '@design-system/fields/button';
import { Trans, useTranslation } from 'react-i18next';

type AddressDetailtsProps = {
  onSubmit: (value: AddressDetailsForm) => void;
  defaultValues?: Partial<AddressDetailsForm>;
  ctaText?: string;
  type?: 'billing' | 'shipping';
};

export const AddressDetailts: FC<AddressDetailtsProps> = ({
  onSubmit,
  defaultValues,
  ctaText,
  type
}) => {
  const { t } = useTranslation();
  const form = useForm<AddressDetailsForm>({ defaultValues });
  const country = form.watch('addressCountry');
  const addressState = form.watch('addressState');

  return (
    <>
      <div>
        <div className="grid grid-rows-1 sm:grid-cols-2 gap-4 my-2">
          <Controller
            rules={{ required: t('checkoutPage.form.mandatoryField') }}
            control={form.control}
            name={'addressCountry'}
            render={({ field }) => (
              <CountryDropdown
                error={form.formState.errors.addressCountry?.message}
                label={t('checkoutPage.form.country')}
                value={field.value}
                onChange={(c) => {
                  field.onChange(c);
                  form.setValue('addressState', '');
                }}
              />
            )}
          />

          <Controller
            rules={{ required: 'County is mandatory' }}
            control={form.control}
            name={'addressState'}
            render={({ field }) => (
              <StateDropdown
                error={form.formState.errors.addressState?.message}
                label={t('checkoutPage.form.state')}
                country={country}
                value={addressState}
                onChange={(v) => field.onChange(v)}
              />
            )}
          />

          <Input
            error={form.formState.errors.addressCity?.message}
            label={t('checkoutPage.form.city')}
            {...form.register('addressCity', {
              required: t('checkoutPage.form.mandatoryField')
            })}
          />

          <Input
            error={form.formState.errors.address1?.message}
            label={
              <Trans i18nKey="checkoutPage.form.address1">
                Address
                <span className="text-sm text-gray-600 pl-1 font-normal">
                  (street, house, number)
                </span>
              </Trans>
            }
            placeholder={t('checkoutPage.placeholders.address1')}
            {...form.register('address1', {
              required: t('checkoutPage.form.mandatoryField')
            })}
          />

          <Input
            error={form.formState.errors.address2?.message}
            label={t('checkoutPage.form.address2')}
            placeholder=""
            {...form.register('address2', {})}
          />

          <Input
            error={form.formState.errors.addressPostalCode?.message}
            label={country === 'BR' ? 'CEP' : t('checkoutPage.form.postalCode')}
            {...form.register('addressPostalCode', {
              required: t('checkoutPage.form.mandatoryField'),
              validate: (value) => {
                if (country === 'BR') {
                  return /^\d{8}$/.test(value) || 'CEP inválido, digite apenas números';
                }
                // Add additional validation for other countries if needed
              }
            })}
          />
        </div>
      </div>

      <Button
        color={type === 'shipping' ? 'secondary' : 'primary'}
        className="mt-4"
        fullWidth
        onClick={form.handleSubmit(onSubmit)}
        type="submit">
        {ctaText || t('checkoutPage.cta.continue')}
      </Button>
    </>
  );
};
