import { Button } from '@design-system/fields/button';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import React, { FC } from 'react';
import { OrderGuestDetails } from 'legacy-types/customer';
import { useTranslation } from 'react-i18next';

type GuestListRowProps = {
  onRemove: (id: string) => void;
  guest: OrderGuestDetails;
};

export const GuestListRow: FC<GuestListRowProps> = ({ onRemove, guest }) => {
  const { t } = useTranslation();

  return (
    <div className="relative flex justify-between gap-x-6 px-2 py-2 ">
      <div className="flex min-w-0 gap-x-4">
        <div className="min-w-0 flex-auto">
          <div className="text-sm font-semibold leading-6 text-gray-900">
            <div>
              <span className="" />
              {guest.firstName} {guest.lastName}
            </div>
          </div>
          <div className="mt-1 flex text-xs leading-5 text-gray-500">
            <div className="relative truncate">{guest.email}</div>
          </div>
        </div>
      </div>
      <div className="flex  items-center gap-x-4">
        <Button
          onClick={() => onRemove(guest.id)}
          color="secondary"
          variant="text"
          icon={<ChevronRightIcon className="h-5 w-5 flex-none" aria-hidden="true" />}>
          {t('checkoutPage.formGuests.remove')}
        </Button>
      </div>
    </div>
  );
};
