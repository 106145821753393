import React from 'react';
import { EmptyStateContainer } from 'pages/commom/empty-state-container';

export const CheckoutUnavilableView = () => {
  return (
    <EmptyStateContainer
      title=" The package you selected is not available"
      description=" We are sorry, but the package you selected is not available or out of stock. Please select
          another package or try again later."
      buttonText="Go back"
      buttonAction={() => window.location.replace('/')}
    />
  );
};
