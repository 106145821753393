import { SummaryCard } from 'pages/packages/shop/components/summary-card';
import { Transition } from '@headlessui/react';
import React, { useMemo, useState } from 'react';
import { useStepsFlow } from 'pages/packages/shop/hooks/use-steps-flow';
import cn from 'classnames';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { useTranslation } from 'react-i18next';
import { OrderPackage } from 'legacy-types/order';

export const useOrderSummary = (data?: OrderPackage[], defaultOpen = false, compact = false) => {
  const { t } = useTranslation();
  const [showSummary, setShowSummary] = useState(defaultOpen);
  const getTotalamount = useStepsFlow((state) => state.getTotalamount);

  const SummaryCards = useMemo(() => {
    return (
      <Transition
        show={showSummary}
        enter="ease-out duration-300"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="ease-in duration-150"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95">
        <div className="pt-4 flex flex-col gap-6">
          <h3 className="text-lg border-b-2 border-pink-700 w-fit font-bold tracking-wide">
            Summary
          </h3>
          {data?.map((info, index) => {
            const totalInfo = getTotalamount([info]);

            return (
              <SummaryCard
                compact={compact}
                withPaymentMethods={false}
                key={`${info.id}_${index}`}
                orderPackage={info}
                count={index + 1}
                total={totalInfo.total}
                pax={totalInfo.pax}
              />
            );
          })}
        </div>
      </Transition>
    );
  }, [compact, data, getTotalamount, showSummary]);

  const ShowSummaryCTA = useMemo(
    () => (
      <div
        onClick={() => setShowSummary(!showSummary)}
        className={cn('text-pink-600 font-bold cursor-pointer flex gap-1 items-center')}>
        {showSummary
          ? t('orderSummary.seeLessItems')
          : t('orderSummary.seeMoreItems', { count: data?.length })}
        <ChevronRight fontSize="small" className="text-pink-600" />
      </div>
    ),
    [data?.length, showSummary, t]
  );

  return {
    SummaryCards,
    ShowSummaryCTA,
    setShowSummary
  };
};
