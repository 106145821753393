import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useStepsFlow } from 'pages/packages/shop/hooks/use-steps-flow';
import { STEPS } from 'pages/packages/constants';
import priceToBRL from 'utils/priceToBRL';
import { PackageCard } from 'pages/packages/shop/components/package-card';
import { PackagePriceBottomNav } from 'pages/packages/shop/components/package-price-bottom-nav';
import { StepHeader } from 'pages/packages/shop/components/step-header';
import { StackCardOption, StackedCards } from '@design-system/components/stacked-cards';
import { AvailabilityChip } from 'pages/commom/components/availability-chip';
import { TicketType } from 'legacy-types/ticket';
import { AccommodationType } from 'legacy-types/accommodation';

type CheckinDateProps = {
  onSelect: (date: string) => void;
};

const filterTicketsByAccommType = (tickets: TicketType[], type?: AccommodationType) => {
  return tickets
    .filter((ticket) => {
      if (type === 'hotel') {
        return true;
      }

      return ticket.price > 0;
    })
    .sort((a, b) => {
      // If either price is 0, handle separately
      if (a.price === 0) return 1;
      if (b.price === 0) return -1;
      // Otherwise, sort by price
      return a.price - b.price;
    });
};

export const TicketSelection: FC<CheckinDateProps> = (props) => {
  const {
    tickets,
    inventoryAvailability,
    updatePackageTicket,
    getTotalamount,
    accommodations,
    steps,
    accommodationsRooms,
    selectedPackages
  } = useStepsFlow((state) => ({
    tickets: state.tickets,
    updatePackageTicket: state.updatePackageTicket,
    getTotalamount: state.getTotalamount,
    steps: state.steps,
    inventoryAvailability: state.inventoryAvailability,
    selectedPackages: state.selectedPackages,
    accommodations: state.accommodations,
    accommodationsRooms: state.accommodationsRooms
  }));

  const { t } = useTranslation();

  const onContinue = useCallback(() => {
    // navigate(`${ROUTES.PackageSummary}`);
    props.onSelect?.('');
  }, [props]);

  const allTicketsSelect = selectedPackages.every((item) => item.ticket);

  const totalPax = selectedPackages.reduce((acc, item) => {
    return (
      acc +
      (accommodationsRooms?.[item.accommodation.id]?.[item.accommodation.room.id]?.capacity || 0)
    );
  }, 0);

  const totalWithTickets = getTotalamount(selectedPackages);

  const ticketList = useMemo(() => {
    return Object.entries(tickets).reduce<TicketType[]>((acc, [key, ticket]) => {
      acc.push(ticket);
      return acc;
    }, []);
  }, [tickets]);

  const roomsListTicketSelection = useMemo(() => {
    return selectedPackages.map((orderPackage, index) => {
      const accommodation = steps?.[STEPS.ACCOMODATION_LIST];

      const accommodationDetail = accommodations?.[accommodation || ''];
      const totalInfo = getTotalamount([{ ...orderPackage, ticket: undefined }]);
      const room = accommodationsRooms?.[accommodation || ''][orderPackage.accommodation.room.id];
      const headerText = t(`common.packageNumber`, { count: index + 1 });

      const options: StackCardOption[] = filterTicketsByAccommType(
        ticketList,
        accommodationDetail?.type
      ).map((ticket, index) => {
        const roomCapacity = room?.capacity || NaN;
        const ticketPriceTotal = ticket.price * roomCapacity;
        const totalPrice = Math.ceil((ticketPriceTotal + totalInfo.total) / roomCapacity);
        const notAvailable = inventoryAvailability.tickets[ticket.id]?.quantity < totalPax;

        return {
          id: ticket.id,
          disabled: notAvailable,
          name: (
            <div className="flex gap-2 sm:items-center flex-col sm:flex-row">
              {ticket.id === '4fdeba8f-ab95-4c0d-9e5f-afbdc5245942'
                ? t('hotelPackageWithoutTicket')
                : ticket.name}{' '}
              {notAvailable ? (
                <AvailabilityChip
                  {...inventoryAvailability.tickets[ticket.id]}
                  isOnHold={notAvailable}
                  isSoldOut={inventoryAvailability.tickets[ticket.id].isSoldOut}
                />
              ) : null}
            </div>
          ),
          description: t(`tickets:${ticket.id}.description`),
          price: priceToBRL.format(totalPrice),
          priceLabel: t('common.perPerson')
        };
      });

      return (
        <div key={`${orderPackage.id}_${index}`}>
          <PackageCard
            header={headerText}
            helpText={`${t(`accommodation:${accommodationDetail?.id}.name`)} - ${t(
              `accommodation_room:${room?.id}.name`
            )} (${room?.capacity}P)`}>
            <StackedCards
              value={options.find((opt) => opt.id === orderPackage.ticket?.id)}
              onChange={(opt) => {
                const ticket = tickets[opt.id];
                updatePackageTicket(orderPackage.id, ticket);
              }}
              options={options}
            />
          </PackageCard>
        </div>
      );
    });
  }, [
    accommodations,
    accommodationsRooms,
    getTotalamount,
    inventoryAvailability.tickets,
    selectedPackages,
    steps,
    t,
    ticketList,
    tickets,
    totalPax,
    updatePackageTicket
  ]);

  useEffect(() => {
    /**
     * If the ticket is not available, select the first available ticket
     */
    selectedPackages.forEach((orderPackage) => {
      if (
        orderPackage.ticket?.id &&
        inventoryAvailability.tickets[orderPackage.ticket.id]?.quantity < totalPax
      ) {
        const accommodationDetail = accommodations?.[orderPackage.accommodation.id || ''];
        const ticket = filterTicketsByAccommType(ticketList, accommodationDetail?.type).find(
          (ticket) => {
            return inventoryAvailability.tickets[ticket.id]?.quantity >= totalPax;
          }
        );
        if (ticket) {
          updatePackageTicket(orderPackage.id, ticket);
        } else {
          throw new Error('No tickets available');
        }
      }
    });
  }, [
    accommodations,
    inventoryAvailability.tickets,
    selectedPackages,
    ticketList,
    tickets,
    totalPax,
    updatePackageTicket
  ]);

  return (
    <div className="mb-12">
      <div className="relative">
        <div className="pb-12 md:pb-0">
          <div className="">
            <StepHeader step="ticketSelection" />
            <div className="flex flex-col gap-10 pt-6">{roomsListTicketSelection}</div>
          </div>
        </div>
      </div>

      <PackagePriceBottomNav
        disabled={!allTicketsSelect}
        total={totalWithTickets.total}
        pax={totalWithTickets.pax}
        onContinue={onContinue}
      />
    </div>
  );
};
