import ArrowIcon from '@heroicons/react/24/outline/ArrowRightIcon';
import { useTranslation } from 'react-i18next';

export const OrderPendingPayment = ({ paymentUrl }: { paymentUrl?: string }) => {
  const { t } = useTranslation();

  if (!paymentUrl) return null;

  return (
    <div className="bg-gray-50 rounded-md">
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-base font-semibold leading-6 text-gray-900">
          {t('orderStatusPage.views.pendingPayment.title')}
        </h3>
        <div className="mt-2 max-w-xl text-sm text-gray-600">
          <p>{t('orderStatusPage.views.pendingPayment.message')}</p>
        </div>
        <div className="mt-5">
          <button
            onClick={() => (window.location.href = paymentUrl)}
            type="button"
            className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
            {t('orderStatusPage.views.pendingPayment.cta')}
            <ArrowIcon className="w-5 h-5 ml-2 -mr-1" />
          </button>
        </div>
      </div>
    </div>
  );
};
