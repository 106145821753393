import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useStepsFlow } from 'pages/packages/shop/hooks/use-steps-flow';
import { Select } from '@catalyst/select';
import cx from 'classnames';
import { Button } from '@design-system/fields/button';
import { Divider } from '@mui/material';
import priceToBRL from 'utils/priceToBRL';
import { PackagesType, StepType } from 'pages/packages/types';
import { MAX_PAX_THRESHOLD, STEPS } from 'pages/packages/constants';
import { useTranslation } from 'react-i18next';
import { calculateRoomPrice } from 'pages/packages/shop/helpers/calculate-room-price';
import { useNavigate, useParams } from 'react-router-dom';
import { AvailabilityChip } from 'pages/commom/components/availability-chip';
import { AccommodationRoomType, RoomCategoryType } from 'legacy-types/accommodation';
import { track } from '@common/analytics/use-analytics';
import { useQueryClient } from 'react-query';
import { roomHasAvailability } from 'pages/packages/shop/helpers/finder-lowest-room-price';
import { TicketType } from 'legacy-types/ticket';

type AccommodationRoomsWidgetProps = {
  title?: string;
  inverted?: boolean;
};

type RoomsToRenderType = {
  price: number;
  optionsMax: number;
  selected: number;
  roomKey: string;
  roomDetail: AccommodationRoomType;
};

const order = [
  'superior',
  'superior_pne',
  'king_room',
  'twin_room',
  'master_suite',
  'family_suite',
  'single_room',
  'superior_double_room',
  'superior_twin',
  'executive_suite',
  'duplex_suite'
];

function findCategoryIndex(category: RoomCategoryType) {
  const index = order.indexOf(category);
  return index === -1 ? 9999 : index; // Large index for categories not in the list
}

const sortingFunction = (a: RoomsToRenderType, b: RoomsToRenderType) => {
  // If categories are equal, sort by capacity
  const capacity = a.roomDetail.capacity - b.roomDetail.capacity;
  if (capacity !== 0) {
    return capacity;
  }

  // const price = a.price - b.price;
  // if (price !== 0) {
  //   return price;
  // }

  const categoryIndexA = findCategoryIndex(a.roomDetail.category);
  const categoryIndexB = findCategoryIndex(b.roomDetail.category);

  return categoryIndexA - categoryIndexB;
};

export const AccommodationRoomsWidget: FC<AccommodationRoomsWidgetProps> = (props) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { type, step } = useParams<{ step: StepType; type: PackagesType }>();
  const { t } = useTranslation();
  const inventoryAvailability = useStepsFlow((s) => s.inventoryAvailability);
  const tickets = useStepsFlow((s) => s.tickets);

  useEffect(() => {
    (async () => {
      await queryClient.invalidateQueries(['fetchInventoryAvailability']);
    })();
  }, [queryClient]);

  const {
    onSelectStep,
    selectedJourneyPrice,
    steps,
    selectedPackages,
    setSelectedRooms,
    getTotalamount,
    accommodationsRooms
  } = useStepsFlow((s) => ({
    onSelectStep: s.onSelectStep,
    accommodationsRooms: s.accommodationsRooms,
    setStep: s.setStep,
    steps: s.steps,
    selectedPackages: s.selectedPackages,
    selectedJourneyPrice: s.selectedJourneyPrice,
    getTotalamount: s.getTotalamount,
    setSelectedRooms: s.setSelectedPackages
  }));

  const accommodationKey = steps?.[STEPS.ACCOMODATION_LIST];
  const room = accommodationKey ? accommodationsRooms?.[accommodationKey] : undefined;

  const cheapestTicket = useMemo(() => {
    return Object.values(tickets).reduce(
      (cheapest, ticket) => {
        if (ticket.price === 0) return cheapest;
        return ticket.price < cheapest.price ? ticket : cheapest;
      },
      { price: Infinity } as TicketType
    );
  }, [tickets]);

  const onSelect = () => {
    if (step && type) {
      const value = JSON.stringify(selectedPackages);
      const navigateOptions = onSelectStep(step, type, value);
      navigate(navigateOptions);
    }
  };

  const journeyPrice = selectedJourneyPrice();
  const { total, pax } = getTotalamount();
  const checkinDate = steps?.[STEPS.CHECKIN_DATE];
  const journeyOutbound = steps?.[STEPS.JOURNEY_OUTBOUND];
  const checkoutDate = steps?.[STEPS.CHECKOUT_DATE];

  const getAggregatedRoomPrice = useCallback(
    (roomKey: string) => {
      if (!room) return NaN;
      return journeyPrice + calculateRoomPrice(room, roomKey, checkinDate, checkoutDate);
    },
    [checkinDate, checkoutDate, journeyPrice, room]
  );

  const remainingCapacity = useMemo(() => {
    if (!room) return NaN;

    const totalPaxSelected = selectedPackages.reduce((sum, orderPackage) => {
      const roomId = orderPackage.accommodation.room.id;
      return sum + room[roomId]?.capacity;
    }, 0);
    return MAX_PAX_THRESHOLD - totalPaxSelected;
  }, [room, selectedPackages]);

  const roomsToRender: RoomsToRenderType[] = useMemo(() => {
    return (
      Object.entries(room || {})
        // Filter out rooms that are not available for the selected dates
        .filter(([, room]) => {
          return roomHasAvailability(room, String(checkinDate), String(checkoutDate));
        })
        .map(([roomKey, roomDetail], index) => {
          const price = getAggregatedRoomPrice(roomKey) + cheapestTicket.price;
          const optionsMax = remainingCapacity / roomDetail.capacity;
          const selected = selectedPackages.filter(
            (r) => r.accommodation.room.id === roomKey
          ).length;

          return { price, optionsMax, selected, roomKey, roomDetail };
        })
    );
  }, [
    cheapestTicket,
    checkinDate,
    checkoutDate,
    getAggregatedRoomPrice,
    remainingCapacity,
    room,
    selectedPackages
  ]);

  const ChooseCTA = (
    <Button
      fullWidth
      disabled={pax === 0}
      onClick={() => {
        onSelect();
      }}>
      {t('packageBuilder.accomoddationRoomsWidget.ctaButton')}
    </Button>
  );

  const limitJourneyTickets = journeyOutbound
    ? inventoryAvailability.journeys[journeyOutbound].quantity
    : undefined;

  return (
    <>
      <div className="pt-4 sm:pt-14 px-6 mb-28">
        {props.title ? (
          <div>
            <div className="text-2xl text-white pb-2">{props.title}</div>
          </div>
        ) : null}

        <div className="rounded-md bg-yellow-50 p-2 my-2">
          <div className="flex">
            <div className="ml-3">
              <p className="text-sm font-medium text-gray-700">
                {t('packageBuilder.accomoddationRoomsWidget.description')}
              </p>
            </div>
          </div>
        </div>
        <div
          className={cx(
            'flex flex-col divide-y  ',
            props.inverted ? 'divide-gray-300' : 'divide-gray-800'
          )}>
          {roomsToRender
            .sort(sortingFunction)
            .map(({ roomKey, roomDetail, price, optionsMax, selected }) => {
              const quantityAvailable =
                inventoryAvailability?.accommodationRooms[roomKey]?.quantity;

              const optionsLenght = Math.floor(
                Math.min(
                  quantityAvailable,
                  optionsMax + selected,
                  (limitJourneyTickets || Infinity) / roomDetail.capacity
                ) + 1
              );

              return (
                <div key={roomKey}>
                  <div className="flex justify-between items-center align-middle my-4">
                    <div className="flex flex-col gap-1">
                      <div
                        className={cx(
                          'items-center',
                          props.inverted ? 'text-gray-900' : 'text-gray-200'
                        )}>
                        <span className="font-semibold pr-1">
                          {t(`accommodation_room:${roomDetail.id}.name`)}
                        </span>
                        <span className="text-gray-500">{`(${roomDetail.capacity}P)`}</span>
                      </div>
                      <div
                        className={cx(
                          'tracking-wide text-sm uppercase',
                          props.inverted ? 'text-gray-700 ' : 'text-gray-300 '
                        )}>
                        {t('packageBuilder.accomoddationRoomsWidget.pricePerPerson', {
                          price: priceToBRL.format(price)
                        })}
                      </div>
                    </div>
                    <div>
                      {inventoryAvailability?.accommodationRooms[roomKey]?.isAvailable ? (
                        <Select
                          id={`room-${roomKey}`}
                          onChange={(e) => {
                            e?.preventDefault();
                            type &&
                              e.target.value &&
                              setSelectedRooms(roomKey, Number(e.target.value), type);
                            void track('accommodation_room_select', 'track', {
                              accommodationId: roomDetail.accommodationUUID,
                              category: roomDetail.category,
                              name: roomDetail.name
                            });
                          }}
                          disabled={optionsLenght === 1}
                          value={selected}>
                          {Array.from({ length: optionsLenght }, (_, index) => (
                            <option key={index} value={index}>
                              {index}
                            </option>
                          ))}
                        </Select>
                      ) : (
                        <AvailabilityChip {...inventoryAvailability.accommodationRooms[roomKey]} />
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>

      <div className="lg:w-1/3 fixed top-auto bottom-0 w-full bg-gray-900 hiddem md:block z-max">
        <Divider />
        <div className="p-4 flex flex-col gap-3">
          <div className="flex justify-between text-sm font-semibold tracking-wide uppercase text-white">
            <div>{priceToBRL.format(total)}</div>
            <div>{t('packageBuilder.accomoddationRoomsWidget.forPaxQuantity', { count: pax })}</div>
          </div>
          {ChooseCTA}
        </div>
      </div>
    </>
  );
};
