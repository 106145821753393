import { Order } from 'legacy-types/order';
import React from 'react';
import {
  Timeline,
  TimelineBody,
  TimelineConnector,
  TimelineHeader,
  TimelineIcon,
  TimelineItem
} from '@material-tailwind/react';
import PaymentsIcon from '@mui/icons-material/Payments';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useTranslation } from 'react-i18next';

export const OrderExpired = (props: { order: Order }) => {
  const { t } = useTranslation();
  return (
    <div className="bg-gray-100 p-6 rounded-md">
      <div className="text-lg text-black font-bold pb-4">
        {t('orderStatusPage.views.expired.title')}
      </div>
      <div className="max-w-xl py-2">
        <Timeline>
          <TimelineItem>
            <TimelineConnector />
            <TimelineHeader>
              <TimelineIcon className="p-2">
                <PaymentsIcon className="h-4 w-4" />
              </TimelineIcon>
              <div className="text-gray-800 text-lg font-bold">
                {t('orderStatusPage.views.expired.nextSteps.refundProcess.title')}
              </div>
            </TimelineHeader>
            <TimelineBody className="pb-8">
              <div className="font-normal text-gray-600">
                {t('orderStatusPage.views.expired.nextSteps.refundProcess.message')}
              </div>
            </TimelineBody>
          </TimelineItem>
          <TimelineItem>
            <TimelineHeader>
              <TimelineIcon className="p-2">
                <ShoppingCartIcon className="h-4 w-4" />
              </TimelineIcon>
              <div className="text-gray-800 text-lg font-bold">
                {t('orderStatusPage.views.expired.nextSteps.placeNewOrder.title')}
              </div>
            </TimelineHeader>
            <TimelineBody>
              <div className="font-normal text-gray-600">
                {t('orderStatusPage.views.expired.nextSteps.placeNewOrder.message')}
              </div>
            </TimelineBody>
          </TimelineItem>
        </Timeline>
      </div>
    </div>
  );
};
