import React, { FC, forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import cx from 'classnames';
import { Step } from '../components/step';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import TicketIcon from '@heroicons/react/24/outline/TicketIcon';
import UsersIcon from '@heroicons/react/24/outline/UsersIcon';
import SvgIcon from '@mui/material/SvgIcon';
import { useWizard, Wizard } from 'react-use-wizard';
import { GuestsList } from 'pages/commom/components/order-guests/guests-list';
import { OrderGuestDetails } from 'legacy-types/customer';
import { enableMapSet, produce } from 'immer';
import { CheckoutItem } from 'legacy-types/checkout';
import { useCheckoutForm } from 'pages/packages/checkout/hooks/use-checkout-form';
import { useTranslation } from 'react-i18next';

enableMapSet();

type TermsAndCondotionsStepProps = {
  data: CheckoutItem[];
  onComplete: () => void;
};

export type GuestsHeaderType = {
  items: CheckoutItem[];
  allGuests: Map<string, OrderGuestDetails[]>;
};

const Example = forwardRef<HTMLDivElement, GuestsHeaderType>(({ items, allGuests = new Map() }) => {
  const { t } = useTranslation();
  const { activeStep, goToStep } = useWizard();

  return (
    <nav aria-label="Progress" className="mb-4">
      <div className="mb-4 text-gray-900 font-bold">
        {t(`accommodation:${items[0].accommodation?.id}.name`)}
      </div>
      <ol className="space-y-4 md:flex md:space-x-8 md:space-y-0">
        {items.map((item, index) => {
          const guests = allGuests.get(item.id) as OrderGuestDetails[];
          const isComplete = allGuests.get(item.id)?.length || activeStep > index;
          const isCurrent = activeStep === index;

          return (
            <li key={item.id} className="md:flex-1" onClick={() => isComplete && goToStep(index)}>
              <div
                className={cx(
                  'group flex flex-col border-l-4 py-2 pl-4  md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4',
                  {
                    'border-pink-700': isCurrent,
                    'border-pink-200 cursor-pointer': isComplete
                  }
                )}>
                <span
                  className={cx(
                    'text-sm font-medium text-gray-600 group-hover:text-pink-800 flex gap-2 align-middle',
                    {
                      'text-pink-600': isCurrent,
                      'text-gray-800': isComplete
                    }
                  )}>
                  {t(`accommodation_room:${item.accommodation.room?.id}.name`)} (
                  {item.accommodation.room.capacity}P)
                  {isComplete ? (
                    <SvgIcon fontSize="small" className="pr-1">
                      <CheckCircleIcon className="h-3 w-3 text-green-400" />
                    </SvgIcon>
                  ) : null}
                </span>
                <span className="text-sm text-gray-700 flex items-center gap-1">
                  <TicketIcon className="w-4 h-4" /> {item.ticket?.name}
                </span>
                <span className={cx('text-sm  text-gray-700 flex items-center gap-1')}>
                  <UsersIcon className="w-4 h-4" />
                  {guests?.map((guest) => `${guest.firstName}`).join(', ') ||
                    t('checkoutPage.form.guestDetails.noGuests')}
                </span>
              </div>
            </li>
          );
        })}
      </ol>
    </nav>
  );
});

export const GuestDetailsStep: FC<TermsAndCondotionsStepProps> = ({ data, onComplete }) => {
  const { t } = useTranslation();
  const headerRef = useRef<HTMLDivElement>(null);
  const { nextStep } = useWizard();
  const [allGuests, setItemGuests] = useState<Map<string, OrderGuestDetails[]>>(new Map());
  const { formsDetails, updateFormDetails } = useCheckoutForm((s) => ({
    updateFormDetails: s.updateFormDetails,
    formsDetails: s.formsDetails
  }));

  const handleOnComplete = useCallback(
    async (itemId: string, guests: OrderGuestDetails[]) => {
      const guestsList = new Map(allGuests).set(itemId, guests);
      setItemGuests(guestsList);

      const isComplete = data.every(
        (item) => guestsList.has(item.id) && guestsList.get(item.id)!.length
      );

      if (isComplete) {
        onComplete();
        updateFormDetails({ guests: Object.fromEntries(guestsList) });
        setItemGuests(new Map());
        await nextStep();
      }
    },
    [allGuests, data, nextStep, onComplete, updateFormDetails]
  );

  useEffect(() => {
    if (allGuests.size === 0 && formsDetails.guests) {
      setItemGuests(new Map(Object.entries(formsDetails.guests)));
    }
  }, [allGuests.size, formsDetails.guests]);

  const scrollToTop = useCallback(() => {
    headerRef?.current?.scrollIntoView({ behavior: 'smooth' });
  }, [headerRef]);

  const stepsList = useMemo(
    () =>
      data.map((item) => (
        <GuestsList
          onAddGuest={scrollToTop}
          onRemove={(guestId) =>
            setItemGuests(
              produce((draft) => {
                const newList = draft.get(item.id)?.filter((g) => g.id !== guestId);
                draft.set(item.id, newList || []);
              })
            )
          }
          defaultGuests={allGuests?.get(item.id) || []}
          itemId={item.id}
          capacity={item.accommodation.room.capacity}
          key={item.id}
          onComplete={handleOnComplete}
        />
      )),
    [allGuests, data, handleOnComplete, scrollToTop]
  );

  return (
    <Step title={t('checkoutPage.form.guestDetails.title')}>
      <Wizard header={<Example ref={headerRef} items={data} allGuests={allGuests} />}>
        {stepsList}
      </Wizard>
    </Step>
  );
};
